export const MOMENT_VARIABLES = {
    NAME: {
        CURRENT_MONTH: "currentMonthName",
        PREV_MONTH: "prevMonthName",
        CURRENT_YEAR: "currentYearName"
    },
    VALUE: {
        CURRENT_MONTH_START: "currentMonthStart",
        CURRENT_MONTH_END: "currentMonthEnd",
        PREV_MONTH_START: "prevMonthStart",
        PREV_MONTH_END: "prevMonthEnd",
        FIRST_MONTH_OF_YEAR: "firstMonthOfYear",
        LAST_MONTH_OF_YEAR: "lastMonthOfYear"
    }

};

export const RENDERED_CONTENT_TYPE = {
    INPUT_WITH_BUTTON: "input_with_button",
    BUTTON: "button",
    DOUBLE_INPUT_WITH_BUTTON: "double_input_with_button",
    DROPDOWN: "dropdown",
    TOGGLE: "toggle",
    TABLE: "table"
};

export const DASHBOARD_INFOS = [
    { text: "Payments", children: [
        { text: "Current Month", value: "incomeCurrentMonth", key: MOMENT_VARIABLES.NAME.CURRENT_MONTH },
        { text: "Previous Month", value: "incomePrevMonth", key: MOMENT_VARIABLES.NAME.PREV_MONTH },
        { text: "All Year", value: "incomeCurrentYear", key: MOMENT_VARIABLES.NAME.CURRENT_YEAR }
    ]},
    { text: "Donate", children: [
        { text: "Current Month", value: "incomeSpendendosenCurrentMonth", key: MOMENT_VARIABLES.NAME.CURRENT_MONTH },
        { text: "All Year", value: "incomeSpendendosen", key: MOMENT_VARIABLES.NAME.CURRENT_YEAR }
    ]},
    { text: "Balance", children: [
        { text: "Current Month", value: "balanceCurrentMonth", key: MOMENT_VARIABLES.NAME.CURRENT_MONTH },
        { text: "All Year", value: "balanceCurrentYear", key: MOMENT_VARIABLES.NAME.CURRENT_YEAR }
    ]},
    { text: "Profile", children: [
        { text: "Number of Active Profile", value: "numberOfActive", key : "none", notCurrency: true }
    ]}
];

export const SPENDENDOSEN_INFOS = [
    { text: "DONATE", children: [
        { text: "Total Payment", value: "spenTotalPayment", key: "none" },
        { text: "Current Month", value: "spenCurrentMonthPayment", key: MOMENT_VARIABLES.NAME.CURRENT_MONTH },
        { text: "Previous Month", value: "spenPrevMonthPayment", key: MOMENT_VARIABLES.NAME.PREV_MONTH },
        { text: "New Payment", value: "none", key: "none", valueType: RENDERED_CONTENT_TYPE.INPUT_WITH_BUTTON },
        { text: "Transaction History", value: "none", key: "none", valueType: RENDERED_CONTENT_TYPE.BUTTON },
        { text: "none", value: "none", key: "none", valueType: RENDERED_CONTENT_TYPE.TABLE },
    ]}
];

export const LANGUAGE_SUPPORT = {
    ENGLISH: "en",
    DEUTSCH: "de"
};

export const TRANSACTION_TYPE = {
    PURCHASE: "Purchase",
    PAYMENT : "Payment"
};

export const ACCOUNT_INFO = {
    ACTIVE      : "Active",
    INACTIVE    : "Inactive",
    INVOICE     : "Invoice",
    NON_INVOICE : "Non-Invoice"
};

export const CUSTOMER_TYPE = {
    PERSON: "person",
    ROOM: "room"
};

export const LIST_WITH_SEARCH_OPERATION_TYPES = {
    NEXUDUS : "nexudus"
}