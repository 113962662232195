import { LOGO_IMAGES, BACK_BUTTON_IMAGES } from '../../Helpers/ImageHelper';
import CoworkingspaceClient from '../coworkingspace/CoworkingspaceClient';

import basislager from '../coworkingspace/basislager.json'; // todo: remove when web service ok
import room from '../coworkingspace/room.json'; // todo: remove when web service ok
import fleet7 from '../coworkingspace/fleet.json'; // todo: remove when web service ok
import rostock from '../coworkingspace/rostock.json'; // todo: remove when web service ok
import iotiq from '../coworkingspace/iotiq.json'; // todo: remove when web service ok

function decideHost() {
    const hostname = window.location.hostname;
    const registeredHost = hostname.split('.')[0];
    switch (registeredHost) {
        case 'basislager':
            return basislager;
        case 'fleet7':
            return fleet7;
        case 'rostock':
            return rostock;
        case 'iotiq':
            return iotiq;
        default:
            return room; // default config need and take from db;
    }
};

export const coworkingSpaceObject = new CoworkingspaceClient(decideHost()); // todo: need check from web service 
export const coworkingSpace = coworkingSpaceObject.getCoworkingspace();

export function getColorTheme() {
    // todo: need take img from string
    let themeObj = coworkingSpaceObject.getStyle(CoworkingspaceClient.STYLE.COLOR_THEME);

    // themeObj.logo = LOGO_IMAGES[`${coworkingSpace}`].logo; // maybe need for dark theme later
    themeObj.logoActive = LOGO_IMAGES[`${coworkingSpace}`].logoActive;

    return themeObj;
}

export function getHeaderLogoStyle() {
    return coworkingSpaceObject.getStyle(CoworkingspaceClient.STYLE.LOGO);
}

export function getHeaderProfileStyle() {
    return coworkingSpaceObject.getStyle(CoworkingspaceClient.STYLE.PROFILE_HEADER);
}

export function getBackImage() {
    return BACK_BUTTON_IMAGES[`${coworkingSpace}`].logoActive;
}

export function getHeaderList() {
    return coworkingSpaceObject.getHeaderInfo(CoworkingspaceClient.HEADER.HEADER_LIST);
}

export function getHeadersMarginStyle(index) {
    const marginArray = coworkingSpaceObject.getHeaderInfo(CoworkingspaceClient.HEADER.MARGIN_LEFT);
    return marginArray[`${index}`];
}

export function isRoomMenuAllowed() {
    return coworkingSpaceObject.getHaveInfo(CoworkingspaceClient.HAVE_INFO.ROOM_MENU);
}

export function isNeedMoreAdmin() {
    return coworkingSpaceObject.getHaveInfo(CoworkingspaceClient.HAVE_INFO.MORE_ADMIN);
}

export function isHaveNexudus() {
    return coworkingSpaceObject.getHaveInfo(CoworkingspaceClient.HAVE_INFO.NEXUDUS);
}

export function getBorderWithTheme(borderPx, borderStyle) {
    return `${borderPx} ${borderStyle} ${getColorTheme().selectedTheme}`
}

export function getPlatformName() {
    return coworkingSpaceObject.getPlatform();
}