import moment from 'moment';

export function momentIsBetween(date, { startDate, endDate }) {
    let momentStart = startDate, momentEnd = endDate;
    let isEqual = false;

    if (typeof startDate === 'object') {
        momentStart = moment(startDate).format("YYYY-MM-DD HH:mm:ss");
    }
    if (typeof endDate === 'object') {
        momentEnd = moment(endDate).endOf('day').format("YYYY-MM-DD HH:mm:ss");
    }

    if (momentStart === momentEnd) {
        isEqual = moment(date).isSame(momentStart, 'day');
    }

    return moment(date).isBetween(momentStart, momentEnd) || isEqual;
}