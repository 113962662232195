import React from 'react';
import moment from 'moment';
import { Button, Icon, Label, Modal } from 'semantic-ui-react';
import CustomDateRangePicker from './CustomDateRangePicker';

export default class DatePickerModalTrigger extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            startDate: moment().toDate(),
            endDate: moment().toDate(),
            dateInfo: "All".localize(), // moment().format("DD MM YYYY"),
        };
    }

    renderModal = () => {
        const buttonTextStyle = { fontFamily: 'kelson2', color: 'white' };
        return (
            <Modal open={this.state.open} closeOnDimmerClick={true} closeOnDocumentClick={true} >
                <Modal.Header style={{ textAlign: 'center' }} >{"Date Period Picker".localize()}</Modal.Header>
                <Modal.Content>
                    <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'center' }} >
                        <CustomDateRangePicker onRef={(ref) => this.datePicker = ref} startDate={this.state.startDate} endDate={this.state.endDate}/>
                    </div>
                    <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'center', marginTop: '5%' }} >
                        <Button
                            style={{ backgroundColor: this.props.applyColor }}
                            variant="contained"
                            onClick={this.handleApply}
                        >
                            <p style={buttonTextStyle} >{"Apply".localize()}</p>
                        </Button>
                        <Button
                            style={{ backgroundColor: "#FF3D00" }}
                            variant="contained"
                            onClick={this.onCloseModal}
                        >
                            <p style={buttonTextStyle} >{"Close".localize()}</p>
                        </Button>
                    </div>
                </Modal.Content>
            </Modal>
        );
    };

    onOpenModal = () => {
        this.setState({ open: true });
    };

    handleApply = () => {
        if (this.datePicker) {
            const pickedDateRange = this.datePicker.getPickedDateRange();
            this.prepareDateInfo(pickedDateRange);
            this.props.onApply(pickedDateRange);
            this.onCloseModal();
        }
    };

    prepareDateInfo = ({ startDate, endDate }) => {
        let start = moment(startDate), end = moment(endDate), dateInfo;
        if (start === end) {
            dateInfo = start;
        }
        dateInfo = start.format("DD MM YYYY") + " - " + end.format("DD MM YYYY");
        this.setState({startDate: start.toDate(), endDate: end.toDate(), dateInfo: dateInfo});
    };

    clearDate = (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.setState({ dateInfo: "All".localize() }, () => {
            this.props.onApply(this.state.dateInfo);
        });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    render() {
        return (
            <div id="customDatePickerModal">
                <Label as='a' onClick={this.onOpenModal}>
                    {this.state.dateInfo}
                    <Icon name='delete' onClick={this.clearDate}/>
                </Label>
                {this.state.open ? this.renderModal() : null}
            </div>
        );
    }
}