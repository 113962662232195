import React, { Component } from 'react';
import LoginInput from '../Components/LoginInput';
import { getColorTheme } from '../config/flavor/mainFlavor';
import '../Main.css';


class LoginScreen extends Component {
    render() {
        return (
            <div>
                <header className="App-header">
                    <img src={getColorTheme().logoActive} className="App-logo" alt="logo" />
                </header>
                <LoginInput changeLanguage={this.props.changeLanguage} activeLanguage={this.props.activeLanguage}/>
            </div>
        );
    }
}

export default LoginScreen;
