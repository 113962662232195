import React from 'react';
import { Dropdown, Button } from 'semantic-ui-react'
export default class CustomDropdown extends  React.Component {
    constructor(props) {
        super(props);
    }

    generateOptions = () => {
        let optionArray = []
        this.props.list.map((item)=>{
            let option =   {
                key : item.id || item[this.props.defaultValueTag],
                value : item[this.props.defaultValueTag],
                text : item[this.props.defaultTextTag]
            }
            optionArray.push(option)
        })
        return optionArray
    }

    render(){
        return(
                <Dropdown
                    placeholder={'Select Account'.localize()}
                    fluid
                    search
                    selection
                    options={this.generateOptions()}
                    onChange={this.props.onChange}
                />
        )
    }
}
