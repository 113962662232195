
import Button from '@material-ui/core/Button';
import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';
import '../Main.css';
import { getColorTheme } from '../config/flavor/mainFlavor';
class DeleteModal extends Component {
    render() {
        return (
            <Modal open={true} closeOnDimmerClick={true} centered={false} closeOnDocumentClick={true} >
                <Modal.Header style={{ textAlign: 'center' }} >{"Are you sure ?".localize()}</Modal.Header>
                <Modal.Content >
                    {/* Add and Close Button */}
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%', height: '15%', marginTop: '5%', justifyContent: 'center', }} >
                        <Button
                            style={{
                                backgroundColor: getColorTheme().selectedTheme,
                                fontSize: "18px",
                                maxWidth: '170px',
                                minWidth: '170px',
                                maxHeight: '35px',
                                minHeight: '35px',
                                marginLeft: '3%'
                            }}
                            variant="contained"
                            onClick={() => this.props.handleDelete()}
                        >
                            <p style={{ fontFamily: 'kelson2', color: 'white' }} >{"Yes".localize()}</p>
                        </Button>
                        <Button
                            style={{
                                backgroundColor: getColorTheme().errorTheme,
                                fontSize: "18px",
                                maxWidth: '170px',
                                minWidth: '170px',
                                maxHeight: '35px',
                                minHeight: '35px',
                                marginLeft: '3%'
                            }}
                            variant="contained"
                            onClick={() => this.props.closeDelete()}
                        >
                            <p style={{ fontFamily: 'kelson2', color: 'white' }} >{"Back".localize()}</p>
                        </Button>
                    </div>
                </Modal.Content>
            </Modal>
        );
    }
}

export default DeleteModal;
