import React from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import * as rdrLocales from 'react-date-range/dist/locale';
import i18n from '../i18n';
import { DateRange } from 'react-date-range';

export default class CustomDateRangePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dateRange: {
                startDate: props.startDate,
                endDate: props.endDate,
                key: 'selection'
            }
        };
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(undefined);
        }
    }

    handleDateSelect = (ranges) => {
        this.setState({ dateRange: ranges.selection });
    };

    getPickedDateRange = () => {
        const { dateRange } = this.state;
        return dateRange;
    };

    getLocalization = () => {
        const language = i18n.language;
        return rdrLocales[`${language}`];
    };

    render() {
        return (
            <DateRange
                locale={this.getLocalization()}
                showDateDisplay={this.props.showDateDisplay}
                editableDateInputs={false}
                onChange={this.handleDateSelect}
                moveRangeOnFirstSelection={false}
                ranges={[this.state.dateRange]}
            />
        );
    }
}