import React from "react";
import Button from '@material-ui/core/Button';
import { CSVLink } from "react-csv";
import { getColorTheme } from "../config/flavor/mainFlavor";

export default class CSVExporterButton extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            dataToDownload: this.props.dataToDownload,           
        }
    }

    handleTableExport = (columns, data) => {
        let downloadedData = [];
        for (let index = 0; index < data.length; index++) {
            let recordedData = {};
            for (let colIndex = 0; colIndex < columns.length; colIndex++) {
                let singleColumn = columns[colIndex];
                recordedData[singleColumn.Header] = data[index][singleColumn.accessor]
            }
            downloadedData.push(recordedData);
        }
        
        this.setState({ dataToDownload: downloadedData }, () => {
            // this.csvLink.link.click();
        });
    }

    render() {
        return <CSVLink
            data={this.state.dataToDownload}
            filename="data.csv"
            ref={(ref) => this.csvLink = ref}
            asyncOnClick={true}
            onClick={(event, done) => {
                this.handleTableExport(this.props.columns, this.props.getSortedData());
                done();
            }}> 
            <Button
                style={{
                    backgroundColor: getColorTheme().selectedTheme,
                    fontSize: "18px",
                    maxWidth: '120px',
                    minWidth: '120px',
                    maxHeight: '34px',
                    minHeight: '34px',
                }}
                variant="contained">
                <p style={{ fontFamily: 'kelson2', color: 'white', fontSize: 18 }} >{"Export".localize()}</p>
            </Button>
        </CSVLink>;
    }
}