import React from 'react';
import { Input, Icon } from 'semantic-ui-react';
import { getColorTheme } from '../config/flavor/mainFlavor';

export default class CustomPersonInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    
    render() {
        return <div className="ProfileUpdateNamePropertyEdit" >
            {
                this.props.openNameAndSurnameTextInput ?
                    <div style={{ display: 'flex', flexDirection: 'row', width: '50%', justifyContent: 'center', }} >
                        <Input
                            iconPosition={"left"}
                            icon="address book"
                            value={this.props.nameTextInput}
                            onChange={this.props.handleNameTextInput}
                            placeholder={this.props.profileUpdateInfo.type !== "room" ? "First Name / Company Name".localize() : "Room Name".localize()}
                            style={{ width: '70%', height: '50%' }} />
                        {/*
                            this.props.profileUpdateInfo.type !== "room" ?
                                <Input
                                    iconPosition={"left"}
                                    icon="address book outline"
                                    value={this.props.surnameTextInput}
                                    onChange={this.props.handleSurnameTextInput}
                                    placeholder={"Last Name / Legal Form".localize()}
                                    style={{ width: '70%', height: '50%' }} /> : null
                        */}
                    </div> :
                    <div style={{ height: '100%', width: '30%', justifyContent: 'center', }} >
                        <p style={{
                            fontFamily: 'kelson1',
                            fontSize: 40, float: 'left',
                            color: getColorTheme().selectedTheme, marginTop: '2%',
                        }}>{this.props.profileUpdateInfo.name}</p>
                    </div>
            }
            {
                !this.props.openNameAndSurnameTextInput ?
                    <div style={{ width: '30%', height: '100%' }} >
                        <div style={{ float: 'left', marginTop: '4%' }} >
                            <Icon
                                onClick={this.props.openNameAndSurnameInput}
                                size='large'
                                name="edit" />
                        </div>
                    </div>
                    :
                    <div style={{ marginTop: 10, float: 'left', marginLeft: 10, display: 'flex', flexDirection: 'row' }} >
                        <Icon
                            onClick={this.props.changePersonNameAndSurname}
                            size='large'
                            name="check circle" />
                        <Icon
                            onClick={this.props.closeNameAndSurnameInput}
                            size='large'
                            name="close" />
                    </div>
            }
        </div>
    }
}