import React from 'react';
import Button from '@material-ui/core/Button';
import { Input, Label, Modal } from 'semantic-ui-react';
import { matchPattern } from '../Helpers/ListHelpers';
import { getBorderWithTheme } from '../config/flavor/mainFlavor';
import CustomImageSelector from './CustomImageSelector';
import { DEFAULT_IMAGE } from '../Helpers/ImageHelper';
import { DISABLED_BUTTON_COLOR, createPriceValueFromText } from '../Helpers/ContentHelpers';

export default class AddDrinkModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            drinksInfo: {
                drink_name: "",
                drink_cost: -1,
                image: DEFAULT_IMAGE,
                inactive_image: DEFAULT_IMAGE,
            },
            validateInput: false
        };
    }

    handleTextChange = (data, type) => {
        let { drinksInfo, validateInput } = this.state;
        let cost = data.value;

        drinksInfo[type] = cost;

        if (type === "drink_cost") {
            drinksInfo[type] = createPriceValueFromText(cost);;

            validateInput = matchPattern(cost);
        }

        this.setState({ drinksInfo, validateInput });
    };

    handleAddDrink = () => {
        this.props.addDrink(this.state.drinksInfo);
    };

    handleImage = (imgObj) => {
        const { image_fileName, inactive_image_fileName } = imgObj;
        this.handleTextChange({ value: image_fileName }, "image");
        this.handleTextChange({ value: inactive_image_fileName }, "inactive_image");
    };

    render() {
        const labelStyle = { display: 'flex', flexDirection: "row", width: '80%', height: '17%', justifyContent: 'center' };
        const buttonTextStyle = { fontFamily: 'kelson2', color: 'white' };
        const buttonStyle = { fontSize: "18px", /*maxWidth: '170px',*/ minWidth: '170px', maxHeight: '35px', minHeight: '35px', marginLeft: '3%' };

        return <Modal open={this.props.open} closeOnDimmerClick={true} centered={false} closeOnDocumentClick={true} >
            <Modal.Header style={{ textAlign: 'center' }} >{"Add Drink".localize()}</Modal.Header>
            <Modal.Content >
                <div>
                    <CustomImageSelector onRef={(ref) => this.imageSelector = ref} data={this.state.drinksInfo} key={`${this.state.drinksInfo.inactive_image}`}
                        theme={this.props.theme} addNotification={this.props.addNotification} onApply={this.handleImage} />
                    <br/>
                </div>
                <div style={labelStyle} >
                    <div style={{ width: '30%' }}>
                        <p className="ProfilesHeaderText" >{"Drink Name".localize()}</p>
                    </div>
                    <Input
                        autoFocus={true}
                        icon="cocktail"
                        onChange={(event, data) => this.handleTextChange(data, "drink_name")}
                        placeholder=""
                        style={{ width: '40%', border: getBorderWithTheme("1px", "solid"), }} />
                </div>

                <div style={{ ...labelStyle, marginTop: '5%' }} >
                    <div style={{ width: '30%' }}>
                        <p className="ProfilesHeaderText" >{"Drink Price".localize()}</p>
                    </div>
                    <Input
                        style={{ width: '40%' }}
                        labelPosition='right' type='text' placeholder=''
                        error={!this.state.validateInput}
                        onChange={(event, data) => this.handleTextChange(data, "drink_cost")} >
                        <input />
                        <Label basic>{'\u20AC'}</Label>
                    </Input>
                </div>
                {/* Add and Close Button */}
                <div style={{ ...labelStyle, width: '100%', height: '15%', marginTop: '5%' }} >
                    <Button
                        style={{ ...buttonStyle, backgroundColor: this.state.validateInput ? this.props.addButtonColor : DISABLED_BUTTON_COLOR }}
                        variant="contained"
                        onClick={this.handleAddDrink}
                        disabled={!this.state.validateInput}
                    >
                        <p style={buttonTextStyle} >{"Add Drink".localize()}</p>
                    </Button>
                    <Button
                        style={{ ...buttonStyle, backgroundColor: "#FF3D00" }}
                        variant="contained"
                        onClick={this.props.onCloseModal}
                    >
                        <p style={buttonTextStyle} >{"Close".localize()}</p>
                    </Button>
                </div>

            </Modal.Content>
        </Modal>
    }
}