const isPropertyValid = (property) => {
    if (typeof property !== "undefined" && property !== null && property !== "") {
        return true;
    }

    return false;
};

const insertPropertyIfValid = (cssProperty, customPropertyValue) => {
    if (isPropertyValid(customPropertyValue)) {
        return `${cssProperty}: ${customPropertyValue};`;
    }
};

const ThemeBuilder = (customTheme) => {
    // return if there's no custom theme available
    if (typeof customTheme === "undefined") {
        return;
    }

    let stylesTexts = ":root {";
    Object.keys(customTheme).forEach(key => {
        const cssProperty = `--${key}`;
        stylesTexts += insertPropertyIfValid(cssProperty, customTheme[key]);
    });
    stylesTexts += "}";

    return stylesTexts;
};

export default ThemeBuilder;