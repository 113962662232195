import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import BackendApi from '../api/app2backend';
import { DASHBOARD_INFOS } from '../Helpers/const';
import { generateCurrentAndPrevMonthName, generateCurrentYearStart } from '../Helpers/ListHelpers';
import '../Main.css';
import { createPriceTextFromValue } from '../Helpers/ContentHelpers';

class DashBoardScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            incomeCurrentMonth: -1,
            incomePrevMonth: -1,
            incomeCurrentYear: -1,
            balanceCurrentMonth: -1,
            balanceCurrentYear: -1,
            numberOfActive: -1,
            incomeSpendendosen: -1,
            incomeSpendendosenCurrentMonth: -1,
            isLoading: true
        }
    }
    componentWillMount() {
        var monthInfos = generateCurrentYearStart();
        let tokenToSend;
        let localStorageInfo = localStorage.getItem('userInfo');
        localStorageInfo = JSON.parse(localStorageInfo);
        if (localStorageInfo && localStorageInfo.isAuthSuccess) {
            tokenToSend = localStorageInfo.token
            this.setState({ isUnauth: false });
        }
        else
            tokenToSend = this.props.token
        monthInfos['token'] = tokenToSend;
        BackendApi.getDashBoardInfos(monthInfos, (res, err) => {
            if (err) {
                // console.log("dash err", err);
                if (err.response) {
                    if (err.response.status === 401) {
                        this.setState({ isUnauth: true });
                        alert("You are unauthorized. Please login firstly!".localize());
                        // cb(401);
                    }
                }
            }
            else {
                if (res) {
                    let { activeProfileNumber, incomeCurrentMonth, incomePrevMonth, 
                        incomeCurrentYear, balanceCurrentMonth, balanceCurrentYear} = res.data;
                    this.setState({
                        incomeCurrentMonth: incomeCurrentMonth,
                        incomePrevMonth: incomePrevMonth,
                        incomeCurrentYear: incomeCurrentYear,
                        balanceCurrentMonth: balanceCurrentMonth,
                        balanceCurrentYear: balanceCurrentYear,
                        numberOfActive: activeProfileNumber,
                    }, () => {
                        BackendApi.getSpendendosenAll(monthInfos, res => {
                            let { spendosenIncome, spendosenCurrentMonth } = res.data;
                            let spenIncome = spendosenIncome;
                            let spenMonth = spendosenCurrentMonth;
                            this.setState({ 
                                incomeSpendendosen: spenIncome, 
                                incomeSpendendosenCurrentMonth: spenMonth,
                                isLoading: false });
                        })
                    })
                }
            }
        });
    }

    render() {
        const dateNames = generateCurrentAndPrevMonthName();
        if (this.state.isLoading) {
            return <div className='sweet-loading'/>
        }
        else {
            return (
                this.state.isUnauth ? <Redirect to={{
                    pathname: '/',
                    state: { token: this.state.token }
                }} /> :
                    <div className="Dashboard" >
                        <div className={"DashboardInnerDiv"} >
                            {
                                DASHBOARD_INFOS.map((boardInfo, key) => {
                                    return (
                                        <div key={`${boardInfo.text}_${key}`} style={{display: 'contents'}}>
                                            <div className="DashboardHeaderDiv">
                                                <p className="DashboardHeader">{boardInfo.text.localize()}</p>                            
                                            </div>
                                            <hr/>
                                            {
                                                boardInfo.children.map((child, ind) => {
                                                    return (
                                                        <div key={`${boardInfo.text}_${key}_${child.text}_${ind}`} className="DashboardHeaderDiv">
                                                        <p className="DashboardLabelText">
                                                            {`${child.text.localize()} ${dateNames[child.key] ? dateNames[child.key] : ""}`}
                                                        </p>
                                                        <p className={this.state[child.value] >= 0 ? "DashboardValueTheme" : "DashboardValueWarning"}>
                                                            {child.notCurrency ? this.state[child.value] 
                                                            : createPriceTextFromValue(this.state[child.value])}
                                                        </p>
                                                    </div>
                                                    )
                                                })
                                            }
                                            <br></br>
                                        </div>
                                    )
                                })
                            }                           
                        </div>
                        <div style={{ flex: 4, }}/>
                    </div>
    
            );

        }
    }
}
export default DashBoardScreen;
