import React from 'react';
import { Button, Grid, Icon, Image, Label, Modal } from 'semantic-ui-react';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import BackendApi from '../api/app2backend';
import { getPlatformName } from '../config/flavor/mainFlavor';
import { DEFAULT_IMAGE, DEFAULT_IMAGE_NAME} from '../Helpers/ImageHelper';

const IMG_KEY = {
    ACTIVE: "image",
    INACTIVE: "inactive_image"
};

export default class DrinkImageModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            image: this.props.data.image,
            inactive_image: this.props.data.inactive_image,
            image_fileName: this.createFileName(this.props.data.image),
            inactive_image_fileName: this.createFileName(this.props.data.inactive_image)
        };
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(undefined);
        }
    }

    createFileName = (fileName) => {
        if (fileName === DEFAULT_IMAGE_NAME)
            return DEFAULT_IMAGE;
        else if (fileName.includes("images/"))
            return fileName;
        else
            return `images/${getPlatformName()}/${fileName}`;
    };

    handleRemove = (imgKey) => {
        this.setState({ [`${imgKey}`]: DEFAULT_IMAGE, [`${imgKey}_fileName`]: this.createFileName(DEFAULT_IMAGE_NAME) });
    };

    renderRemoverIcon = (imgKey) => {
        return <Icon name="cancel" onClick={() => this.handleRemove(imgKey)} />
    };

    renderImage = (imgSrc, color, imgKey) => {
        const style = {maxHeight: '100px', maxWidth: '100px', minHeight: '100px', minWidth: '100px'}
        return <Image src={imgSrc} style={{ ...style, backgroundColor: color }} bordered
            label={{ color: 'red', corner: 'right', icon: this.renderRemoverIcon(imgKey) }} />
    };

    createImageNameWithTimestamp = (name, imgKey) => {
        return this.state[`${imgKey}_timestamp`] ? this.state[`${imgKey}_timestamp`] + "_" + name : name;
    }

    handleStatus = ({ meta }, status, imgKey) => {
        let name = this.createImageNameWithTimestamp(meta.name, imgKey);
        if (status === "done") {
            this.setState({ [`${imgKey}`]: meta.previewUrl, [`${imgKey}_fileName`]: this.createFileName(name) });
        } else if (status === "removed") {
            BackendApi.clearFile(`/${this.createFileName(name)}`, (res, err) => {
                if (err) {
                    this.props.addNotification(null, err.toString(), "error")
                } else {
                    this.setState({ [`${imgKey}`]: DEFAULT_IMAGE, [`${imgKey}_fileName`]: this.createFileName(DEFAULT_IMAGE_NAME) });
                }
            })
        }
    };

    getUploadParams = (imgKey) => {
        this.setState({ [`${imgKey}_timestamp`]: Date.now()});
        return { url: BackendApi.uploadImageURL(), headers: {timestamp: this.state[`${imgKey}_timestamp`]} };
    }

    renderDropzone = (imgKey) => {
        return <Dropzone
            maxFiles={1}
            multiple={false}
            getUploadParams={() => this.getUploadParams(imgKey)}
            onChangeStatus={({ meta }, status) => this.handleStatus({ meta }, status, imgKey)}
            accept=".png"
            inputContent={"Drop Files or Click to Browse".localize()}
            styles={{ dropzone: { minHeight: 200, maxHeight: 250, overflow: 'auto', textAlign: 'center' } }}
        />
    };

    handleApply = () => {
        this.props.onApply(this.state);
    };

    handleClose = () => {
        this.props.onClose();
    };

    render() {
        return (
            <Modal open={this.props.open} closeOnDimmerClick={true} closeOnDocumentClick={true}>
                <Modal.Header style={{ textAlign: 'center' }} >{"Drink Icon Picker".localize()}</Modal.Header>
                <Modal.Content style={{ textAlign: 'center', color: 'red' }} >{"recommendationText".localize()}</Modal.Content>
                <Modal.Content>
                    <Grid columns="two" divided>
                        <Grid.Column>
                            <Label color='blue' ribbon>{"Non-selected".localize()}</Label>
                            <Grid.Row style={{ textAlign: 'center' }}>
                                {this.renderImage(this.state.inactive_image, this.props.theme.mainBackgroundColor, IMG_KEY.INACTIVE)}
                            </Grid.Row>
                            <br />
                            <Grid.Row>
                                {this.renderDropzone(IMG_KEY.INACTIVE)}
                            </Grid.Row>
                        </Grid.Column>
                        <Grid.Column>
                            <Label color='teal' ribbon="right">{"Selected".localize()}</Label>
                            <Grid.Row style={{ textAlign: 'center' }}>
                                {this.renderImage(this.state.image, this.props.theme.selectedTheme, IMG_KEY.ACTIVE)}
                            </Grid.Row>
                            <br />
                            <Grid.Row>
                                {this.renderDropzone(IMG_KEY.ACTIVE)}
                            </Grid.Row>
                        </Grid.Column>
                    </Grid>

                </Modal.Content>
                <Modal.Actions>
                    <Button positive onClick={this.handleApply}>
                        {"Apply".localize()}
                    </Button>
                    <Button negative onClick={this.handleClose}>
                        {"Close".localize()}
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}