import React from 'react';
import eraseImg from '../images/close_number.png';

export default class PinNumpadComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    renderSingleButtons = (textValue) => {
        const isTransparent = textValue === ""
        const isErase = textValue === "erase";
        return <button className={isTransparent ? "DigitTransparentButton" : "DigitButton"} key={`button_${textValue}`}
            onClick={() => this.props.handlePinPress(textValue)} disabled={isTransparent}>
            {isErase ?
                <img alt="logo" src={eraseImg} className="DigitEraseButton" /> : <p className="DigitButtonText">{textValue}</p>
            }
        </button>
    };

    renderDigits = (columnNums) => {
        return <div className="DigitButtonGroup">
            {columnNums.map((num) => this.renderSingleButtons(num))}
        </div>
    };

    renderColumns = (padColumns) => {
        return <div style={{ display: 'contents' }}>
            {padColumns.map((column, key) => <div className="PinInputTwenty" key={key}>
                {this.renderDigits(column)}
            </div>)}
        </div>
    };

    render() {
        const numpad = [[1, 2, 3], [4, 5, 6], [7, 8, 9], ["", 0, "erase"]];
        return this.renderColumns(numpad);
    }
}