import React from 'react';
import { Input } from 'semantic-ui-react';
import Switch from '@material-ui/core/Switch';
import { generateHashedPin } from '../Helpers/hashHelper';
import { getBorderWithTheme } from '../config/flavor/mainFlavor';
import PinNumpadComponent from './PinNumpadComponent';

export default class PinTriggerComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPinSection: false,
            pinInputValue: "",
            pin: "not_setted",
        };
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(undefined);
        }
    }

    handleShowPinSection = () => {
        this.setState({ showPinSection: !this.state.showPinSection });
    };

    handlePinPress = (index) => {
        if (index !== "erase") {
            let oldPin = this.state.pinInputValue;
            let newPin = oldPin.concat(index);
            if (newPin.length > 3) {
                this.props.addNotification(null, "PIN cannot be longer than 3 digits!".localize(), "error");
            }
            else
                this.setState({ pinInputValue: newPin });
        }
        else {
            if (this.state.pinInputValue.length > 0) {
                let oldPin = this.state.pinInputValue;
                let newPin = oldPin.substring(0, oldPin.length - 1);
                this.setState({ pinInputValue: newPin });
            }
        }
    };

    getInputStyle = () => {
        return { width: '80%', height: '50%', border: getBorderWithTheme("1px", "solid"), alignSelf: 'center' };
    };

    getPinValue = () => {
        return this.state.showPinSection || this.props.noToggle ? generateHashedPin(this.state.pinInputValue) : this.state.pin;
    };

    getPinInputValue = () => {
        return this.state.pinInputValue;
    };

    renderPinToggle = () => {
        return this.props.noToggle ?
            <div className="NewProfileTextContainer" /> :
            <div className="NewProfileTextContainer">
                <p className="NewProfileGeneralText" >{"PIN".localize()}</p>
                <Switch
                    color="primary" checked={this.state.showPinSection}
                    onChange={(e, c) => this.handleShowPinSection()}
                    value={this.state.showPinSection} />
            </div>;
    };

    render() {
        const condition = this.state.showPinSection || this.props.noToggle;
        return <div className={condition ? "ChangablePinContainerHigh" : "ChangablePinContainer"}>
            <div className="NewProfilePinContainer">
                {this.renderPinToggle()}
                <div className="NewProfileSelectDigitContainer">
                    <div className="PinInputTwentyFive">
                        {condition ?
                            <Input
                                iconPosition='left' type="password" icon="lock"
                                value={this.state.pinInputValue} placeholder="" style={this.getInputStyle()} />
                            : null
                        }
                    </div>
                    {condition ? <PinNumpadComponent handlePinPress={this.handlePinPress} /> : null}
                </div>
            </div>
        </div>
    }
}