import Button from '@material-ui/core/Button';
import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';
import '../Main.css';
import { getColorTheme } from '../config/flavor/mainFlavor';
import PinTriggerComponent from './PinTriggerComponent';

const BUTTON_TYPES = {
    RESET: "Reset",
    REMOVE_PIN: "Remove PIN",
    BACK: "Back"
};
export default class ResetModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPinSection: false,
            pinInputValue: ""
        }
    }

    handlePinPress = (index) => {
        if (index !== "erase") {
            let oldPin = this.state.pinInputValue;
            let newPin = oldPin.concat(index);
            if (newPin.length > 3) {
                this.props.addNotification(null, "PIN cannot be longer than 3 digits!".localize(), "error");
            }
            else
                this.setState({ pinInputValue: newPin });
        }
        else {//If it is erase button
            if (this.state.pinInputValue.length > 0) {//If there is thing to erase
                let oldPin = this.state.pinInputValue;
                let newPin = oldPin.substring(0, oldPin.length - 1);
                this.setState({ pinInputValue: newPin });
            }
        }
    };

    handleResetPin() {
        const inputValue = this.pinTrigger ? this.pinTrigger.getPinInputValue() : "";
        if (inputValue.length === 3) {
            this.props.handleResetPin(inputValue, false);
        }
        else {
            this.props.addNotification(null, "PIN cannot be shorter than 3 digits!".localize(), "error");
        }
    };

    handleRemovePin() {
        this.props.handleResetPin("not_setted", true);
    };

    getButtonStyle = (buttonType) => {
        let { selectedTheme, darkBackgroundTheme, errorTheme } = getColorTheme();
        let backgroundColor;
        switch (buttonType) {
            case BUTTON_TYPES.RESET:
                backgroundColor = selectedTheme;
                break;
            case BUTTON_TYPES.REMOVE_PIN:
                backgroundColor = darkBackgroundTheme;
                break;
            case BUTTON_TYPES.BACK:
                backgroundColor = errorTheme;
                break;
            default:
                backgroundColor = "";
                break;
        }
        return {
            backgroundColor: backgroundColor, marginRight: '5%',
            fontSize: "18px", alignSelf: 'center',
            maxWidth: '200px', minWidth: '200px',
            maxHeight: '45px', minHeight: '45px'
        }
    };

    render() {
        return (
            <Modal open={true} closeOnDimmerClick={true} centered={false} closeOnDocumentClick={true} style={{ textAlign: 'center' }}>
                <Modal.Header>{"Reset PIN".localize()}</Modal.Header>
                <Modal.Content >
                    <PinTriggerComponent onRef={(ref) => this.pinTrigger = ref} addNotification={this.props.addNotification} noToggle />
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        style={this.getButtonStyle(BUTTON_TYPES.RESET)} variant="contained"
                        onClick={() => this.handleResetPin()}>
                        <p className="NewProfileAddButtonText" >{BUTTON_TYPES.RESET.localize()}</p>
                    </Button>
                    <Button
                        style={this.getButtonStyle(BUTTON_TYPES.REMOVE_PIN)} variant="contained"
                        onClick={() => this.handleRemovePin()}>
                        <p className="NewProfileAddButtonText" >{BUTTON_TYPES.REMOVE_PIN.localize()}</p>
                    </Button>
                    <Button
                        style={this.getButtonStyle(BUTTON_TYPES.BACK)} variant="contained"
                        onClick={() => this.props.closeResetModal()}>
                        <p className="NewProfileAddButtonText" >{BUTTON_TYPES.BACK.localize()}</p>
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}