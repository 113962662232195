import i18next from "i18next";
import strings_de from "./Helpers/Language/de.json";
import strings_en from "./Helpers/Language/en.json";

const i18n = i18next.init({
    interpolation: {
        // React already does escaping
        escapeValue: false,
    },
    lng: 'en',
    resources: {
        en: {
            translation: strings_en
        },
        de: {
            translation: strings_de
        },
    },

},
    (err, t) => {
        if (err) {
            return console.error(err)
        }
    }
);

export default i18next;
