import Button from '@material-ui/core/Button';
import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { Input, Label, Button as ButtonSm } from 'semantic-ui-react';
import BackendApi from '../api/app2backend';
import { matchPattern } from '../Helpers/ListHelpers';
import { getColorTheme } from '../config/flavor/mainFlavor';
import { createPriceTextFromValue, createPriceValueFromText, DISABLED_BUTTON_COLOR } from '../Helpers/ContentHelpers';
import AddDrinkModal from '../Components/AddDrinkModal';
import CustomImageSelector from '../Components/CustomImageSelector';
import DeleteModal from '../Components/DeleteModal';
import CustomDrinkInput from '../Components/CustomDrinkInput';

export default class PricesScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drinkList: [],
            updateList: [],
            openModal: false,
            validateInput: [],
            dataToDownload: [],
            personsColumn: [],
            isOpenDeleteModal: false,
            selectedDrink: {},
        }
    }

    componentWillMount() {
        this.getDrinkListFromDB();
    }

    handlePriceChange = (event, value, id) => {
        let validationArray = this.state.validateInput;
        const selectedValidationIndex = validationArray.findIndex((validation) => validation.index_id === id);
        let selectedValidation = validationArray[selectedValidationIndex];

        selectedValidation.active = matchPattern(value);
        validationArray[selectedValidationIndex] = selectedValidation;

        let actualValue = createPriceValueFromText(value);
        let updateArray = this.state.updateList;
        const selectedUpdateIndex = updateArray.findIndex((update) => update.index_id === id);
        const newUpdateOject = { index_id: id, value: actualValue };

        if (selectedUpdateIndex !== -1) {
            updateArray[selectedUpdateIndex] = newUpdateOject;
        } else {
            updateArray.push(newUpdateOject);
        }
        this.setState({ updateList: updateArray, validateInput: validationArray })
    };

    handleUpdate = (data) => {
        const { name, id } = data;
        const updateArray = this.state.updateList;
        const drinkToChange = updateArray.find((update) => update.index_id === id);
        if (drinkToChange) {
            if (drinkToChange.value > 0) {
                BackendApi.updateDrinks({ ...drinkToChange, ...{ drink_name: name } }, (res) => {
                    if (res.status === 200) {
                        this.props.addNotification(null, "successfullyCostUpdatedInfo".localize().format(name), "success")

                        let tempDrinkList = this.state.drinkList;
                        const selectedDrinkIndex = tempDrinkList.findIndex((drink) => drink.id === id);
                        tempDrinkList[selectedDrinkIndex] = { ...tempDrinkList[selectedDrinkIndex], ...{ name: name, cost: drinkToChange.value } };
                        this.setState({ drinkList: tempDrinkList })
                    }
                    else {
                        this.props.addNotification(null, "The cost of cannot be updated!".localize(), "error");
                    }
                });
            }
            else if (drinkToChange.value === 0) {
                this.props.addNotification(null, "The price cannot be zero!".localize(), "error");
            }
            else
                this.props.addNotification(null, "The price cannot be negative number!".localize(), "error");
        }
        else {
            this.props.addNotification(null, "Please enter the new price".localize(), "error");
        }
    }

    openModal = () => {
        this.setState({ openModal: true });
    };

    handleCloseModal = () => {
        this.setState({ openModal: false });
    };

    handleAddDrink = (drinkInfo) => {
        BackendApi.addDrink(drinkInfo, (res) => {
            if (res.status === 200) {
                this.props.addNotification(null, "successfullyDrinkAddedInfo".localize().format(drinkInfo.drink_name), "success")
                this.handleCloseModal();
                this.getDrinkListFromDB();
            }
            else {
                this.props.addNotification(null, "The drink cannot be added!".localize(), "error");
            }
        });

    };

    handleDeleteDrink = () => {
        BackendApi.deleteDrink(this.state.selectedDrink, (res) => {
            if (res.status === 200) {
                this.props.addNotification(null, "successfullyDrinkDeletedInfo".localize().format(this.state.selectedDrink.drink_name), "success")
                this.getDrinkListFromDB();
            }
            else {
                this.props.addNotification(null, "The drink cannot be deleted!".localize(), "error");
            }
            this.setState({ selectedDrink: null });
            this.closeDeleteModal();
        });
    };

    openDeleteModal = (data) => {
        const drinkInfo = {
            drink_id: data.id,
            drink_cost: data.cost,
            drink_name: data.name
        }
        this.setState({ isOpenDeleteModal: true, selectedDrink: drinkInfo });
    };

    closeDeleteModal = () => {
        this.setState({ isOpenDeleteModal: false, selectedDrink: null });
    };

    getDrinkListFromDB() {
        let tokenToSend;
        let localStorageInfo = localStorage.getItem('userInfo');
        localStorageInfo = JSON.parse(localStorageInfo);
        if (localStorageInfo && localStorageInfo.isAuthSuccess) {
            tokenToSend = localStorageInfo.token
            this.setState({ isUnauth: false });
        }
        else
            tokenToSend = this.props.token
        let info = { token: tokenToSend, mobile: false };
        BackendApi.getDrinks(info, (res, err) => {
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        this.setState({ isUnauth: true });
                        alert("You are unauthorized. Please login firstly!".localize());
                    }
                    else {
                        this.props.addNotification(null, "The drink cannot be added!".localize(), "error");
                    }
                }
            }
            else if (res) {
                let drinks = res.data;
                let activeList = [];
                drinks.forEach((drink) => {
                    activeList.push({ index_id: drink.id, active: false });
                });
                this.setState({ drinkList: drinks, validateInput: activeList });
            }
        });
    };

    handleImageUpdate = (drink_name, imageObj) => {
        const drinkInfo = { drink_name, ...imageObj };
        BackendApi.updateImagesDrink({ drinkInfo }, (res, err) => {
            if (res.data) {
                this.getDrinkListFromDB();
            } else {
                this.props.addNotification(null, "Image cannot be updated".localize(), "error");
            }
        });
    };

    handleChangeDrinkName = (newName, selectedDrink) => {
        if (newName === "" || newName === null) {
            this.props.addNotification(null, "Drink name cannot be empty".localize(), "error");
        } else {
            const drinkInfo = {
                drink_id: selectedDrink.id, drink_name: selectedDrink.name,
                newName: newName, drink_cost: selectedDrink.cost,
                image: selectedDrink.image, inactive_image: selectedDrink.inactive_image
            };
            BackendApi.updateNameDrink({ drinkInfo }, (res, err) => {
                if (res.data) {
                    this.getDrinkListFromDB();
                } else {
                    this.props.addNotification(null, "Drink name cannot be updated".localize(), "error");
                }
            });
        }
    };

    checkValidate = (id) => {
        const validationArray = this.state.validateInput;
        const selectedValidation = validationArray.find((validation) => validation.index_id === id);
        return selectedValidation.active;
    };

    getButtonStyle = (id) => {
        return {
            backgroundColor: this.checkValidate(id) ? getColorTheme().selectedTheme : DISABLED_BUTTON_COLOR,
            fontSize: "18px", marginLeft: '3%',
            maxWidth: '170px', minWidth: '170px',
            maxHeight: '35px', minHeight: '35px',
        }
    };

    getAddButtonStyle = () => {
        return {
            backgroundColor: getColorTheme().selectedTheme, fontSize: "18px", marginLeft: '3%',
            minWidth: '170px', maxHeight: '34px', minHeight: '34px',
        }
    };

    render() {
        return (
            this.state.isUnauth ? <Redirect to={{
                pathname: '/',
                state: { token: this.state.token }
            }} /> :
                <div className="Prices">
                    <div style={{ flex: 6, flexDirection: 'column', marginTop: '3%', }} >
                        {this.state.openModal ?
                            <AddDrinkModal
                                open={this.state.openModal}
                                addButtonColor={getColorTheme().selectedTheme}
                                addDrink={this.handleAddDrink}
                                theme={getColorTheme()}
                                addNotification={this.props.addNotification}
                                onCloseModal={this.handleCloseModal}
                            /> : null}
                        <div style={{ alignItems: 'center', width: '100%', height: '5%', justifyContent: 'center', }} >
                            <p className="PricesCurrentText" >{"Current Price".localize()}</p>
                            <p className="PricesNewText" >{"New Price".localize()}</p>
                        </div>
                        <div style={{ flexDirection: 'column', width: '100%', height: '95%' }} >
                            {
                                this.state.drinkList.map((data, index) => {
                                    return (
                                        <div key={`${data.name}_${data.id}`} className="DrinkContainer">
                                            <CustomImageSelector
                                                data={data} theme={getColorTheme()}
                                                addNotification={this.props.addNotification} key={`${data.inactive_image}_${index}`}
                                                style={{ width: '15%' }} onRef={(ref) => this.imageSelector = ref}
                                                onApply={(imageObj) => this.handleImageUpdate(data.name, imageObj)} />
                                            <div style={{ width: '20%', marginLeft: '5%' }} >
                                                <CustomDrinkInput drinkName={data.name} onApplyDrinkName={(newName) => this.handleChangeDrinkName(newName, data)} />
                                            </div>
                                            <div style={{ width: '15%', }} >
                                                <p className="PricesCostText">{createPriceTextFromValue(data.cost)}</p>
                                            </div>
                                            <Input
                                                style={{ marginLeft: '7%' }}
                                                labelPosition='right' type='text' placeholder=''
                                                error={!this.checkValidate(data.id)}
                                                onChange={(event, inputData) => this.handlePriceChange(event, inputData.value, data.id)} >
                                                <input />
                                                <Label basic>{'\u20AC'}</Label>
                                            </Input>
                                            <Button
                                                style={this.getButtonStyle(data.id)}
                                                variant="contained"
                                                onClick={() => this.handleUpdate(data)}
                                                disabled={!this.checkValidate(data.id)}>
                                                <p style={{ fontFamily: 'kelson1', color: 'white' }} >{"Update".localize()}</p>
                                            </Button>
                                            <ButtonSm style={{ marginLeft: '1%', color: getColorTheme().errorTheme }}
                                                onClick={() => this.openDeleteModal(data)} circular icon='trash' />
                                        </div>
                                    )
                                })
                            }
                            <Button
                                style={this.getAddButtonStyle()}
                                variant="contained"
                                onClick={() => this.openModal()}>
                                <p style={{ fontFamily: 'kelson2', color: 'white' }} >{"Add New Drink".localize()}</p>
                            </Button>
                        </div>
                    </div>
                    <div style={{ flex: 2, }} />
                    {
                        this.state.isOpenDeleteModal ?
                            <DeleteModal
                                handleDelete={this.handleDeleteDrink}
                                closeDelete={this.closeDeleteModal} /> : null
                    }
                </div>
        );
    }
}