import axios from 'axios';
import { coworkingSpaceObject } from "../config/flavor/mainFlavor";
const connectionConfig = coworkingSpaceObject.getConnectionInfo();

const baseUrl = connectionConfig['server-url'];
const baseUrl3 = connectionConfig['local-ip'];
const baseUrl2 = connectionConfig['local-host'];

axios.defaults.withCredentials = true

const HEADERS = {
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
    },
    withCredentials:true,
};

function createAuthHeaderWithToken(token) {
    // TODO: need to assigment once 
    return {
        headers: {
            'Authorization': "Bearer " + token
        },
        withCredentials: true
    }
};

function createURLWithPath(path) {
    return baseUrl + path;
};

function labeledWithNonMobile(object) {
    // TODO: false or 'web' need to change server.js
    object["mobile"] = 'web';
};

class BackendApi {
    uploadImageURL = () => {
        return createURLWithPath("uploadFile");
    };

    clearFile = (filename, callback) => {
        let promise = axios.post(createURLWithPath("clearFile"), { filename: filename }, HEADERS);
        promise.then((res) => {
            callback(res, null);
        }).catch((error) => {
            callback({}, error);
        });
    };

    checkAuthentication = (credentials, callback) => {
        let promise = axios.post(createURLWithPath("auth"), credentials, HEADERS);
        promise.then((res) => {
            callback(res, null);
        }).catch((error) => {
            callback({}, error);
        });
    };

    createPayment = (paymentInfo, callback) => {
        let promise = axios.post(createURLWithPath("createPayment"), paymentInfo, HEADERS);
        promise.then((res) => {
            callback(res);
        }).catch((error) => {
            callback(error);
        })
    };

    createDonation = (paymentInfo, callback) => {
        let promise = axios.post(createURLWithPath("createDonation"), paymentInfo, HEADERS);
        promise.then((res) => {
            callback(res);
        }).catch((error) => {
            callback(error);
        })
    };

    getPersonWithAll = (index, callback) => {
        let promise = axios.get(createURLWithPath("getPersonWithAll"), { params: index }, HEADERS);
        promise.then((res) => {
            callback(res);
        }).catch((error) => {
            callback(error);
        });
    };

    getSpendendosen = (index, callback) => {
        let promise = axios.get(createURLWithPath("getSpendendosen"), { params: index }, HEADERS);
        promise.then((res) => {
            callback(res);
        }).catch((error) => {
            callback(error);
        });
    };

    getSpendendosenAll = (index, callback) => {
        let promise = axios.get(createURLWithPath("getSpendendosenAll"), { params: index }, HEADERS);
        promise.then((res) => {
            callback(res);
        }).catch((error) => {
            callback(error);
        });
    };

    getPersonalPurchaseMonth = (info, callback) => {
        let promise = axios.get(createURLWithPath("getPersonalPurchaseMonth"), { params: info }, HEADERS);
        promise.then((res) => {
            callback(res);
        }).catch((error) => {
            callback(error);
        });
    };

    getProfilesOfFloor = (index, callback) => {
        let promise = axios.get(createURLWithPath("getProfile"), { params: { index: index } }, HEADERS);
        promise.then((res) => {
            callback(res);
        }).catch((error) => {
            callback(error);
        });
    };

    searchProfile = (searchInfo, callback) => {
        let promise = axios.get(createURLWithPath("searchProfile"), { params: searchInfo }, HEADERS);
        promise.then((res) => {
            callback(res);
        }).catch((error) => {
            callback(error);
        });
    };

    signUp = (credentials, callback) => {
        let promise = axios.post(createURLWithPath("signUp"), credentials, HEADERS);
        promise.then((res, err) => {
            callback(res);
        }).catch((error) => {
            callback(null, error)
        });
    };

    addProfile = (personInfo, callback) => {
        let promise = axios.post(createURLWithPath("addProfile"), personInfo, createAuthHeaderWithToken(personInfo.token));
        promise.then((res, err) => {
            callback(res);
        }).catch((error) => {
            callback(null, error)
        });
    };

    updateDrinks = (drinkInfo, callback) => {
        let promise = axios.put(createURLWithPath("updateDrink"), drinkInfo, HEADERS);
        promise.then((res) => {
            callback(res);
        }).catch((error) => {
            callback(error);
        })
    };

    updateImagesDrink = (drinkInfo, callback) => {
        let promise = axios.put(createURLWithPath("updateImagesDrink"), drinkInfo, HEADERS);
        promise.then((res) => {
            callback(res);
        }).catch((error) => {
            callback(error);
        })
    };

    updateNameDrink = (drinkInfo, callback) => {
        let promise = axios.put(createURLWithPath("updateNameDrink"), drinkInfo, HEADERS);
        promise.then((res) => {
            callback(res);
        }).catch((error) => {
            callback(error);
        })
    };

    updateUserActive = (personInfo, callback) => {
        let promise = axios.put(createURLWithPath("updatePersonActive"), personInfo, HEADERS)
        promise.then((res) => {
            callback(res);
        }).catch((error) => {
            callback(error);
        });
    };

    updateUserInvoice = (personInfo, callback) => {
        let promise = axios.put(createURLWithPath("updatePersonInvoice"), personInfo, HEADERS)
        promise.then((res) => {
            callback(res);
        }).catch((error) => {
            callback(error);
        });
    };

    addDrink = (drinkInfo, callback) => {
        let promise = axios.post(createURLWithPath("addDrink"), drinkInfo, HEADERS);
        promise.then((res) => {
            callback(res);
        }).catch((error) => {
            callback(error);
        });
    };

    addPurchaseOfPerson = (purchaseInfo, callback) => {
        purchaseInfo['mobile'] = false;
        let promise = axios.post(createURLWithPath("addOrder"), purchaseInfo, HEADERS);
        promise.then((res) => {
            callback(res);
        }).catch((error) => {
            callback(error);
        });
    };

    updateFloorOfPerson = (personInfo, callback) => {
        let promise = axios.put(createURLWithPath("changeFloor"), personInfo, HEADERS);
        promise.then((res) => {
            callback(res);
        }).catch((error) => {
            callback(error);
        })
    };

    changeEmail = (personInfo, callback) => {
        let promise = axios.put(createURLWithPath("changeEmail"), personInfo, HEADERS);
        promise.then((res) => {
            callback(res);
        }).catch((error) => {
            callback(error);
        });
    };

    changeNote = (personInfo, callback) => {
        let promise = axios.put(createURLWithPath("changeNote"), personInfo, HEADERS);
        promise.then((res) => {
            callback(res);
        }).catch((error) => {
            callback(error);
        });
    };

    changeNameAndSurname = (personInfo, callback) => {
        let promise = axios.put(createURLWithPath("changeNameAndSurname"), personInfo, HEADERS);
        promise.then((res) => {
            callback(res);
        }).catch((error) => {
            callback(error);
        });
    };

    deleteDrink = (drinkInfo, callback) => {
        let promise = axios.post(createURLWithPath("deleteDrink"), drinkInfo, HEADERS);
        promise.then((res) => {
            callback(res);
        }).catch((error) => {
            callback(error);
        });
    };

    deleteProfile = (id, callback) => {
        let promise = axios.delete(createURLWithPath("deleteProfile"), { params: id }, HEADERS);
        promise.then((res) => {
            callback(res);
        }).catch((error) => {
            callback(error);
        });
    };

    resetPin = (info, callback) => {
        let promise = axios.post(createURLWithPath("resetPin"), info, HEADERS);
        promise.then((res) => {
            callback(res);
        }).catch((error) => {
            callback(error);
        });
    };

    deleteTransaction = (deleteInfo, callback) => {
        let promise = axios.delete(createURLWithPath("deleteTransaction"), { params: deleteInfo }, HEADERS);
        promise.then((res) => {
            callback(res);
        }).catch((error) => {
            callback(error);
        });
    };

    deleteSpendendosenTransaction = (deleteInfo, callback) => {
        let promise = axios.delete(createURLWithPath("deleteSpendendosenTransaction"), { params: deleteInfo }, HEADERS);
        promise.then((res) => {
            callback(res);
        }).catch((error) => {
            callback(error);
        });
    };

    getPersonalTransaction = (personInfo, callback) => {
        let promise = axios.get(createURLWithPath("getPersonalBalance"), { params: personInfo }, createAuthHeaderWithToken(personInfo.token));
        promise.then((res, err) => {
            callback(res);
        }).catch((err) => {
            callback(null, err)
        });
    };

    getSpendendosenTransaction = (personInfo, callback) => {
        let promise = axios.get(createURLWithPath("getSpendendosenBalance"), { params: personInfo }, createAuthHeaderWithToken(personInfo.token));
        promise.then((res, err) => {
            callback(res);
        }).catch((err) => {
            callback(null, err)
        });
    };

    getDashBoardInfos = (info, callback) => {
        let promise = axios.get(createURLWithPath("getDashBoardInfos"), { params: info }, createAuthHeaderWithToken(info.token));
        promise.then((res, err) => {
            callback(res);
        }).catch((err) => {
            callback(null, err)
        });
    };

    initializeMainScreen = (info, callback) => {
        let promise = axios.get(createURLWithPath("main"), { params: info }, createAuthHeaderWithToken(info.token));
        promise.then((res, err) => {
            callback(res);
        }).catch((err) => {
            callback(null, err)
        });
    };

    getDrinks = (info, callback) => {
        labeledWithNonMobile(info); // todo: info['mobile'] = "web" give the support
        let promise = axios.get(createURLWithPath("getDrinks"), { params: info }, createAuthHeaderWithToken(info.token));
        promise.then((res, err) => {
            callback(res);
        }).catch((err) => {
            callback(null, err)
        });
    };

    getFloors = (info, callback) => {
        labeledWithNonMobile(info); // todo: info['mobile'] = "web" give the support
        let promise = axios.get(createURLWithPath("getFloors"), { params: info }, createAuthHeaderWithToken(info.token));
        promise.then((res, err) => {
            callback(res);
        }).catch((err) => {
            callback(null, err)
        });
    };

    getDrinksTransaction = (info, callback) => {
        labeledWithNonMobile(info); // todo: info['mobile'] = "web" give the support
        let promise = axios.get(createURLWithPath("getAllDrinksBalance"), { params: info }, createAuthHeaderWithToken(info.token));
        promise.then((res, err) => {
            callback(res);
        }).catch((err) => {
            callback(null, err)
        });
    };

    getAllOrder = (info, callback) => {
        labeledWithNonMobile(info); // todo: info['mobile'] = "web" give the support
        let promise = axios.get(createURLWithPath("getAllOrder"), { params: info }, createAuthHeaderWithToken(info.token));
        promise.then((res, err) => {
            callback(res);
        }).catch((err) => {
            callback(null, err)
        });
    };

    updatePassword = (info, callback) => {
        let promise = axios.post(createURLWithPath("changePassword"), info, HEADERS);
        promise.then((res) => {
            callback(res, null);
        }).catch((error) => {
            callback({}, error);
        });
    };

    getDevices = (info, callback) => {
        let promise = axios.get(createURLWithPath("getDevices"), { params: info }, HEADERS);
        promise.then((res, err) => {
            callback(res);
        }).catch((error) => {
            callback(null, error);
        });
    };

    addDevices = (info, callback) => {
        let promise = axios.post(createURLWithPath("addDevices"), info, HEADERS);
        promise.then((res, err) => {
            callback(res, null);
        }).catch((error) => {
            callback(null, error);
        });
    };

    updateDevices = (info, callback) => {
        let promise = axios.put(createURLWithPath("updateDevices"), info, HEADERS);
        promise.then((res, err) => {
            callback(res, null);
        }).catch((error) => {
            callback(null, error);
        });
    };

    getOrdersWithDeviceId = (info, callback) => {
        let promise = axios.get(createURLWithPath("getOrdersWithDeviceId"), { params: info }, HEADERS);
        promise.then((res, err) => {
            callback(res);
        }).catch((error) => {
            callback(null, error);
        });
    };

    // NEXUDUS API CALLS
    getNexudusUsers = (User_Email,successCb,errorCb) => {
        let promise = axios.get(createURLWithPath("nexudus/users"), {params:{User_Email}} , HEADERS);
        promise.then((res,err)=>{
            successCb(res)
        }).catch((err)=>{
            errorCb(err.response.data)
        })
    }
    
    syncProfileToNexudusUser = (info,successCb,errorCb) => {
        let promise = axios.post(createURLWithPath("nexudus/syncProfile"), info , HEADERS);
        promise.then((res,err)=>{
            successCb(res)
        }).catch((err)=>{
            errorCb(err)
        })
    }
/*
    authNexudus = (successCb,errorCb) => {
        let promise = axios.post(createURLWithPath("nexudus/auth"), null , HEADERS);
        promise.then((res,err)=>{
            successCb(res)
        }).catch((err)=>{
            errorCb(err)
        })
    }
*/
    transferBalanceToNexudus = (info,successCb,errorCb) => {
        let promise = axios.post(createURLWithPath("nexudus/transferBalance"), info , HEADERS);
        promise.then((res,err)=>{
            successCb(res)
        }).catch((err)=>{
            errorCb(err.response.data)
        })
    }

    addCoworkerProductToQue = (info,successCb,errorCb) => {
        let promise = axios.post(createURLWithPath("nexudus/addCoworkerProductToQue"), info , HEADERS);
        promise.then((res,err)=>{
            successCb(res)
        }).catch((err)=>{
            errorCb(err.response.data)
        })
    }

    deleteTransactionForThirdParty = (deleteInfo,third_party_name, callback) => {
        let promise = axios.delete(createURLWithPath(`${third_party_name.toLowerCase()}/delete`), { params: deleteInfo }, HEADERS);
        promise.then((res) => {
            callback(res);
        }).catch((error) => {
            callback(error);
        });
    };

    getCustomersLatestQue = (successCb,errorCb) => {
        let promise = axios.get(createURLWithPath("que/users"), {} , HEADERS);
        promise.then((res,err)=>{
            successCb(res)
        }).catch((err)=>{
            console.log("err : ",err )
            errorCb(err.response.data)
        })
    }

}

export default new BackendApi();