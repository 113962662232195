import React from 'react';
import { Icon, Form, TextArea } from 'semantic-ui-react';
import CustomTooltip from './CustomTooltip';
import { isNotNullAndUndefined, stringLenght, stringShortener } from '../Helpers/ContentHelpers'

export default class CustomNoteInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        const noNoteText = "No Note".localize();
        return <div className="ProfileUpdateProperty">
            <div className="ProfileTextContainer">
                <p className="ProfileTextValueMail" style={{ float: 'left' }}>{"Note".localize()}</p>
            </div>
            <div className="ProfileValueContainer">
                <div className="ProfileFirstLinesMailContainer" >
                    <div style={{ width: '70%' }}>
                        {/* Open Text Input */}
                        {
                            this.props.openNoteTextInput ?
                                <Form>
                                    <TextArea
                                        onChange={this.props.handleNoteTextInput}
                                        value={this.props.noteTextInput}
                                        placeholder=''
                                        style={{ width: 220, height: 50, marginTop: -10, resize: 'none' }} />
                                </Form> :
                                stringLenght(this.props.profileUpdateInfo.note) > 18 ?
                                    <CustomTooltip title={this.props.profileUpdateInfo.note} style={{ float: 'right' }} fontSize='20px'>
                                        <p className={"ProfileTextValueMail"}>
                                            {stringShortener(this.props.profileUpdateInfo.note)}
                                        </p>
                                    </CustomTooltip> :
                                    <p className={"ProfileTextValueMail"}>
                                        {isNotNullAndUndefined(this.props.profileUpdateInfo.note) ? this.props.profileUpdateInfo.note : noNoteText}
                                    </p>
                        }
                    </div>
                    <div style={{ marginTop: 3, marginLeft: 10 }} >
                        {
                            !this.props.openNoteTextInput ?
                                <Icon
                                    onClick={this.props.handleChangeNote}
                                    size='large'
                                    name="edit" /> :
                                <div>
                                    <div style={{ marginTop: 5, float: 'left', marginLeft: 10, display: 'flex', flexDirection: 'row' }} >
                                        <Icon
                                            onClick={this.props.changePersonNote}
                                            size='large'
                                            name="check circle" />
                                        <Icon
                                            onClick={this.props.closeNoteInput}
                                            size='large'
                                            name="close" />
                                    </div>
                                </div>
                        }
                    </div>
                    <div style={{ width: '60%', justifyContent: 'center', alignItems: 'center', }} />
                </div>
                <div style={{ width: '70%' }} />
            </div>
        </div>
    }
}