import React from 'react';
import { Input, Icon } from 'semantic-ui-react';

export default class CustomMailInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return <div className="ProfileUpdateProperty" >
            <div className="ProfileTextContainer">
                <p className="ProfileTextValueMail" style={{ float: 'left' }}>{"E-Mail".localize()}</p>
            </div>
            <div className="ProfileValueContainer" >
                <div className="ProfileFirstLinesMailContainer" >
                    <div style={{ width: '70%' }}>
                        {/* Open Text Input */}
                        {
                            this.props.openMailTextInput ?
                                <Input
                                    iconPosition={"left"}
                                    icon="mail"
                                    onChange={this.props.handleEmailTextInput}
                                    value={this.props.emailTextInput}
                                    style={{ width: '90%', height: '50%' }} /> :
                                <p className={"ProfileTextValueMail"}>
                                    {this.props.profileUpdateInfo.email}
                                </p>
                        }
                    </div>
                    <div style={{ marginTop: 3, marginLeft: 10 }} >
                        {
                            !this.props.openMailTextInput ?
                                <Icon
                                    onClick={this.props.handleChangeEmail}
                                    size='large'
                                    name="edit" /> :
                                <div>
                                    <div style={{ marginTop: 5, float: 'left', marginLeft: 10, display: 'flex', flexDirection: 'row' }} >
                                        <Icon
                                            onClick={this.props.changePersonEmail}
                                            size='large'
                                            name="check circle" />
                                        <Icon
                                            onClick={this.props.closeEmailInput}
                                            size='large'
                                            name="close" />
                                    </div>
                                </div>
                        }
                    </div>
                    <div style={{ width: '60%', justifyContent: 'center', alignItems: 'center', }} />
                </div>
                <div style={{ width: '70%' }} />
            </div>
        </div>
    }
}