import React, { Component } from 'react';
import { Input, Button as ButtomSm } from 'semantic-ui-react';
import BackendApi from '../api/app2backend';
import '../Main.css';

class ProfileScreenHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
    };

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(undefined);
        }
    };

    handleHeaderClick = (index) => {
        this.props.hightLightSelectedFloor(index);
        this.props.getPersonsOfFloor(index);
        this.props.updateFloorIndex(index);
        this.props.changeSearchText("");
    };

    handleSearch = (event, data) => {
        var selectedFloor = this.props.floorList.find((floor) => floor.isActive === 1);

        if (data.value.length > 0) {
            var searchInfo = {
                searchQuery: data.value,
                floorNumber: selectedFloor.id
            }
            BackendApi.searchProfile(searchInfo, (res) => {
                // console.log("ressearch", res);
                if (res.data) {
                    if (res.data.length > 0) {
                        var persons = res.data;
                        if (persons.length === 0)
                            this.setState({ personList: persons });
                        else {
                            this.props.updateList(persons);
                        }

                    }
                    else {// Hiç bir sonuç yok ise 
                        this.props.updateList("");
                    }
                }
            });
        }
        else {//Eğer length 0 ise eski haline dönsün
            BackendApi.getProfilesOfFloor(selectedFloor.id, (res) => {
                this.props.updateList(res.data);
            });
        }
        this.props.changeSearchText(data.value);
    }

    checkActive = (index) => {
        return this.props.floorList[index].isActive === 1;
    };

    render() {
        return (
            <div className="profilesHeader">
                {
                    this.props.floorList.map((data, index) => {
                        return (
                            <ButtomSm
                                key={index}
                                style={{ backgroundColor: "transparent", fontSize: "18px", borderWidth: 0, marginLeft: '2%' }}
                                onClick={() => this.handleHeaderClick(data.id)}>
                                <p className={this.checkActive(index) ? "ProfilesHeaderTextActive" : "ProfilesHeaderText"} >{data.name}</p>
                            </ButtomSm>
                        )
                    })
                }
                <div style={{ marginLeft: "2%" }} >
                    <Input icon={{ name: 'search' }} placeholder={"Search".localize() + "..."} value={this.props.searchText}
                        onChange={(event, data) => this.handleSearch(event, data)} />
                </div>
            </div>
        );
    }
}

export default ProfileScreenHeader;
