import React from 'react'
import {Popup } from 'semantic-ui-react'

function CustomPopup(props) {
    return (
        <Popup content={props.content} trigger={props.trigger} />
    )
}

export default CustomPopup
