import React from 'react';
import BackendApi from '../api/app2backend';
import DeleteModal from '../Components/DeleteModal';
import ResetModal from '../Components/ResetModal';
import CustomReactTable from '../Components/CustomReactTable';
import CSVExporterButton from '../Components/CSVExporterButton';
import CustomPersonInput from '../Components/CustomPersonInput';
import CustomMailInput from '../Components/CustomMailInput';
import CustomNoteInput from '../Components/CustomNoteInput';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import {Icon, Input, Label} from 'semantic-ui-react';
import {
    createPriceTextFromValue, createPriceValueFromText,
    sortByDate, sortByPrice,
    isNotNullAndUndefined, createProfileTransactionData
} from '../Helpers/ContentHelpers';
import { getColorTheme, getBackImage, getBorderWithTheme, isHaveNexudus } from '../config/flavor/mainFlavor';
import { MOMENT_VARIABLES } from '../Helpers/const';
import {
    matchPattern, generatePaymentInfo, generatePurchase
} from '../Helpers/ListHelpers';
import { generateHashedPin } from '../Helpers/hashHelper';
import { momentIsBetween } from '../Helpers/TimeHelper';
import DatePickerModalTrigger from '../Components/DatePickerModalTrigger';
import CustomDropdown from "../Components/CustomDropdown";
import GroupButton from "../Components/GroupButton";
import SearchInput from "../Components/SearchInput";
import CustomModal from "../Components/CustomModal"

const ROOM_KEY = "room";
const NOTE_KEY = "note";
export default class SelectedProfileScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isUnauth: false,
            openNameAndSurnameTextInput: false,
            openNoteTextInput: false,
            openMailTextInput: false,
            profileUpdateInfo: this.props.profileUpdateInfo,
            activePaymentInput: false,
            dataToDownload: [],
            orderTableData: [],
            deviceList: [],
            drinkList: [],
            nexudusUsers:[],
            nexudusSearchValue:this.props.profileUpdateInfo.nexudus_email || this.props.profileUpdateInfo.email,
            isLoading:false,
            isFirstDisable:false,
            isSecondDisable:true,
            selectedNexudusUser:{Id:"",Email:""},
            syncStatus:false,
            openTransferModal:false,
            isTransfering:false,
            isDeletingTransaction:false
        };
    }

    componentDidMount() {
        this.fetchTableData(this.props.profileUpdateInfo.id);
        let info = { token: this.props.tokenActual, mobile: false };
        this.fetchDrinks(info);
        this.fetchDevices(info);
        if(this.state.profileUpdateInfo.nexudus_id){
            this.setState({syncStatus:true})
        }
    }

    fetchTableData = (person_id) => {
        let personSendInfo = {
            id: person_id,
            token: this.props.tokenActual,
            mobile: "web"
        }
        BackendApi.getPersonalTransaction(personSendInfo, (res, err) => {
            if (res.data) {
                const tableData = createProfileTransactionData(res.data);
                this.setState({ orderTableData: tableData });
            }
            else
                this.props.addNotification(null, "Connection closed!".localize(), "error");
        });
    };

    fetchDrinks = (info) => {
        BackendApi.getDrinks(info, (res, err) => {
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        this.setState({ isUnauth: true });
                        alert("You are unauthorized. Please login firstly!");
                    }
                    else {
                        this.props.addNotification("The drink list could not be fetched!", "error");
                    }
                }
            }
            else if (res.data) {
                var drinks = res.data;
                this.setState({ drinkList: drinks });
            }
        });
    };

    fetchDevices = (info) => {
        BackendApi.getDevices(info, (res, err) => { // maybe need parameter for request
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        this.setState({ isUnauth: true });
                        alert("You are unauthorized. Please login firstly!");
                    }
                    else {
                        this.props.addNotification("The device list could not be fetched!", "error");
                    }
                }
            }
            else if (res) {
                this.setState({ deviceList: res.data });
            }
        });
    };

    changePersonNameAndSurname = () => {
        let personInfo = this.state.profileUpdateInfo;
        let newName = this.state.nameTextInput;
        if (personInfo.type !== ROOM_KEY && newName === "")
            this.props.addNotification(null, "Name and surname cannot be empty".localize(), "error");
        else {
            let tempName = newName.replace(/\s+/g, ' ').trim();
            personInfo['newName'] = tempName;
            BackendApi.changeNameAndSurname(personInfo, (res, err) => {
                if (res) {
                    this.props.addNotification(null, "The name and surname changed successfully".localize(), "success");
                    personInfo.name = tempName;
                    this.setState({ openNameAndSurnameTextInput: false, profileUpdateInfo: personInfo });
                }
            });
        }
    };

    changePersonEmail = () => {
        let personInfo = this.state.profileUpdateInfo;
        let newEmail = this.state.emailTextInput;
        if (newEmail === "")
            this.props.addNotification(null, "Email cannot be empty".localize(), "error");
        else {
            personInfo['newEmail'] = newEmail;
            BackendApi.changeEmail(personInfo, (res, err) => {
                if (res) {
                    this.props.addNotification(null, "The email changed successfully".localize(), "success");
                    personInfo.email = newEmail;
                    this.setState({ openMailTextInput: false, profileUpdateInfo: personInfo });
                }
            });
        }
    };

    changePersonNote = () => {
        let personInfo = this.state.profileUpdateInfo;
        let newNote = this.state.noteTextInput;
        if (newNote === "")
            this.props.addNotification(null, "Note cannot be empty".localize(), "error");
        else {
            personInfo['newNote'] = newNote;
            console.log(personInfo)
            BackendApi.changeNote(personInfo, (res, err) => {
                if (res) {
                    this.props.addNotification(null, "The note changed successfully".localize(), "success");
                    personInfo.note = newNote;
                    this.setState({ openNoteTextInput: false, profileUpdateInfo: personInfo });
                }
            });
        }
    };

    handleNameTextInput = (event, data) => {
        this.setState({ nameTextInput: data.value });
    };

    openNameAndSurnameInput = () => {
        this.setState({
            openNameAndSurnameTextInput: !this.state.openNameAndSurnameTextInput,
            nameTextInput: this.state.profileUpdateInfo.name,
        });
    };

    handleEmailTextInput = (event, data) => {
        this.setState({ emailTextInput: data.value });
    };

    handleNoteTextInput = (event, data) => {
        this.setState({ noteTextInput: data.value });
    };

    handleChangeNote = () => {
        this.setState({ openNoteTextInput: !this.state.openNoteTextInput, noteTextInput: this.state.profileUpdateInfo.note });
    };

    handleChangeEmail = () => {
        this.setState({ openMailTextInput: !this.state.openMailTextInput, emailTextInput: this.state.profileUpdateInfo.email });
    };

    handleNewPaymentInput = (event, data) => {
        if (matchPattern(data.value))
            this.setState({ activePaymentInput: true })
        else
            this.setState({ activePaymentInput: false })

        let actualValue = createPriceValueFromText(data.value);
        this.setState({ newPaymentValue: actualValue });
    };

    handleNewPurchaseInput = (event, data) => {
        if (matchPattern(data.value))
            this.setState({ activePurchaseInput: true })
        else
            this.setState({ activePurchaseInput: false })

        var actualValue = createPriceValueFromText(data.value);
        this.setState({ purchaseInput: actualValue });
    };

    handlePurchaseTextInput = (event, data) => {
        this.setState({ selectedDrinkPurchase: data.value });
    };

    handleNewPayment = () => {
        let paymentValue = this.state.newPaymentValue;
        let personInfo = this.state.profileUpdateInfo;
        if (this.state.activePaymentInput) {
            if (paymentValue > 0) {
                let paymentInfo = generatePaymentInfo(personInfo, paymentValue);
                BackendApi.createPayment(paymentInfo, (res) => {
                    if (res.data) {
                        let { totalBalanceOfPerson } = res.data;
                        let totalCost = totalBalanceOfPerson;
                        personInfo.Account.balance = totalCost;
                        this.props.addNotification(null, "The payment is added successfully".localize(), "success");
                        //Update Transaction History State
                        this.setState({ profileUpdateInfo: personInfo });
                        this.fetchTableData(personInfo.id);
                    }
                    else {
                        this.props.addNotification(null, "The payment cannot be added!".localize(), "error");
                    }
                });
            }
            else if (paymentValue === 0)
                this.props.addNotification(null, "Value cannot be zero!".localize(), "error");
            else
                this.props.addNotification(null, "Value cannot be negative integer!".localize(), "error");
        }
        else {
            this.props.addNotification(null, "Please enter a valid value!".localize(), "error");
        }
    };

    handlePurchase = () => {
        let selectedDrink = this.state.selectedDrinkPurchase;
        if (selectedDrink !== "") {
            if (this.state.activePurchaseInput) {
                if (this.state.purchaseInput > 0) {
                    let personInfo = this.state.profileUpdateInfo;
                    let purchase = generatePurchase(selectedDrink, personInfo, parseFloat(this.state.purchaseInput));
                    BackendApi.addPurchaseOfPerson(purchase, (res) => {
                        if (res.data) {
                            //Update state
                            var totalCost = res.data.totalBalanceOfPerson;
                            personInfo.Account.balance = totalCost;
                            if (personInfo.current_month_cost === undefined)
                                personInfo.current_month_cost = -parseFloat(this.state.purchaseInput);
                            else
                                personInfo.current_month_cost = personInfo.current_month_cost + -parseFloat(this.state.purchaseInput);

                            this.props.addNotification(null, "The purchase is added successfully".localize(), "success");
                            this.setState({ profileUpdateInfo: personInfo });
                            this.fetchTableData(personInfo.id);
                        } else {
                            this.props.addNotification(null, "The purchase cannot be added!".localize(), "success");
                        }
                    })
                }
                else if (this.state.purchaseInput === 0)
                    this.props.addNotification(null, "Value cannot be zero!".localize(), "error");
                else
                    this.props.addNotification(null, "Value cannot be negative integer!".localize(), "error");
            }
            else {
                this.props.addNotification(null, "Please enter a valid value!".localize(), "error");
            }
        }
        else
            this.props.addNotification(null, "Please select a drink to purchase!".localize(), "error");
    };

    handleChangeFloorOfPerson = (event) => {
        var floorToChange = event.target.value;
        var person = this.state.profileUpdateInfo;
        //If not same floor
        if (person.floor_id !== floorToChange) {
            var personInfo = {
                id: person.id,
                floor: floorToChange
            }
            BackendApi.updateFloorOfPerson(personInfo, (res) => {
                if (res.status === 200) {
                    //Update the state
                    person.floor_id = floorToChange;
                    this.setState({ profileUpdateInfo: person });
                }
            });
        }
    };

    handleActiveProfileChange = (event, checked) => {
        let currentValue = this.state.profileUpdateInfo.Account.activated;
        var temp = this.state.profileUpdateInfo;
        temp.Account.activated = !currentValue;
        var reqInfo = {
            id: temp.id,
            value: temp.Account.activated,
        }
        //Update the state
        BackendApi.updateUserActive(reqInfo, (res) => {
            if (res.status === 200) {
                this.props.addNotification(null, "The activity of person changed successfully".localize(), "success");
                this.setState({ profileUpdateInfo: temp });
            }
            else {
                this.props.addNotification(null, "The activity of person cannot be changed!".localize(), "error");
            }
        });
    };

    handleInvoiceProfileChange = (event, checked) => {
        let currentValue = this.state.profileUpdateInfo.Account.warning_limit;
        var temp = this.state.profileUpdateInfo;
        temp.Account.warning_limit = !currentValue;
        var reqInfo = {
            id: temp.id,
            value: temp.Account.warning_limit,
        }
        //Update the state
        BackendApi.updateUserInvoice(reqInfo, (res) => {
            if (res.status === 200) {
                this.props.addNotification(null, "The invoice of person changed successfully".localize(), "success");
                this.setState({ profileUpdateInfo: temp });
            }
            else {
                this.props.addNotification(null, "The invoice of person cannot be changed!".localize(), "error");
            }
        });
    };

    deleteTransaction = (row) => {
        let personInfo = this.state.profileUpdateInfo;
        let transaction = row.original;
        let priceType = typeof (transaction.price);
        if (priceType === "string")
            transaction.price = createPriceValueFromText(transaction.price);
        const deleteInfo = { person_id: personInfo.id, transaction_id: transaction.id };
        this.setState({isDeletingTransaction:true})
        if(!transaction.third_party){
            BackendApi.deleteTransaction(deleteInfo, (res) => {
                if (res.data) {
                    //Update total cost state
                    var totalCost = res.data.totalBalanceOfPerson;
                    personInfo.Account.balance = totalCost;
                    if (transaction.type === "Purchase"/* .localize() */)
                        personInfo.current_month_cost = personInfo.current_month_cost + Math.abs(transaction.price);
                    //Update Table
                    this.props.addNotification(null, "The transaction deleted successfully".localize(), "success");
                    this.setState({ profileUpdateInfo: personInfo });
                    this.fetchTableData(personInfo.id);
                }else{
                    this.props.addNotification(null, "The transaction could not deleted!".localize(), "error");
                }
                this.setState({isDeletingTransaction:false})
            });
        }else{
            this.deleteTransactionForThirdParty(personInfo,transaction)
        }

    };

    deleteTransactionForThirdParty = (personInfo,transaction) => {
        const deleteInfo = { person_id: personInfo.id, transaction_id: transaction.id,third_party:transaction.third_party };
        BackendApi.deleteTransactionForThirdParty(deleteInfo,transaction.third_party, (res) => {
            if (res.data) {
                //Update total cost state
                let totalCost = res.data.totalBalanceOfPerson;
                personInfo.Account.balance = totalCost;
                if (transaction.type === "Purchase"/* .localize() */)
                    personInfo.current_month_cost = personInfo.current_month_cost + Math.abs(transaction.price);
                //Update Table
                this.props.addNotification(null, "The transaction deleted successfully".localize(), "success");
                this.setState({ profileUpdateInfo: personInfo });
                this.fetchTableData(personInfo.id);
            }else{
                this.props.addNotification(null, "The transaction could not deleted!".localize(), "error");
            }
            this.setState({isDeletingTransaction:false})
        });
    }

    closeDeleteModal = () => {
        this.setState({ openDeleteModal: false });
    };

    closeResetModal = () => {
        this.setState({ openResetModal: false });
    };

    handleResetPin = (newPin, isRemovePin) => {
        let personInfo = this.state.profileUpdateInfo;
        let id = personInfo.id
        let hashedPin = isRemovePin ? "not_setted" : generateHashedPin(newPin);
        let info = {
            id: id,
            newPin: hashedPin
        }
        BackendApi.resetPin(info, (res) => {
            if (res.status === 200) {
                personInfo.pin = hashedPin;
                this.props.addNotification(null, "The PIN resetted successfully".localize(), "success");
                this.setState({ openResetModal: false, profileUpdateInfo: personInfo });
            }
            else
                this.props.addNotification(null, "The PIN could not be resetted".localize(), "error");
        })
    };

    handleDeletePerson = () => {
        let id = this.state.profileUpdateInfo.id
        BackendApi.deleteProfile(id, (res) => {
            if (res.status === 200) {
                this.props.addNotification(null, "The person deleted successfully".localize(), "success");
                this.setState({ openDeleteModal: false, showProfileUpdate: false });
                //Update the person list
                // this.getPersonsOfFloor(this.state.floorIndex);
                this.props.backToAllProfiles();
            }
            else
                this.props.addNotification(null, "The person cannot be deleted!".localize(), "error");
        })
    };

    returnColumns = () => {
        return [{
            Header: 'Date'.localize(),
            accessor: 'date',
            sortMethod: (a, b) => {
                return sortByDate(a, b);
            },
            filterMethod: (filter, row) => {
                if (filter.value === "All".localize()) {
                    return true;
                }
                return momentIsBetween(row._original.actualDate, filter.value);
            },
            Filter: ({ filter, onChange }) =>
                <DatePickerModalTrigger onApply={(data) => onChange(data)} applyColor={getColorTheme().selectedTheme} />
        },
        {
            Header: 'Type'.localize(),
            accessor: 'type',
            id: "type",
            filterMethod: (filter, row) => {
                if (filter.value === "all") {
                    return true;
                }
                if (filter.value === "purchase") {
                    console.log(row)
                    return row[filter.id] === "Purchase".localize();
                }
                if (filter.value === "payment") {
                    return row[filter.id] === "Payment".localize();
                }
                return row[filter.id];
            },
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value="all">{"All".localize()}</option>
                    <option value="purchase">{"Purchase".localize()}</option>
                    <option value="payment">{"Payment".localize()}</option>
                </select>
        },
        {
            Header: 'Device'.localize(),
            accessor: 'device_name',
            filterMethod: (filter, row) => {
                if (filter.value === "all") {
                    return true;
                } else if (filter.value === "unregistered") {
                    const index = this.state.deviceList.findIndex((device) => device.name === row[filter.id]);
                    return index === -1;
                }
                return row[filter.id] === filter.value;
            },
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value="all">{"All".localize()}</option>
                    {this.state.deviceList.map((device, key) => {
                        let value = device.name;
                        if (device.name)
                            return <option key={key + 1} value={value}>{value}</option>
                    })}
                    <option value="unregistered">{"Unregistered".localize()}</option>
                </select>
        },
        {
            Header: 'Product'.localize(),
            accessor: 'product',
            id: "product",
            filterMethod: (filter, row) => {
                if (filter.value === "all") {
                    return true;
                }
                else if (filter.value === "custom") {
                    const index = this.state.drinkList.findIndex((drinks) => drinks.name === row[filter.id]);
                    return index === -1;
                }
                return row[filter.id] === filter.value;
            },
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value="all">{"All".localize()}</option>
                    {this.state.drinkList.map((drink, key) => {
                        return <option key={key + 1} value={drink.name}>{drink.name}</option>
                    })}
                    <option value="custom">{"Custom".localize()}</option>
                </select>
        },
        {
            Header: 'Product Price'.localize(),
            accessor: 'product_cost',
            sortMethod: (a, b) => {
                return sortByPrice(a, b);
            }
        },
        {
            Header: 'Quantity'.localize(),
            accessor: 'product_quantity',
            sortMethod: (a, b) => {
                return sortByPrice(a, b);
            }
        },
        {
            Header: 'Price'.localize(),
            accessor: 'price',
            sortMethod: (a, b) => {
                return sortByPrice(a, b);
            }
        },
            {
                Header: 'From'.localize(),
                accessor: 'third_party',
                sortMethod: (a, b) => {
                    return sortByPrice(a, b);
                }
            },
        ];
    }

    getSortedData = () => {
        if (this.reactTable) {
            return this.reactTable.getResolvedState();
        }

        return [];
    };

    renderBackButton = () => {
        return <div className="BackButton" >
            <a onClick={this.props.backToAllProfiles} href="#main" >
                <img alt="" className="backButtonImg" src={getBackImage()} />
            </a>
        </div>
    };

    returnButtonStyle = (type) => {
        return {
            backgroundColor: type === "delete" ? getColorTheme().errorTheme : getColorTheme().selectedTheme,
            fontSize: "18px", maxWidth: '120px', minWidth: '120px', maxHeight: '34px', minHeight: '34px',
        }
    };

    renderStatusText = () => {
        return <p className="ProfileTextValueMail" style={{ float: 'left' }}>{this.getSyncStatusMessage()}</p>
    }

    getSyncStatusMessage = () => {
        let message = "Not Synchronized".localize()
        if(this.state.syncStatus){
            message = "Synchronized successfully".localize()
        }
        return message
    }

    handleSearchInputOnChange = (event) => {
        this.setState({nexudusSearchValue:event.target.value})
    }

    handleChangeDropdown = (event,data) => {
        this.setState({isSecondDisable:false})
        let selectedNexudusUserData = data.options.filter(item => item.value === data.value)[0]
        this.setState(prevState => ({
            selectedNexudusUser: {
                Id : selectedNexudusUserData.value,
                Email: selectedNexudusUserData.text
            }
        }))
    }

    renderNexudusSyncFields () {
        return (
            <div
                style={{display:"grid",gridTemplateColumns:"1fr 1fr",gridGap:"5px"}}
            >
                <SearchInput
                    defaultValue={this.state.nexudusSearchValue}
                    onChange={this.handleSearchInputOnChange}
                    label={"Email"}
                ></SearchInput>
                <CustomDropdown
                    list={this.state.nexudusUsers}
                    onChange={this.handleChangeDropdown}
                    defaultValueTag={"Id"}
                    defaultTextTag={"Email"}
                ></CustomDropdown>
                <GroupButton
                    firstButtonClick={this.searchForNexudus}
                    secondButtonClick={this.syncProfileToNexudusUser}
                    addNotification={this.props.addNotification}
                    firstButton={'Search'.localize()}
                    secondButton={'Synchronize'.localize()}
                    isLoading={this.state.isLoading}
                    isFirstDisable={this.state.isFirstDisable}
                    isSecondDisable={this.state.isSecondDisable}
                    style={{position:"relative",left:"50%",width:"50%"}}
                    firstPopup={"Search account in nexudus with the filled email".localize()}
                    secondPopup={"Syncronize selected account".localize()}
                >
                </GroupButton>
        </div>
        )

    }

    renderNexudusSyncModal = () => {
        return <div>
            <CustomModal
                open={this.state.openNexudusSyncInput}
                render={this.renderNexudusSyncFields()}
                header={"Nexudus Syncronize".localize()}
                descriptionHeader={`${"Status"}: ${this.getSyncStatusMessage()}!`}
                description={
                this.state.profileUpdateInfo.nexudus_id ?
                    <p>{`${"Synchronized email".localize()}: ${this.state.profileUpdateInfo.nexudus_email}`}</p>
                    :null
                }
                firstButton={
                    <Button  style={this.returnButtonStyle()} onClick={this.handleCloseNexudusSyncField}>
                        {<p style={{ fontFamily: 'kelson2', color: 'white', fontSize: 18 }} >{"Back".localize()}</p>}
                    </Button>
                }
            >
            </CustomModal>
        </div>
    }

    checkIsNexudusRequestShouldLogout = (err) => {
        if(err.error && err.error.logout){
            this.props.logout()
        }
    }

    syncProfileToNexudusUser = (successCb,errorCb) => {
        if(this.state.selectedNexudusUser.Id && this.state.selectedNexudusUser.Email){
            BackendApi.syncProfileToNexudusUser({selectedNexudusUser: this.state.selectedNexudusUser,customerEmail:this.state.profileUpdateInfo.email,customerId:this.state.profileUpdateInfo.id},
                (response)=>{
                    this.setState(prevState => ({
                        profileUpdateInfo: {
                            ...prevState.profileUpdateInfo,
                            nexudus_id : response.data.result,
                            nexudus_email : this.state.selectedNexudusUser.Email
                        },
                        syncStatus:true,
                        isSecondDisable:true
                    }))
                    successCb()
                },(err)=>{
                    errorCb()
                    this.setState({isSecondDisable:true})
                })
        }else{
            errorCb()
        }
    }

    searchForNexudus = (successCb,errorCb) => {
        this.setState({isLoading:true,isFirstDisable:true,isSecondDisable:true})
        BackendApi.getNexudusUsers(this.state.nexudusSearchValue,(response)=>{
            successCb()
            this.setState({nexudusUsers:response.data.result,isLoading:false,isFirstDisable:false})
        },(err)=>{
            errorCb()
            this.checkIsNexudusRequestShouldLogout(err)
            this.setState({isLoading:false,isFirstDisable:false})
        })
    }

    generateNexudusSyncProfileFields = () => {
        return (
            <div className="ProfileUpdateProperty">
                <div className="ProfileTextContainer" >
                    <p className="ProfileTextValueMail" style={{ float: 'left' }}>{"Nexudus Sync Status".localize()}</p>
                </div>
                <div  className="ProfileValueContainer">
                    <div className="ProfileFirstLinesMailContainer">
                        <div style={{ width: '70%' }}>
                            {/* Open Text Input */}
                            {
                                this.state.openNexudusSyncInput ?
                                    this.renderNexudusSyncModal()
                                    :this.renderStatusText()
                            }
                        </div>
                        <div style={{ marginTop: 3, marginLeft: 10 }} >
                            {
                                !this.state.openNexudusSyncInput ?
                                    <Icon
                                        onClick={(()=>{this.setState({openNexudusSyncInput:true})})}
                                        size='large'
                                        name="edit" /> :
                                    <div>
                                        <div style={{ marginTop: 5, float: 'left', marginLeft: 10, display: 'flex', flexDirection: 'row' }} >
                                            <Icon
                                                onClick={this.handleCloseNexudusSyncField}
                                                size='large'
                                                name="close" />
                                        </div>
                                    </div>
                            }
                        </div>
                        <div style={{ width: '60%', justifyContent: 'center', alignItems: 'center', }} />
                    </div>
                    <div style={{width:"70%"}}></div>
                </div>
            </div>
        )
    }

    handleCloseNexudusSyncField = () => {
        this.setState({openNexudusSyncInput:false,isSecondDisable:true,nexudusSearchValue:this.state.profileUpdateInfo.nexudus_email || this.state.profileUpdateInfo.email})
    }

    renderNexudusTransferModal = () => {
        return <div>
            <CustomModal
                open={this.state.openTransferModal}
                render={null}
                header={"Transfer To Nexudus".localize()}
                descriptionHeader={`Are you sure ?`.localize()}
                description={
                    <p>{`${"Your current balance is including taxes".localize()}. ${"You are going to transfer your balance to Nexudus".localize()}. ${"Please note that prices are sent to Nexudus without taxes".localize()}.`}</p>
                }
                firstButton={
                    <Button  style={this.returnButtonStyle()} onClick={this.transferBalanceToNexudus} disabled={this.state.isTransfering}>
                        {<p style={{ fontFamily: 'kelson2', color: 'white', fontSize: 18 }} >{"Transfer".localize()}</p>}
                    </Button>
                }
                secondButton={
                    <Button  style={this.returnButtonStyle("delete")} onClick={this.handleCloseNexudusTransferModal}>
                        {<p style={{ fontFamily: 'kelson2', color: 'white', fontSize: 18 }} >{"Back".localize()}</p>}
                    </Button>
                }
            >
            </CustomModal>
        </div>
    }

    handleCloseNexudusTransferModal = () => {
        this.setState({openTransferModal:false})
    }

    generateNexudusTransferFields = () => {
        return(
            <div className="ProfileUpdateDelete">
                <div className="ProfileTextContainer" >{
                    <p className="ThematicGeneralText">{"Transfer To Nexudus".localize()}</p>
                }</div>
                <div  className="ProfileDeleteContainer">
                    <div className="SwitchContainer">
                        {
                            this.state.openTransferModal ? this.renderNexudusTransferModal():
                            <Button
                                style={this.returnButtonStyle("add")}
                                variant="contained"
                                onClick={this.handleTransferButton}
                            >
                                <p style={{ fontFamily: 'kelson2', color: 'white', fontSize: 18 }} >{"Transfer".localize()}</p>
                            </Button>

                        }
                    </div>
                </div>
            </div>
        )
    }

    handleTransferButton = () => {
        if(this.state.profileUpdateInfo.Account.balance >= 0){
            return  this.props.addNotification(null, "Your balance must have a negative value!".localize(), "error");
        }
        this.setState({openTransferModal:true})
    }

    transferBalanceToNexudus = () => {
        this.setState({isTransfering:true})
        BackendApi.transferBalanceToNexudus({customerEmail : this.state.profileUpdateInfo.email,customerId:this.state.profileUpdateInfo.id},(response)=>{
            let { balance,customer_id } = response.data.result;
            this.setState(prevState => ({
                profileUpdateInfo: {
                    ...prevState.profileUpdateInfo,
                    Account:{
                        ...prevState.profileUpdateInfo.Account,
                        balance:balance
                    }
                },
                openTransferModal:false,
                isTransfering:false
            }))
            this.props.addNotification(null, "The transfer successfully completed!".localize(), "success");
            this.fetchTableData(customer_id);
            },(error)=>{
            this.props.addNotification(null, "The transfer failed!".localize(), "error");
            this.checkIsNexudusRequestShouldLogout(error)
            this.setState({openTransferModal:false,isTransfering:false})
        })
    }

    render() {
        const dateNames = this.props.dateNames;
        const noPaymentText = "No Payment".localize();
        const recordButtonText = "Record".localize();

        return (
            <div className="ProfileUpdate" >
                <div className="ProfileUpdateContainer" >
                    {this.renderBackButton()}
                    <CustomPersonInput
                        openNameAndSurnameTextInput={this.state.openNameAndSurnameTextInput}
                        handleNameTextInput={this.handleNameTextInput}
                        nameTextInput={this.state.nameTextInput}
                        openNameAndSurnameInput={this.openNameAndSurnameInput}
                        changePersonNameAndSurname={this.changePersonNameAndSurname}
                        closeNameAndSurnameInput={() => this.setState({ openNameAndSurnameTextInput: false })}
                        profileUpdateInfo={this.state.profileUpdateInfo}
                    />
                    {
                        isHaveNexudus() ? this.generateNexudusSyncProfileFields() : null
                    }
                    <div className="ProfileUpdateBlankProperty" ></div>
                    {this.state.profileUpdateInfo.type !== ROOM_KEY ?
                        <CustomMailInput
                            openMailTextInput={this.state.openMailTextInput}
                            handleEmailTextInput={this.handleEmailTextInput}
                            emailTextInput={this.state.emailTextInput}
                            handleChangeEmail={this.handleChangeEmail}
                            changePersonEmail={this.changePersonEmail}
                            closeEmailInput={() => this.setState({ openMailTextInput: false })}
                            profileUpdateInfo={this.state.profileUpdateInfo}
                        /> : null}
                    {this.state.profileUpdateInfo.type !== NOTE_KEY ?
                        <CustomNoteInput
                            openNoteTextInput={this.state.openNoteTextInput}
                            handleNoteTextInput={this.handleNoteTextInput}
                            noteTextInput={this.state.noteTextInput}
                            handleChangeNote={this.handleChangeNote}
                            changePersonNote={this.changePersonNote}
                            closeNoteInput={() => this.setState({ openNoteTextInput: false })}
                            profileUpdateInfo={this.state.profileUpdateInfo}
                        /> : null}
                    {/* For Current Balance */}
                    <div className="ProfileUpdateProperty" >
                        <div className="ProfileTextContainer">
                            <p className="SelectedProfileInfoText">{"Current Balance".localize()}</p>
                        </div>
                        <div className="ProfileValueContainer" >
                            <div className="ProfileFirstLinesContainer" >
                                <div style={{ width: '60%' }}>
                                    <p className={"ProfileTextValue"}>
                                        {
                                            isNotNullAndUndefined(this.state.profileUpdateInfo.Account.balance) ?
                                                createPriceTextFromValue(this.state.profileUpdateInfo.Account.balance) : noPaymentText
                                        }
                                    </p>
                                </div>
                                <div style={{ width: '60%' }}>
                                </div>
                            </div>
                            <div style={{ width: '70%' }} >

                            </div>
                        </div>
                    </div>
                    {/* For Current Month */}
                    <div className="ProfileUpdateProperty" >

                        <div className="ProfileTextContainer">
                            <p className="SelectedProfileInfoText">{"Current Month".localize()} ({dateNames[MOMENT_VARIABLES.NAME.CURRENT_MONTH]})</p>
                        </div>
                        <div className="ProfileValueContainer">
                            <div className="ProfileFirstLinesContainer" >
                                <div style={{ width: '60%' }}>
                                    <p className={"ProfileTextValue"}>
                                        {
                                            isNotNullAndUndefined(this.state.profileUpdateInfo.current_month_cost) ?
                                                createPriceTextFromValue(this.state.profileUpdateInfo.current_month_cost) : noPaymentText
                                        }
                                    </p>
                                </div>
                                <div style={{ width: '60%' }}>
                                </div>
                            </div>
                            <div style={{ width: '70%' }} >

                            </div>
                        </div>
                    </div>
                    {/* For Previous Month */}
                    <div className="ProfileUpdateProperty" >
                        <div className="ProfileTextContainer">
                            <p className="SelectedProfileInfoText">{"Previous Month".localize()} ({dateNames[MOMENT_VARIABLES.NAME.PREV_MONTH]}) </p>

                        </div>
                        <div className="ProfileValueContainer" >
                            <div className="ProfileFirstLinesContainer" >
                                <div style={{ width: '60%' }}>
                                    <p className={"ProfileTextValue"}>
                                        {
                                            isNotNullAndUndefined(this.state.profileUpdateInfo.prev_month_cost) ?
                                                createPriceTextFromValue(this.state.profileUpdateInfo.prev_month_cost) : noPaymentText
                                        }
                                    </p>
                                </div>
                                <div style={{ width: '60%' }}>
                                </div>
                            </div>
                            <div style={{ width: '70%' }} >

                            </div>
                        </div>
                    </div>

                    {/* For New Payment */}
                    <div className="ProfileUpdateNewPayment" >
                        <div className="ProfileTextContainer">
                            <p className="ThematicGeneralText">{"New Payment".localize()}</p>
                        </div>
                        <div className="ProfileUpdatePaymentValueContainer" >
                            <Input
                                style={{ width: '30%', height: '100%' }}
                                error={!this.state.activePaymentInput}
                                labelPosition='right' type='text' placeholder=''
                                onChange={(event, data) => this.handleNewPaymentInput(event, data)} >
                                <input />
                                <Label style={{ alignSelf: 'center', width: '15%', height: '100%' }} basic>{' \u20AC'}</Label>
                            </Input>
                            <div style={{ marginLeft: '9%', height: '100%' }} >
                                <button
                                    className={"RecordButton"}
                                    variant="contained"
                                    onClick={() => this.handleNewPayment()} >
                                    <p className={"RecordButtonText"}>{recordButtonText}</p>
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* For New Purchase */}
                    <div className="ProfileUpdateNewPurchase" >
                        <div className="ProfileTextContainer">
                            <p className="ThematicGeneralText">{"New Purchase".localize()}</p>
                        </div>
                        <div className="ProfileUpdatePurchaseValueContainer" >
                            <Input
                                style={{ width: '30%', height: '48%' }}
                                error={!this.state.activePurchaseInput}
                                labelPosition='right' type='text' placeholder=''
                                onChange={(event, data) => this.handleNewPurchaseInput(event, data)} >
                                <input />
                                <Label style={{ alignSelf: 'center', width: '15%', height: '100%' }} basic>{' \u20AC'}</Label>
                            </Input>
                            <div style={{ width: '40%', height: '48%', }} >
                                <Input
                                    icon="cocktail"
                                    onChange={(event, data) => this.handlePurchaseTextInput(event, data)}
                                    placeholder=""
                                    style={{ width: '100%', height: '100%' }} />
                            </div>
                            <div style={{ marginLeft: '4%', height: '48%' }} >
                                <button
                                    className={"RecordButton"}
                                    variant="contained"
                                    onClick={() => this.handlePurchase()} >
                                    <p className={"RecordButtonText"}>{recordButtonText}</p>
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* For Change the Floor of Profile */}
                    <div className="ProfileUpdateNewPurchase" >
                        <div className="ProfileTextContainer" >
                            <p className="ThematicGeneralText">{"Change Floor".localize()}</p>
                        </div>
                        <div className="ProfileUpdateChangeFloorContainer" >
                            <Select
                                value={this.state.profileUpdateInfo.floor_id}
                                onChange={(event) => this.handleChangeFloorOfPerson(event)}
                                inputProps={{
                                    name: 'age',
                                    id: 'age-simple',
                                }}
                                style={{ width: '50%', border: getBorderWithTheme("1px", "solid"), height: '50%', }}
                            >
                                {
                                    this.props.floorListDropdown.map((data, index) => {
                                        return (
                                            <MenuItem key={index} value={data.id}>{data.name}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                    </div>
                    {/* For Active*/}
                    <div className="ProfileUpdateActive">
                        <div className="ProfileTextContainer" >
                            <p className="ThematicGeneralText">{"Active".localize()}</p>
                        </div>
                        <div className="ProfileUpdateActiveContainer" >
                            <div className="SwitchContainer">
                                <Switch
                                    color="primary"
                                    checked={this.state.profileUpdateInfo.Account.activated}
                                    onChange={(e, c) => this.handleActiveProfileChange(e, c, 'checkedA')}
                                    value={this.state.profileUpdateInfo.Account.activated}
                                />
                            </div>
                        </div>
                    </div>
                    {/* For Invoice*/}
                    <div className="ProfileUpdateActive">
                        <div className="ProfileTextContainer" >
                            <p className="ThematicGeneralText">{"Invoice".localize()}</p>
                        </div>
                        <div className="ProfileUpdateActiveContainer" >
                            <div className="SwitchContainer">
                                <Switch
                                    color="primary"
                                    checked={this.state.profileUpdateInfo.Account.warning_limit}
                                    onChange={(e, c) => this.handleInvoiceProfileChange(e, c, 'checkedA')}
                                    value={this.state.profileUpdateInfo.Account.warning_limit}
                                />
                            </div>
                        </div>
                    </div>
                    {/* For Delete Profile */}
                    <div className="ProfileUpdateDelete">
                        <div className="ProfileTextContainer" >
                            <p className="ThematicGeneralText">{"Delete Profile".localize()}</p>
                        </div>
                        <div className="ProfileDeleteContainer">
                            <div className="SwitchContainer">
                                <Button
                                    style={this.returnButtonStyle("delete")}
                                    variant="contained"
                                    onClick={() => this.setState({ openDeleteModal: true })}
                                >
                                    <p style={{ fontFamily: 'kelson2', color: 'white', fontSize: 18 }} >{"Delete".localize()}</p>
                                </Button>
                            </div>
                        </div>
                    </div>
                    {/* For Reset PIN */}
                    <div className="ProfileUpdateDelete">
                        <div className="ProfileTextContainer" >
                            <p className="ThematicGeneralText">{"Reset PIN".localize()}</p>
                        </div>
                        <div className="ProfileDeleteContainer">
                            <div className="SwitchContainer">
                                <Button
                                    style={this.returnButtonStyle("add")}
                                    variant="contained"
                                    onClick={() => this.setState({ openResetModal: true })}
                                >
                                    <p style={{ fontFamily: 'kelson2', color: 'white', fontSize: 18 }} >
                                        {
                                            this.state.profileUpdateInfo.pin === "not_setted" ? "Add".localize() : "Reset".localize()
                                        }</p>
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="ProfileUpdateDelete">
                        <div className="ProfileTextContainer" >
                            <p className="ThematicGeneralText">{"Transaction History".localize()}</p>
                        </div>
                        <div className="ProfileDeleteContainer">
                            <div className="SwitchContainer">
                                <CSVExporterButton
                                    dataToDownload={this.state.dataToDownload}
                                    columns={this.returnColumns()} getSortedData={this.getSortedData} />
                            </div>
                        </div>
                    </div>
                    {
                        isHaveNexudus() && this.state.profileUpdateInfo.nexudus_id ? this.generateNexudusTransferFields() : null
                    }
                    {/* For Transaction history Text */}
                    <div className="ProfileTransactionHistory" >
                        <CustomReactTable
                            onRef={(ref) => this.reactTable = ref}
                            data={this.state.orderTableData}
                            subComponentClick={this.deleteTransaction}
                            subComponentText={"Delete".localize()}
                            columns={this.returnColumns()}
                            subComponentLoading={this.state.isDeletingTransaction}
                        />
                    </div>
                    {
                        this.state.openDeleteModal ?
                            <DeleteModal
                                handleDelete={this.handleDeletePerson}
                                closeDelete={this.closeDeleteModal} /> : null
                    }
                    {
                        this.state.openResetModal ?
                            <ResetModal
                                handleResetPin={this.handleResetPin}
                                closeResetModal={this.closeResetModal}
                                addNotification={this.props.addNotification} /> : null
                    }

                </div>
            </div>
        )
    }
}