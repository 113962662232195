import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { Input, Label } from 'semantic-ui-react';
import BackendApi from '../api/app2backend';
import '../Main.css';
import {
    convertDate, generateCurrentAndPrevMonthName, generateCurrentAndPrevMonthValue,
    generateSpendendosenPaymentInfo, matchPattern
} from '../Helpers/ListHelpers';
import { getColorTheme } from '../config/flavor/mainFlavor';
import CustomReactTable from '../Components/CustomReactTable';
import CSVExporterButton from '../Components/CSVExporterButton';
import { momentIsBetween } from '../Helpers/TimeHelper';
import DatePickerModalTrigger from '../Components/DatePickerModalTrigger';
import { SPENDENDOSEN_INFOS, RENDERED_CONTENT_TYPE } from '../Helpers/const';
import { sortByDate, sortByPrice, isNotNullAndUndefined, createPriceTextFromValue, createPriceValueFromText } from '../Helpers/ContentHelpers';

class Spendendosen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newPaymentValue: -1,
            activePaymentInput: false,
            spendendosenInfo: [],
            orderTableData: [],
            propList: "none",
            buttonActive: 0,
            dataToDownload: [],
        }
    }

    componentWillUnmount() {
        if (this.props.onRef)
            this.props.onRef(undefined);
    }

    componentDidMount() {
        if (this.props.onRef)
            this.props.onRef(this);
    }

    componentWillMount() {
        let tokenToSend;
        let localStorageInfo = localStorage.getItem('userInfo');
        localStorageInfo = JSON.parse(localStorageInfo);
        if (localStorageInfo && localStorageInfo.isAuthSuccess) {
            tokenToSend = localStorageInfo.token
            this.setState({ isUnauth: false });
        }
        else
            tokenToSend = this.props.token
        let personInfo = {
            person_id: 1,
            person_name: "Spendendosen",
            token: tokenToSend,
            mobile: "web"
        }
        this.fetchTableData(personInfo);
        this.updateInfos(personInfo);
    }

    fetchTableData = (personInfo) => {
        BackendApi.getSpendendosenTransaction(personInfo, (res, err) => {
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        this.setState({ isUnauth: true });
                        alert("You are unauthorized. Please login firstly!".localize());
                    }
                    else {
                        this.props.addNotification("The Spendendosen payment cannot be added!".localize(), "error");
                    }
                }
            }
            else {
                var orders = [];
                for (let i = 0; i < res.data.length; i++) {
                    let order = {
                        id: res.data[i].id,
                        date: convertDate(res.data[i].update_date),
                        actualDate: res.data[i].update_date,
                        price: createPriceTextFromValue(res.data[i].amount)
                    }
                    orders.push(order);
                }
                this.setState({ orderTableData: orders, });
            }
        });
    };

    updateInfos = (personInfo) => {
        let sendInfo = { ...generateCurrentAndPrevMonthValue(), ...personInfo, token: this.props.token }
        BackendApi.getSpendendosen(sendInfo, (res) => {
            let spen = res.data;
            this.setState({ spendendosenInfo: spen, personInfo: personInfo })
        });
    };

    handleNewPaymentInput = (event, data) => {
        if (matchPattern(data.value))
            this.setState({ activePaymentInput: true })
        else
            this.setState({ activePaymentInput: false })

        let actualValue = createPriceValueFromText(data.value);
        this.setState({ newPaymentValue: actualValue });
    };

    handleNewPayment = () => {
        var paymentValue = this.state.newPaymentValue;
        var personInfo = this.state.spendendosenInfo;
        if (this.state.activePaymentInput) {
            if (paymentValue > 0) {
                var paymentInfo = generateSpendendosenPaymentInfo(personInfo, paymentValue);
                BackendApi.createDonation(paymentInfo, (res) => {
                    if (res.data) {
                        this.props.addNotification(null, "The Spendendosen payment is added successfully".localize(), "success");
                        //Update Transaction History State
                        this.updateInfos(this.state.personInfo);
                        this.fetchTableData(this.state.personInfo);
                    }
                    else {
                        this.props.addNotification(null, "The Spendendosen payment cannot be added!".localize(), "error");
                    }
                });
            }
            else if (paymentValue === 0)
                this.props.addNotification(null, "Value cannot be zero!".localize(), "error");
            else
                this.props.addNotification(null, "Value cannot be negative integer!".localize(), "error");
        }
        else {
            this.props.addNotification(null, "Please enter a valid value!".localize(), "error");
        }
    }

    deleteTransaction = (row) => {
        let transaction = row.original;
        let deleteInfo = { donation_id: transaction.id };
        BackendApi.deleteSpendendosenTransaction(deleteInfo, (res) => {
            if (res.data) {
                //Notification
                this.props.addNotification(null, "The Spendendosen payment is deleted successfully".localize(), "success");
                //Update Table
                this.updateInfos(this.state.personInfo);
                this.fetchTableData(this.state.personInfo);
            }
        })
    }

    getSortedData = () => {
        if (this.reactTable) {
            return this.reactTable.getResolvedState();
        }

        return [];
    };

    getColumnDescription = () => {
        return [{
            Header: 'Date'.localize(),
            accessor: 'date',
            sortMethod: (a, b) => {
                return sortByDate(a, b);
            },
            filterMethod: (filter, row) => {
                if (filter.value === "All".localize()) {
                    return true;
                }
                return momentIsBetween(row._original.actualDate, filter.value);
            },
            Filter: ({ filter, onChange }) =>
                <DatePickerModalTrigger onApply={(data) => onChange(data)} applyColor={getColorTheme().selectedTheme} />
        },
        {
            Header: 'Price'.localize(),
            accessor: 'price',
            sortMethod: (a, b) => {
                return sortByPrice(a, b);
            }
        }];
    };

    prepareInfo = (value) => {
        const noneText = "None".localize();
        return isNotNullAndUndefined(value) ? createPriceTextFromValue(value) : noneText;
    }

    renderContentType = (spendInfo, key, child, ind) => {
        const dateNames = generateCurrentAndPrevMonthName();
        const column = this.getColumnDescription();
        let uuid = require('uuid');
        switch (child.valueType) {
            case RENDERED_CONTENT_TYPE.INPUT_WITH_BUTTON:
                return (
                    <div key={`${spendInfo.text}_${key}_${child.text}_${ind}`} className="ProfileUpdateNewPayment" >
                        <div className="ProfileTextContainer">
                            <p className="ThematicGeneralText">{child.text.localize()}</p>
                        </div>
                        <div className="ProfileUpdatePaymentValueContainer" >
                            <Input
                                style={{ width: '30%', height: '100%' }}
                                error={!this.state.activePaymentInput}
                                labelPosition='right' type='text' placeholder=''
                                onChange={(event, data) => this.handleNewPaymentInput(event, data)} >
                                <input />
                                <Label style={{ alignSelf: 'center', width: '14%', height: '100%' }} basic>{' \u20AC'}</Label>
                            </Input>
                            <div style={{ marginLeft: '9%' }} >
                                <button
                                    className={"RecordButton"}
                                    // disabled={!this.state.activePaymentInput}
                                    variant="contained"
                                    onClick={() => this.handleNewPayment()} >
                                    <p className={"RecordButtonText"}>{"Record".localize()}</p>
                                </button>
                            </div>
                        </div>
                    </div>
                );
            case RENDERED_CONTENT_TYPE.BUTTON:
                return (
                    <div key={`${spendInfo.text}_${key}_${child.text}_${ind}`} className="ProfileUpdateDelete">
                        <div className="ProfileTextContainer" >
                            <p className="ThematicGeneralText">{child.text.localize()}</p>
                        </div>
                        <div className="ProfileDeleteContainer">
                            <div className="SwitchContainer">
                                <CSVExporterButton
                                    dataToDownload={this.state.dataToDownload}
                                    columns={column} getSortedData={this.getSortedData} />
                            </div>
                        </div>
                    </div>
                );
            case RENDERED_CONTENT_TYPE.TABLE:
                return (
                    <div key={`${spendInfo.text}_${key}_${child.text}_${ind}`} className="ProfileTransactionHistory" >
                        <CustomReactTable
                            onRef={(ref) => this.reactTable = ref}
                            data={this.state.orderTableData}
                            key={uuid.v1()}
                            subComponentClick={this.deleteTransaction}
                            subComponentText={"Delete".localize()}
                            columns={column} />
                    </div>
                );
            default:
                return (
                    <div key={`${spendInfo.text}_${key}_${child.text}_${ind}`} className="ProfileUpdateProperty">
                        <div className="ProfileTextContainer">
                            <p className="ThematicGeneralText">
                                {`${child.text.localize()} ${dateNames[child.key] ? dateNames[child.key] : ""}`}
                            </p>
                        </div>
                        <div className="ProfileValueContainer" >
                            <div className="ProfileFirstLinesContainer" >
                                <div style={{ width: '30%' }}>
                                    <p className={"ProfileTextValue"}>
                                        {this.prepareInfo(this.state.spendendosenInfo[child.value])}
                                    </p>
                                </div>
                                <div style={{ width: '70%' }} />
                            </div>
                            <div style={{ width: '70%' }} />
                        </div>
                    </div>
                );
        }
    };

    render() {
        return (
            this.state.isUnauth ? <Redirect to={{
                pathname: '/',
                state: { token: this.state.token }
            }} /> :
                <div>
                    <div className="ProfileUpdate" >
                        <div className="ProfileUpdateContainer" >
                            {
                                SPENDENDOSEN_INFOS.map((spendInfo, key) => {
                                    return (
                                        <div key={`${spendInfo.text}_${key}`} style={{ display: 'contents' }}>
                                            <div className="ProfileUpdateNameProperty">
                                                <p className="SpendendosenHeader">{spendInfo.text.localize()}</p>
                                            </div>
                                            <div className="ProfileUpdateBlankProperty" />
                                            {
                                                spendInfo.children.map((child, ind) => {
                                                    return this.renderContentType(spendInfo, key, child, ind);
                                                })
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
        )
    }
}

export default Spendendosen;
