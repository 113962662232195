import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import CustomReactTable from './CustomReactTable';
import { sortByPrice, sortByDate, createOrderOfPersonData } from '../Helpers/ContentHelpers';
import DatePickerModalTrigger from './DatePickerModalTrigger';
import { getColorTheme } from '../config/flavor/mainFlavor';
import { momentIsBetween } from '../Helpers/TimeHelper';
import BackendApi from '../api/app2backend';

export default class DeviceTransactionModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orderTableData: [],
            personList: [],
            floorList: [],
            drinkList: []
        };
    }

    componentDidMount() {
        this.getRequiredInfos();
    }

    getRequiredInfos = () => {
        const info = this.props.tokenInfo;
        const device = this.props.device;

        BackendApi.getOrdersWithDeviceId({ device_id: device.id }, (res, err) => {
            if (err) {
                this.generateGenericError(err);
            }
            else if (res) {
                const tableData = createOrderOfPersonData(res.data);
                this.setState({ orderTableData: tableData });
            }
        });
        // all drinks
        BackendApi.getDrinks(info, (res, err) => {
            if (err) {
                this.generateGenericError(err);
            }
            else if (res) {
                this.setState({ drinkList: res.data });
            }
        });
        // all persons
        BackendApi.getProfilesOfFloor('all', (res, err) => {
            if (err) {
                this.generateGenericError(err);
            }
            else if (res) {
                this.setState({ personList: res.data });
            }
        });
        // all floors
        BackendApi.getFloors(info, (res, err) => {
            if (err) {
                if (err.response.status === 401) {
                    this.setState({ isUnauth: true, isLoading: false });
                    alert("You are unauthorized. Please login firstly!".localize());
                }
                else {
                    this.props.addNotification(null, "The required information could not be loaded!".localize(), "error");
                }
            }
            else if (res) {
                let floors = res.data;
                this.setState({ floorList: floors })
            }
        });
    };

    generateGenericError = (err) => {
        //todo need more generic in BackendAPI
        if (err.response) {
            if (err.response.status === 401) {
                alert("You are unauthorized. Please login firstly!".localize());
            }
            else {
                this.props.addNotification(null, "Something went wrong!".localize(), "error");
            }
        }
    };

    generateColumn = () => {
        // TODO: refactor all columns for CustomReactTable.
        return [{
            Header: 'Date'.localize(),
            accessor: 'formattedDate',
            sortMethod: (a, b) => {
                return sortByDate(a, b);
            },
            filterMethod: (filter, row) => {
                if (filter.value === "All".localize()) {
                    return true;
                }
                return momentIsBetween(row._original.date, filter.value);
            },
            Filter: ({ filter, onChange }) =>
                <DatePickerModalTrigger onApply={(data) => onChange(data)} applyColor={getColorTheme().selectedTheme} />
        },
        {
            Header: 'Profile'.localize(),
            accessor: 'person_info',
            filterMethod: (filter, row) => {
                if (filter.value === "all") {
                    return true;
                }
                else if (filter.value === "deleted") {
                    const index = this.state.personList.findIndex((person) => person.name === row[filter.id]);
                    return index === -1;
                }
                return row[filter.id] === filter.value;
            },
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value="all">{"All".localize()}</option>
                    {this.state.personList.map((person, key) => {
                        let value = person.name;
                        return <option key={key + 1} value={value}>{value}</option>
                    })}
                    <option value="deleted">{"Deleted Profiles".localize()}</option>
                </select>
        },
        {
            Header: 'Floor'.localize(),
            accessor: 'floor_info',
            filterMethod: (filter, row) => {
                if (filter.value === "all") {
                    return true;
                }
                return row[filter.id] === filter.value;
            },
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value="all">{"All".localize()}</option>
                    {this.state.floorList.map((floor, key) => {
                        return <option key={key + 1} value={floor.name}>{floor.name}</option>
                    })}
                </select>
        },
        {
            Header: 'Type'.localize(),
            accessor: 'type',
            id: "type",
            filterMethod: (filter, row) => {
                if (filter.value === "all") {
                    return true;
                }
                if (filter.value === "purchase") {
                    return row[filter.id] === "Purchase".localize();
                }
                if (filter.value === "payment") {
                    return row[filter.id] === "Payment".localize();
                }
                return row[filter.id];
            },
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value="all">{"All".localize()}</option>
                    <option value="purchase">{"Purchase".localize()}</option>
                    <option value="payment">{"Payment".localize()}</option>
                </select>
        },
        {
            Header: 'Product'.localize(),
            accessor: 'product',
            id: "product",
            filterMethod: (filter, row) => {
                if (filter.value === "all") {
                    return true;
                }
                else if (filter.value === "custom") {
                    const index = this.state.drinkList.findIndex((drinks) => drinks.name === row[filter.id]);
                    return index === -1;
                }
                return row[filter.id] === filter.value;
            },
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value="all">{"All".localize()}</option>
                    {this.state.drinkList.map((drink, key) => {
                        return <option key={key + 1} value={drink.name}>{drink.name}</option>
                    })}
                    <option value="custom">{"Custom".localize()}</option>
                </select>
        },
        {
            Header: 'Product Price'.localize(),
            accessor: 'product_cost',
            sortMethod: (a, b) => {
                return sortByPrice(a, b);
            }
        },
        {
            Header: 'Quantity'.localize(),
            accessor: 'product_quantity',
        },
        {
            Header: 'Price'.localize(),
            accessor: 'price',
            sortMethod: (a, b) => {
                return sortByPrice(a, b);
            }
        }];
    };

    handleClose = () => {
        this.props.onClose();
    };

    render() {
        const header = this.props.device.name ? this.props.device.name : this.props.device.id;
        return <Modal open={this.props.open} closeOnDimmerClick={true} closeOnDocumentClick={true} style={{width: '95%'}}>
            <Modal.Header style={{ textAlign: 'center' }} >{header}</Modal.Header>
            <Modal.Content scrolling>
                <CustomReactTable
                    onRef={(ref) => this.reactTable = ref}
                    data={this.state.orderTableData}
                    defaultPageSize={10}
                    columns={this.generateColumn()} />
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={this.handleClose}>
                    {"Close".localize()}
                </Button>
            </Modal.Actions>
        </Modal>
    }
}