import moment from 'moment';
import 'moment/locale/de';
import { getColorTheme, getHeaderLogoStyle, getHeaderProfileStyle } from '../config/flavor/mainFlavor';
import { convertDate } from './ListHelpers';
import { TRANSACTION_TYPE, ACCOUNT_INFO } from './const';

export function createDrinksTransactionData(dataArr) {
    const orders = [];
    const persons = [];
    for (let i = 0; i < dataArr.length; i++) {
        let data = dataArr[i];
        let order = {
            id: data.id,
            date: data.update_date,
            formattedDate: convertDate(data.update_date),
            person_id: data.customer_id,
            person_info: data.Customer.name,
            product: data.OrdersInfo.drink_name,
            product_cost: createPriceTextFromValue(data.OrdersInfo.drink_cost),
            product_quantity: data.OrdersInfo.quantity,
            price: createPriceTextFromValue(data.amount),
            device_name: data.Device ? data.Device.name : "",
        }
        orders.push(order);
        if (persons.indexOf(order.person_info) === -1) {
            persons.push(order.person_info)
        }
    }
    return { orders, persons };
}

export function createProfileTransactionData(dataArr) {
    let orders = [];
    for (let i = 0; i < dataArr.length; i++) {
        let data = dataArr[i];
        let order = {
            id: data.id,
            date: convertDate(data.update_date),
            actualDate: data.update_date,
            type: data.OrdersInfo ? "Purchase".localize() : "Payment".localize(),
            product: returnValidDataForTable(data.OrdersInfo, 'drink_name'),
            product_cost: retunValidCostForTable(data.OrdersInfo, 'drink_cost'),
            product_quantity: returnValidDataForTable(data.OrdersInfo, 'quantity'),
            price: createPriceTextFromValue(data.amount),
            device_name: data.Device ? data.Device.name : "",
            third_party : returnValidDataForTable(data.PaymentsInfo,'third_party')
        }
        orders.push(order);
    }
    return orders;
};

export function createThemeObjectWithConfig() {
    return {
        "theme-color": getColorTheme().selectedTheme,
        "default-text-color": getColorTheme().darkBackgroundTheme,
        "main-background-color": getColorTheme().mainBackgroundTheme,
        "error-color": getColorTheme().errorTheme,
        "header-logo-height": getHeaderLogoStyle().height,
        "header-logo-width": getHeaderLogoStyle().width,
        "header-logo-margin-left": getHeaderLogoStyle().marginLeft,
        "profile-hover-color": getHeaderProfileStyle().hoverColor
    }
}

export function createOrderOfPersonData(dataArr) {
    const orders = [];
    for (let i = 0; i < dataArr.length; i++) {
        let data = dataArr[i];
        let isOrder = data.OrdersInfo !== null
        let order = {
            id: data.id,
            date: data.update_date,
            formattedDate: convertDate(data.update_date),
            person_id: data.customer_id,
            person_info: data.Customer.name,
            floor_info: data.Floor.name,
            product: isOrder ? data.OrdersInfo.drink_name : "",
            product_cost: isOrder ? createPriceTextFromValue(data.OrdersInfo.drink_cost) : "",
            product_quantity: isOrder ? data.OrdersInfo.quantity : "",
            price: createPriceTextFromValue(data.amount),
            type: isOrder ? TRANSACTION_TYPE.PURCHASE.localize() : TRANSACTION_TYPE.PAYMENT.localize(),
            active: data.Customer.Account.activated ? ACCOUNT_INFO.ACTIVE.localize() : ACCOUNT_INFO.INACTIVE.localize(),
            profile_type: data.Customer.type,
            invoice: data.Customer.Account.warning_limit ? ACCOUNT_INFO.INVOICE.localize() : ACCOUNT_INFO.NON_INVOICE.localize(),
            device_name: data.Device ? data.Device.name : "",
        }
        orders.push(order);
    }
    return orders;
}

export function createPriceTextFromValue(value) {
    return parseFloat(value).toFixed(2).toString().replace(".", ",") + ' \u20AC';
}

export function createPriceValueFromText(text) {
    if (text && text.includes(","))
        return parseFloat(text.replace(",", "."));
    else return parseFloat(text);

}

export function sortByPrice(price1, price2) {
    let price1_value = createPriceValueFromText(price1);
    let price2_value = createPriceValueFromText(price2);

    if (price1_value < price2_value)
        return 1;
    else if (price1_value > price2_value)
        return -1;
    else
        return 0;
}

export function sortByDate(date1, date2) {
    let date1_value = moment(date1, "DD/MM/YYYY").valueOf();
    let date2_value = moment(date2, "DD/MM/YYYY").valueOf();
    if (date1_value < date2_value)
        return 1;
    else if (date1_value > date2_value)
        return -1;
    else
        return 0;
}

export function returnValidDataForTable(data, key) {
    return data !== null && data[key] ? data[key] : "";
}

export function retunValidCostForTable(data, key) {
    return data !== null && data[key] ? createPriceTextFromValue(data[key]) : "";
}

export function calculateTotalCostForAnalysis(dataArr) {
    if (dataArr.length !== 0) {
        let systemBalance = 0;
        dataArr.map((data) => {
            systemBalance += createPriceValueFromText(data.price);
        });
        return createPriceTextFromValue(systemBalance);
    }
    return createPriceTextFromValue(0);
}
export function stringLenght(text)
{   
    if(text === null)
    return 0;
    else
    return text.length;
}

export function stringShortener(text){
    if(text.length > 18){
        let number = text.length
        return( 
        text.substring(0,3)+
        " "+
        "..."+
        " "+
        text.substring(number-3,number)
        );    
    }
    else 
      return  text;
    
    
}

export function isNotNullAndUndefined(value) {
    return value !== null && value !== undefined;
};

export const DISABLED_BUTTON_COLOR = "gray";