import React, { Component } from 'react';
import NotificationSystem from 'react-notification-system';
import { Redirect } from "react-router-dom";
import BackendApi from '../api/app2backend';
import Header from '../Components/Header';
import LogoutModal from '../Components/LogoutModal';
import { getHeaderList } from '../config/flavor/mainFlavor';
import '../Main.css';
import DashBoardScreen from './DashBoardScreen';
import PricesScreen from './PricesScreen';
import ProfilesScreen from './ProfilesScreen';
import Spendendosen from './Spendendosen';
import AnalysisScreen from './AnalysisScreen';
import ChangePasswordModal from '../Components/ChangePasswordModal';
import CreateCustomerScreen from './CreateCustomerScreen';
import { CUSTOMER_TYPE } from '../Helpers/const';
import DeviceScreen from './DeviceScreen';

class MainScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            funcList: [],
            token: "none",
            openLogoutModal: false,
            openChangePassword: false,
        }
        this.notificationSystem = React.createRef();
    }
    openLogoutModal = () => {
        this.setState({ openLogoutModal: true });
    }
    closeLogoutModal = () => {
        this.setState({ openLogoutModal: false });
    }
    handleLogout = () => {
        this.setState({ isUnauth: true });
        localStorage.clear();
    }
    componentWillMount() {
        let tempList = getHeaderList();
        this.setState({ funcList: tempList })

        if (this.props.location.state !== undefined) {
            this.setState({ token: this.props.location.state.token, }, () => {
            });
        }

        let localStorageInfo = localStorage.getItem('userInfo');
        localStorageInfo = JSON.parse(localStorageInfo);
        if (localStorageInfo && localStorageInfo.isAuthSuccess) {
            this.setState({ isUnauth: false });
        }
        else {// is LoggedOut
            let info = {
                token: this.props.location.state !== undefined ? this.props.location.state.token : "none"
            }
            BackendApi.initializeMainScreen(info, (res, err) => {
                if (err) {
                    if (err.response.status === 401) {
                        this.setState({ isUnauth: true });
                        alert("You are unauthorized. Please login firstly!".localize());
                    }
                }
                else {
                }
            });
        }
    }
    componentDidMount = () => {
        setTimeout(
            function () {
                this.headerRef.handleHeaderClick(0);
            }.bind(this), 50
        )
    }
    hightLightSelectedKey = (key) => {
        let tempList = this.state.funcList;
        tempList.map((header, index) => {
            header['isActive'] = index === key ? 1 : 0;
        });
        this.setState({ funcList: tempList });
    }
    addNotification = (event, message, type) => {
        if (event)
            event.preventDefault();
        const notification = this.notificationSystem.current;
        notification.addNotification({
            message: message,
            level: type,
        });
    };
    renderMenu = (key) => {
        switch (key) {
            case "dashboard":
                return <DashBoardScreen token={this.state.token} key={key} />;
            case "profiles":
                return (
                    <ProfilesScreen token={this.state.token} addNotification={this.addNotification} key={key}
                        onRef={ref => this.ProfileScreenRef = ref} logout={this.handleLogout}/>
                );
            case "spendendosen":
                return <Spendendosen token={this.state.token} addNotification={this.addNotification} key={key} />;
            case "prices":
                return <PricesScreen token={this.state.token} addNotification={this.addNotification} key={key} />;
            case "new":
                return <CreateCustomerScreen token={this.state.token} addNotification={this.addNotification} key={key} customerType={CUSTOMER_TYPE.PERSON} />;
            case "rooms":
                return <CreateCustomerScreen token={this.state.token} addNotification={this.addNotification} key={key} customerType={CUSTOMER_TYPE.ROOM} />;
            case "device":
                return <DeviceScreen token={this.state.token} addNotification={this.addNotification} key={key} />;
            case "analysis":
                return <AnalysisScreen token={this.state.token} addNotification={this.addNotification} key={key} />;
            default:
                return "";
        }
    };

    recoverPassword = (pass, newPass) => {
        let localStorageInfo = JSON.parse(localStorage.getItem('userInfo'));
        let userObject = { username: localStorageInfo.username, password: pass, newPassword: newPass };
        BackendApi.updatePassword(userObject, (res, err) => {
            if (err && err.response && err.request.status === 401) {
                this.addNotification(null, "The informations you gave are wrong!".localize(), "error");
            }
            else {
                this.addNotification(null, "The password changed successfully".localize(), "success");
            }
        });
    };

    render() {
        return (
            this.state.isUnauth ? <Redirect to={{
                pathname: '/',
                state: { token: this.state.token }
            }} /> :
                <div >
                    {/* <p style={{ color: 'black' }} >Main Screen {this.props.match.params.isLogged} </p> */}
                    <NotificationSystem ref={this.notificationSystem} />
                    {
                        this.state.openLogoutModal ?
                            <LogoutModal
                                handleLogout={this.handleLogout}
                                closeLogoutModal={this.closeLogoutModal} /> : null
                    }
                    {
                        this.state.openChangePassword ?
                            <ChangePasswordModal
                                addNotification={this.addNotification}
                                recoverPassword={this.recoverPassword}
                                closePasswordModal={() => this.setState({ openChangePassword: false })} /> : null
                    }

                    <Header
                        hightLightSelectedKey={this.hightLightSelectedKey}
                        funcList={this.state.funcList}
                        changePassword={() => this.setState({ openChangePassword: true })}
                        openLogoutModal={this.openLogoutModal}
                        changeLanguage={this.props.changeLanguage}
                        activeLanguage={this.props.activeLanguage}
                        onRef={ref => this.headerRef = ref} />

                    {this.state.funcList.map((data, index) => {
                        var ind = -1;
                        if (data.isActive === 1)
                            ind = index;
                        if (ind !== -1) {
                            return (
                                this.renderMenu(data.key)
                            )
                        }
                    })}

                </div>
        );
    }
}

export default MainScreen;
