import React from 'react';
import { createLastPaymentText } from '../Helpers/ListHelpers';
import { createPriceTextFromValue, isNotNullAndUndefined } from '../Helpers/ContentHelpers';
import ProfileScreenHeader from '../Components/ProfileScreenHeader';
import { MOMENT_VARIABLES } from '../Helpers/const';
import CustomTooltip from '../Components/CustomTooltip';
import IconButton from '@material-ui/core/IconButton';
import Button from "@material-ui/core/Button";
import {getColorTheme, isHaveNexudus} from "../config/flavor/mainFlavor";
import BackendApi from "../api/app2backend";


export default class GeneralProfileScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
    };

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(undefined);
        }
    };

    calculateFontSize = (text) => {
        return text.length > 17 ? 20 : 25;
    };

    handleHeaderClick = (value) => {
        this.headerProfileRef.handleHeaderClick(value);
    };

    renderPersonList = () => {
        const noPaymentText = "No Payment".localize();
        return this.props.personList && this.props.personList.length > 0 &&
            this.props.personList.map((data, index) => {
                const personQueData = this.props.personsLatesQueues.filter(item=> item.id === data.id)[0]
                return (
                    <a key={index} onClick={() => this.props.handlePersonClick(index)} href="#main" >
                        <div className="ProfileContainer" >
                            <div className="ProfileUpdateNameSurnameContainer" >
                                <div className="ProfileNameAndSurname" >
                                    <div style={{ width: '20%' }} />
                                    <div style={{ width: '50%' }} >
                                        <p className="ProfileListNameText" style={{ fontSize: this.calculateFontSize(data.name) }}>{data.name}</p>
                                    </div>
                                    <div style={{ width: '20%' }} >
                                        {
                                            data.note === null
                                                ?
                                                null
                                                :
                                                <CustomTooltip title={data.note} style={{ float: 'right' }} fontSize='20px'>
                                                    <IconButton  href="#main">
                                                        <i class ="info circle icon"/>
                                                    </IconButton>
                                                </CustomTooltip>
                                        }
                                    </div>
                                    <div className="InactiveContainer" >
                                        <p className="InactiveText"> {data.Account.activated === false ? `(${"Inactive".localize()})` : ""} </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ProfilePersonValueContainer" >
                                <div style={{  width: '18%', marginTop: "1%"}}>
                                    {
                                        (isHaveNexudus() && personQueData) ?
                                            <p className="ProfileListNameText" style={{ fontSize: this.calculateFontSize(personQueData.status) }}>{personQueData.status.toLowerCase().localize()   }</p>
                                            :null
                                    }
                                </div>
                                <div className="ProfileUpdateBalanceValue" >
                                    <div style={{ width: '65%', height: '100%' }} >
                                        <p className={"ProfileUpdateBalanceValueText"}>
                                            {
                                                isNotNullAndUndefined(data.Account.balance) ? createPriceTextFromValue(data.Account.balance) : noPaymentText
                                            }
                                        </p>
                                    </div>
                                    <div style={{ width: '35%', height: '100%' }} />
                                </div>
                                <div className="ProfileUpdateCurrentMonthContainer" >
                                    <div style={{ width: '55%', height: '100%' }} />
                                    <div style={{ width: '45%', height: '100%' }} >
                                        <div style={{ width: '85%', height: '100%' }} >
                                            <p className="ProfileUpdateCurrentMonthText">
                                                {
                                                    isNotNullAndUndefined(data.current_month_cost) ? createPriceTextFromValue(data.current_month_cost) : ""
                                                }
                                            </p>
                                        </div>
                                        <div style={{ width: '15%', height: '100%' }} />
                                    </div>
                                </div>
                                <div className="ProfileUpdatePrevMonthContainer" >
                                    <div style={{ width: '55%', height: '100%' }} />
                                    <div style={{ width: '45%', height: '100%' }} >
                                        <div style={{ width: '75%', height: '100%', display: 'inline' }} >
                                            <p className="ProfileUpdatePrevMonthText">
                                                {
                                                    isNotNullAndUndefined(data.prev_month_cost) ? createPriceTextFromValue(data.prev_month_cost) : ""
                                                }
                                            </p>
                                        </div>
                                        <div style={{ width: '25%', height: '100%' }} />
                                    </div>
                                </div>
                                <div className="ProfileUpdateLastPaymentContainer">
                                    <div style={{ width: '35%', height: '100%' }} />
                                    <div style={{ width: '65%', height: '100%', display: 'inline' }} >
                                        <p className="ProfileUpdateLastPaymentText">{createLastPaymentText(data.last_payment)}</p>
                                    </div>
                                </div>
                            </div>
                            <div style={{ width: '20%', height: '70px', }} />
                        </div>
                    </a>
                )
            });
    }

    returnButtonStyle = (type) => {
        return {
            backgroundColor: type === "delete" ? getColorTheme().errorTheme : getColorTheme().selectedTheme,
            fontSize: "18px", maxWidth: '120px', minWidth: '120px', maxHeight: '34px', minHeight: '34px',
        }
    };

    addCoworkerProductToQue = () => {
        const personIds = this.props.personList.map(person => person.id)
        BackendApi.addCoworkerProductToQue({customerList:personIds},(response)=>{
            this.props.addNotification(null, "The transfers added to queue!".localize(), "success");
            this.props.getLatestQue()
        },(err)=>{
            if(err.error.message && err.error.message === 'Customers are not valid'){
                return this.showInvalidCustomers(err.error)
            }
            return this.props.addNotification(null, "The transfers could not added to queue!".localize(), "error");
        })
    }

    showInvalidCustomers = (error) => {
        let message = "Some accounts are not synchronised with Nexudus! Accounts".localize()
        let invalidNameArray = error.invalidCustomerList
        let invalidNamesStr = ""
        invalidNameArray.map((name,index) => {
            invalidNamesStr += name
            if(index !== invalidNameArray.length-1)
                invalidNamesStr += ", "
        })
        return this.props.addNotification(null, `${message}: ${invalidNamesStr}`, "error");
    }

    render() {
        const dateNames = this.props.dateNames;
        return <div style={{ flexDirection: 'column', overflow: 'hidden' }} >
            <ProfileScreenHeader
                hightLightSelectedFloor={this.props.hightLightSelectedFloor}
                getPersonsOfFloor={this.props.getPersonsOfFloor}
                floorList={this.props.floorList}
                updateList={this.props.updateList}
                searchText={this.props.searchText}
                changeSearchText={this.props.updateSearchText}
                updateFloorIndex={this.props.updateFloorIndex}
                onRef={ref => this.headerProfileRef = ref} />
            <div className="PaymentContainer" >
                <div style={{ width: '16%', height: '70px', }} />

                <div style={{ width: '64%', height: '70px', display: 'flex', flexDirection: 'row', marginTop: '1%' }}>
                    <div style={{ width: '22%' ,height: '70px'}}>
                        {
                            isHaveNexudus() ?
                                <p className={"PaymentBalanceText"}>{"Transfer Status".localize()}</p> : null
                        }
                    </div>
                    <div style={{ width: '15%', }} >
                        <p className={"PaymentBalanceText"}>{"Balance".localize()}</p>
                    </div>
                    <div className="ProfileCurrentMonthHeaderContainer" >
                        <p className={"PaymentText"}>{dateNames[MOMENT_VARIABLES.NAME.CURRENT_MONTH]}</p>
                    </div>
                    <div style={{ width: '25%' }} >
                        <p className={"PaymentPrevText"}>{dateNames[MOMENT_VARIABLES.NAME.PREV_MONTH]}</p>
                    </div>
                    <div style={{ width: '28%' }} >
                        <p className={"PaymentlastText"}>{"Last payment on".localize()}</p>
                    </div>
                </div>
                <div style={{ width: '20%', height: '70px',display: 'flex', flexDirection: 'row', marginTop: '1%'  }}>
                    {
                        isHaveNexudus() ?
                            <div style={{ width: '28%' , paddingLeft:'25%'}} >
                                <Button  style={this.returnButtonStyle()} onClick={this.addCoworkerProductToQue} disabled={false}>
                                    {<p style={{ fontFamily: 'kelson2', color: 'white', fontSize: 15 }} >{"Transfer All".localize()}</p>}
                                </Button>
                            </div> : null
                    }
                </div>
            </div>
            {this.renderPersonList()}
        </div>
    }
}