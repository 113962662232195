import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import './Main.css';
import LoginScreen from './Screens/LoginScreen';
import MainScreen from './Screens/MainScreen';
import i18n from './i18n';
import ThemeBuilder from './Helpers/ThemeHelper';
import { createThemeObjectWithConfig } from './Helpers/ContentHelpers';
import { coworkingSpace, getPlatformName } from './config/flavor/mainFlavor';
import { LANGUAGE_SUPPORT } from './Helpers/const';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeLanguage: LANGUAGE_SUPPORT.ENGLISH
    };
    Object.assign(String.prototype, {
      localize() {
        return i18n.t(this);
      },
      format() {
        let args = arguments;
        return this.replace(/\{(\d+)\}/g, function () {
          return args[arguments[1]];
        })
      }
    });
  }

  changeTitleAndFavWithCoworking = () => {
    const html = document.getElementById("titleLink");
    const icon = document.getElementById("iconLink");
    if (icon.href !== `${coworkingSpace}.ico`) {
      html.textContent = `${getPlatformName()} Drink Purchase`;
      icon.href = `${coworkingSpace}.ico`;
    }
  };

  buildStyleElement = () => {
    const styleEl = document.createElement("style");

    styleEl.appendChild(document.createTextNode(""));
    document.head.appendChild(styleEl);

    return styleEl.sheet;
  };


  changeLocalization = () => {
    let newValue = this.state.activeLanguage === LANGUAGE_SUPPORT.ENGLISH ? LANGUAGE_SUPPORT.DEUTSCH : LANGUAGE_SUPPORT.ENGLISH;
    i18n.changeLanguage(newValue);
    this.setState({ activeLanguage: newValue });
  };

  getImageFromSource = () => {
    let newValue = this.state.activeLanguage === LANGUAGE_SUPPORT.ENGLISH ? LANGUAGE_SUPPORT.DEUTSCH : LANGUAGE_SUPPORT.ENGLISH;
    return `/images/languages/${newValue}.svg`;
  };

  render() {
    const stylesheet = this.buildStyleElement();
    const customStyleRules = ThemeBuilder(createThemeObjectWithConfig());
    this.changeTitleAndFavWithCoworking();
    stylesheet.insertRule(customStyleRules);
    return (
      <div className="App">
        <Switch>
          <Route path="/" exact={true} strict render={(props) => <LoginScreen {...props} changeLanguage={this.changeLocalization} activeLanguage={this.getImageFromSource()} />} />
          <Route path="/main" render={(props) => <MainScreen {...props} changeLanguage={this.changeLocalization} activeLanguage={this.getImageFromSource()} />} />
        </Switch>
      </div>
    );
  }
}
export default App;