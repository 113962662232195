import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ExitToApp from '@material-ui/icons/ExitToApp';
import PasswordIcon from '@material-ui/icons/LockOpen';
import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import '../Main.css';
import { getColorTheme, getHeadersMarginStyle } from '../config/flavor/mainFlavor';
import CustomTooltip from './CustomTooltip';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: this.props.funcList,
        }
    }
    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
    };

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(undefined);
        }
    };
    handleHeaderClick = (index) => {
        this.props.hightLightSelectedKey(index);
    }

    render() {
        const buttonStyle = {
            backgroundColor: "transparent", fontSize: "18px",
            maxHeight: '48px', minHeight: '48px',
            maxWidth: '160px', minWidth: '160px', borderWidth: 0,
        };

        return (
            this.state.isUnauth ? <Redirect to={{
                pathname: '/',
                state: { token: this.state.token }
            }} /> :
                <div className="mainHeader">
                    <img src={getColorTheme().logoActive} className={"App-logo-header"} alt="logo" />
                    {
                        this.state.list.map((data, index) => {
                            return (
                                <Button key={index} style={{ ...buttonStyle, marginLeft: getHeadersMarginStyle(index) }}
                                    onClick={() => this.handleHeaderClick(index)}>
                                    <p style={{ color: this.state.list[index].isActive === 0 ? "#424243" : getColorTheme().selectedTheme, }} >{data.text.localize()}</p>
                                </Button  >
                            )
                        })
                    }
                    <div className="LogoutButtonContainer" style={{ flex: 'auto ' }}>
                        <CustomTooltip title={"Log out".localize()} style={{ float: 'right' }}>
                            <IconButton onClick={this.props.openLogoutModal} href="#main">
                                <ExitToApp />
                            </IconButton>
                        </CustomTooltip>
                        <CustomTooltip title={"Change password".localize()} style={{ float: 'right' }}>
                            <IconButton onClick={this.props.changePassword} href="#main">
                                <PasswordIcon />
                            </IconButton>
                        </CustomTooltip>
                        <CustomTooltip title={"Change Language".localize()} style={{ float: 'right' }}>
                            <IconButton onClick={this.props.changeLanguage} href="#main">
                                <img className="MuiSvgIcon-root" src={this.props.activeLanguage}/>
                            </IconButton>
                        </CustomTooltip>
                    </div>
                </div>
        );
    }
}

export default Header;
