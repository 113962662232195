import React from 'react';
import BackendApi from '../api/app2backend';
import Button from '@material-ui/core/Button';
import { Grid, Icon, Input } from 'semantic-ui-react';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { getBorderWithTheme, getColorTheme } from '../config/flavor/mainFlavor';
import DeviceTransactionModal from '../Components/DeviceTransactionModal';
import { DISABLED_BUTTON_COLOR } from '../Helpers/ContentHelpers';
import CustomTooltip from '../Components/CustomTooltip';

const DEFAULT_DEVICE_CONF = { id: "", name: "", floor_id: "" };
export default class DeviceScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tokenInfo: null,
            registeredDevices: [],
            unregisteredDevices: [],
            floorListDropdown: [],
            editableDevice: "",
            selectedDevice: DEFAULT_DEVICE_CONF,
            newCreatedDevice: DEFAULT_DEVICE_CONF,
            isOpenTransactionModal: false,
        };
    }

    componentDidMount() {
        // todo: take once token
        let tokenToSend;
        let localStorageInfo = localStorage.getItem('userInfo');
        localStorageInfo = JSON.parse(localStorageInfo);
        if (localStorageInfo && localStorageInfo.isAuthSuccess) {
            tokenToSend = localStorageInfo.token
        }
        else
            tokenToSend = this.props.token
        const info = { token: tokenToSend, mobile: false };
        this.setState({ tokenInfo: info }, () => {
            this.fetchDevices();
            this.fetchFloor();
        });
    }

    fetchDevices = () => {
        BackendApi.getDevices(this.state.tokenInfo, (res, err) => {
            if (err) {
                this.createGenericErrorNotification(err.response, "device");
            }
            else if (res) {
                const devices = res.data;
                let registeredDevices = [], unregisteredDevices = [];
                devices.forEach((device) => {
                    device.name && device.Floor ? registeredDevices.push(device) : unregisteredDevices.push(device);
                })
                this.setState({ registeredDevices, unregisteredDevices });
            }
        });
    };

    fetchFloor = () => {
        BackendApi.getFloors(this.state.tokenInfo, (res, err) => {
            if (err) {
                this.createGenericErrorNotification(err.response, "floor");
            }
            else if (res) {
                const floor = res.data;
                this.setState({ floorListDropdown: floor })
            }
        });
    };

    createGenericErrorNotification = (response, object) => {
        if (response) {
            if (response.status === 401) {
                this.setState({ isUnauth: true });
                alert("You are unauthorized. Please login firstly!".localize());
            }
            else {
                this.props.addNotification(null, `The ${object} list could not be fetched!`.localize(), "error");
            }
        }
    };

    renderAddNewDevice = () => {
        const buttonStyle = {
            backgroundColor: this.checkNewDeviceForm() ? DISABLED_BUTTON_COLOR : getColorTheme().selectedTheme,
            fontSize: "18px"
        };
        return <Grid id="add-device">
            <Grid.Row id="add-device-header" columns={3}>
                <Grid.Column><p className="DeviceLabelText">{"ID".localize()}</p></Grid.Column>
                <Grid.Column><p className="DeviceLabelText">{"Name".localize()}</p></Grid.Column>
                <Grid.Column><p className="DeviceLabelText">{"Floor".localize()}</p></Grid.Column>
            </Grid.Row>
            <Grid.Row id="add-device-main" columns={3}>
                <Grid.Column>
                    <Input
                        value={this.state.newCreatedDevice["id"]}
                        onChange={(event, data) => this.handleCreateDevice(data, "id")}
                        style={{ width: '100%', height: '100%' }} />
                </Grid.Column>
                <Grid.Column>
                    <Input
                        value={this.state.newCreatedDevice["name"]}
                        onChange={(event, data) => this.handleCreateDevice(data, "name")}
                        style={{ width: '100%', height: '100%' }} />
                </Grid.Column>
                <Grid.Column>
                    <Select
                        value={this.state.newCreatedDevice["floor_id"]}
                        onChange={(event) => this.handleCreateDevice(event.target, "floor_id")}
                        style={{ width: '100%', border: getBorderWithTheme("1px", "solid"), height: '100%', }}>
                        {this.getFloorsMenu()}
                    </Select>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row id="add-device-actions" centered>
                <Button
                    style={buttonStyle}
                    disabled={this.checkNewDeviceForm()} onClick={this.createNewDevice}>
                    <p style={{ fontFamily: 'kelson2', color: 'white', fontSize: 18 }} >{"Add New Device".localize()}</p>
                </Button>
            </Grid.Row>
        </Grid>
    };

    checkNewDeviceForm = () => {
        const createdDevice = this.state.newCreatedDevice;
        return createdDevice.id === "" || createdDevice.floor_id === "";
    };

    createNewDevice = () => {
        const device = this.state.newCreatedDevice
        BackendApi.addDevices(device, (res, err) => {
            if (res.status && res.status === 200) {
                this.props.addNotification(null, "successfullyDeviceAddedInfo".localize().format(device.name), "success");
                this.fetchDevices();
                this.setState({ newCreatedDevice: DEFAULT_DEVICE_CONF });

            } else {
                this.props.addNotification(null, "The device cannot be created!".localize(), "error");
            }
        });
    };

    renderGridColumn = (deviceList, labelHeader) => {
        return <Grid.Column>
            <p className="DeviceLabelText">{labelHeader}</p>
            {deviceList.map((device, index) =>
                <Grid.Row key={`${device.id}_${index}`} stretched>
                    {this.renderDeviceCard(device)}
                </Grid.Row>
            )}
        </Grid.Column>
    };

    renderDeviceCard = (device) => {
        return <Grid id="device-card" celled>
            <Grid.Row id="info-groups-for-device" columns={3}>
                <Grid.Column>{this.renderTextField("id", device)}</Grid.Column>
                <Grid.Column>{this.renderTextField("name", device)}</Grid.Column>
                <Grid.Column>{this.renderFloorDropdown("floor_id", device)}</Grid.Column>
            </Grid.Row>
            <Grid.Row id="button-groups-for-device">
                {this.checkDeviceIsEditMode(device) ?
                    <Grid.Column>
                        <CustomTooltip title={"Apply".localize()}>
                            <IconButton onClick={this.applyEditMode}>
                                <Icon name="check circle" />
                            </IconButton>
                        </CustomTooltip>
                        <CustomTooltip title={"Cancel".localize()}>
                            <IconButton onClick={this.closeEditMode}>
                                <Icon name="close" />
                            </IconButton>
                        </CustomTooltip>
                    </Grid.Column> :
                    <Grid.Column centered>
                        <CustomTooltip title={"Check Transactions".localize()}>
                            <IconButton onClick={() => this.openDeviceTransactionMenu(device)}>
                                <Icon name="coffee" />
                            </IconButton>
                        </CustomTooltip>
                        <CustomTooltip title={"Edit".localize()}>
                            <IconButton onClick={() => this.openEditMode(device)}>
                                <Icon name="edit" />
                            </IconButton>
                        </CustomTooltip>
                    </Grid.Column>
                }
            </Grid.Row>
        </Grid>
    };

    renderTextField = (deviceKey, device) => {
        const textField = <div>
            <p className="DrinkNameText">
                {device[deviceKey] ? device[deviceKey] : "Unknown Device".localize()}
            </p>
        </div>;
        const inputField = <Input
            value={this.state.selectedDevice[deviceKey]}
            onChange={(event, data) => this.handleEditDevice(data, deviceKey)}
            style={{ width: '100%', height: '100%' }} />;
        return this.checkDeviceIsEditMode(device) && deviceKey !== "id" ? inputField : textField;
    };

    renderFloorDropdown = (deviceKey, device) => {
        return <Select
            value={!this.checkDeviceIsEditMode(device) ? device[deviceKey] : this.state.selectedDevice[deviceKey]}
            disabled={!this.checkDeviceIsEditMode(device)}
            onChange={(event) => this.handleEditDevice(event.target, deviceKey)}
            style={{ width: '100%', border: getBorderWithTheme("1px", "solid"), height: '100%', }}>
            {this.getFloorsMenu()}
        </Select>
    }

    getFloorsMenu = () => {
        return this.state.floorListDropdown.map((data, index) => {
            return <MenuItem key={index} value={data.id}>{data.name}</MenuItem>
        });
    };

    checkDeviceIsEditMode = (device) => {
        return this.state.editableDevice === device.id && device.name !=="";
    };

    openEditMode = (device) => {
        this.setState({ editableDevice: device.id, selectedDevice: device });
    };

    applyEditMode = () => {
        
        const name = `${this.state.selectedDevice.name}(${this.state.selectedDevice.id})`;
        if(!this.state.selectedDevice.name || !this.state.selectedDevice.floor_id ){
           
            if(!this.state.selectedDevice.floor_id && !this.state.selectedDevice.name )
            {
            this.props.addNotification(null,"Name and Floor information cannot written".localize(),"error")
            }
            else if(!this.state.selectedDevice.floor_id)
            {
            this.props.addNotification(null,"Floor information cannot written".localize(),"error")   
            }
            else
            {
            this.props.addNotification(null,"Name information cannot written".localize(),"error")
            }
        }  
        else
        {
            BackendApi.updateDevices(this.state.selectedDevice, (res, err) => {
                if (res.status === 200) {
                    this.props.addNotification(null, "successfullyDeviceUpdatedInfo".localize().format(name), "success");
                    this.closeEditMode();
                    this.fetchDevices();

                } else {
                    this.props.addNotification(null, "The device cannot be updated!".localize(), "error");
                }
            });
        }
    };

    closeEditMode = () => {
        this.setState({ editableDevice: "", selectedDevice: DEFAULT_DEVICE_CONF });
    };

    openDeviceTransactionMenu = (device) => {
        this.setState({ isOpenTransactionModal: true, selectedDevice: device });
    };

    closeDeviceTransactionMenu = () => {
        this.setState({ isOpenTransactionModal: false, selectedDevice: DEFAULT_DEVICE_CONF });
    };

    handleCreateDevice = (data, deviceKey) => {
        const value = data.value;
        let newCreatedDevice = { ...this.state.newCreatedDevice };
        newCreatedDevice[deviceKey] = value;
        this.setState({ newCreatedDevice });
    };

    handleEditDevice = (data, deviceKey) => {
        const value = data.value;
        let selectedDevice = { ...this.state.selectedDevice };
        selectedDevice[deviceKey] = value;
        this.setState({ selectedDevice });
    };

    render() {
        return <div id="device-screen" className="AnalysisContainer">
            {this.renderAddNewDevice()}
            <Grid columns="two" divided>
                {this.renderGridColumn(this.state.registeredDevices, "Registered Devices".localize())}
                {this.renderGridColumn(this.state.unregisteredDevices, "Unregistered Devices".localize())}
            </Grid>
            {this.state.isOpenTransactionModal ?
                <DeviceTransactionModal
                    open={true}
                    tokenInfo={this.state.tokenInfo}
                    device={this.state.selectedDevice}
                    addNotification={this.props.addNotification}
                    onClose={this.closeDeviceTransactionMenu} /> : null}
        </div>
    }
}