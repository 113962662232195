import moment from 'moment';
import i18n from '../i18n';
import 'moment/locale/de';
import 'moment/locale/en-gb';

export function generatePaymentInfo(personInfo, paymentValue) {
    let info = {
        person_id: personInfo.id,
        floor: personInfo.floor_id,
        drink_cost: parseFloat(paymentValue),
        device: null, // decide how to handle "web based payments"
        quantity: 1,
    }
    return info;
}

export function generateSpendendosenPaymentInfo(personInfo, paymentValue) {
    let localStorageObj = JSON.parse(localStorage.getItem("userInfo"));
    let info = {
        admin_id: localStorageObj.admin_id,
        cost: parseFloat(paymentValue)
    }
    return info;
}

export function generatePurchase(selectedDrink, personInfo, costValue) {
    return {
        person_id: personInfo.id,
        floor: personInfo.floor_id,
        cost: parseFloat(costValue),
        drinkName: selectedDrink,
        device: null, // decide how to handle "web based purchase"
        quantity: 1
    }
}

export function generateCurrentAndPrevMonthValue() {
    var currentMonthStart = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss');
    var currentMonthEnd = moment().endOf('month').format('YYYY-MM-DD HH:mm:ss');
    var prevMonthStart = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD HH:mm:ss');
    var prevMonthEnd = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD HH:mm:ss');
    return {
        currentMonthStart: currentMonthStart,
        currentMonthEnd: currentMonthEnd,
        prevMonthStart: prevMonthStart,
        prevMonthEnd: prevMonthEnd,
    }
}

export function generateCurrentYearStart() {
    var currentYear = (new Date()).getFullYear();
    return {
        ...generateCurrentAndPrevMonthValue(),
        firstMonthOfYear: moment(`${currentYear}-01-01 00:00:01`).startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        lastMonthOfYear: moment(`${currentYear}-01-01 00:00:01`).endOf('year').format('YYYY-MM-DD HH:mm:ss')
    }
}

export function generateCurrentAndPrevMonthName() {
    moment.locale(i18n.language)
    let currentMonthName = moment().startOf('month').format('YYYY MMMM');
    let currentYearName = moment().startOf('month').format('YYYY');
    let prevMonthName = moment().subtract(1, 'months').startOf('month').format('YYYY MMMM');
    return { currentMonthName, prevMonthName, currentYearName };
}

export function createLastPaymentText(last_payment) {
    moment.locale(i18n.language);
    return last_payment !== undefined ? moment(last_payment).format("DD MMMM YYYY") : "-"
};

export function generateCurrentProp(currentList, persons) {
    if (currentList) {
        for (let i = 0; i < currentList.length; i++) {
            var currentListPerson = currentList[i];
            if (persons) {
                for (let j = 0; j < persons.length; j++) {
                    let person = persons[j];
                    if (currentListPerson.id === person.id) {
                        let value = 0;
                        currentListPerson.TransactionsInfos.map((info) => value = value + info.amount);
                        persons[j].current_month_cost = value;
                    }
                }
            }
        }
    }

}

export function generatePrevProp(prevList, persons) {
    if (persons) {
        for (let i = 0; i < persons.length; i++) {
            let person = persons[i];
            if (prevList) {
                for (let j = 0; j < prevList.length; j++) {
                    var prevListPerson = prevList[j];
                    if (prevListPerson.id === person.id) {
                        let value = 0;
                        prevListPerson.TransactionsInfos.map((info) => value = value + info.amount);
                        persons[i].prev_month_cost = value
                    }
                }
            }
        }
    }
}

export function generateLastPaymentProp(lastPaymentList, persons) {
    if (persons) {
        for (let i = 0; i < persons.length; i++) {
            let person = persons[i];
            if (lastPaymentList) {
                for (let j = 0; j < lastPaymentList.length; j++) {
                    var prevListPerson = lastPaymentList[j];
                    if (prevListPerson.id === person.id && prevListPerson.TransactionsInfos.length !== 0) {
                        persons[i].last_payment = prevListPerson.TransactionsInfos[0].update_date;
                    }
                }
            }
        }
    }
}

export function matchPattern(data) {
    return /^[0-9]+(,[0-9]+){0,2}$/.test(data);
}

export function convertDate(orginalDate) {
    let date = moment(orginalDate).format("YYYY-MM-DD HH:mm:ss");
    var indexOfSpace = date.indexOf(" ");
    var newDate = date.substring(0, indexOfSpace);
    //Find year, month, day
    let year = newDate.substring(0, 4);
    let month = newDate.substring(5, 7);
    let day = newDate.substring(8, 10);

    return day + "/" + month + "/" + year;
}