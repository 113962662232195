import Button from '@material-ui/core/Button';
import NotificationSystem from 'react-notification-system';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import PasswordIcon from '@material-ui/icons/Lock';
import PersonIcon from '@material-ui/icons/Person';
import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import BackendApi from '../api/app2backend';
import '../Main.css';
import { getColorTheme, isNeedMoreAdmin, isHaveNexudus } from '../config/flavor/mainFlavor';
import IconButton from '@material-ui/core/IconButton';
import CustomTooltip from './CustomTooltip';

class LoginInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAuthSuccess: false,
            userName: "",
            password: "",
            token: ""
        }
        this.notificationSystem = React.createRef();
    }

    createUser = () => {
        let credentials = {
            password: this.state.password,
            user_name: this.state.userName
        };
        if (credentials.password === "" || credentials.user_name === "")
            this.addNotification(null, "Please fill all of the fields!".localize(), "error");
        else {
            var bcrypt = require('bcryptjs');
            var salt = bcrypt.genSaltSync(10);
            var hashedPassword = bcrypt.hashSync(JSON.stringify(this.state.password), salt);
            credentials.password = hashedPassword;
            BackendApi.signUp(credentials, (res, err) => {
                if (err) {
                    if (err.response.status !== null && err.response.status !== undefined) {
                        if (err.response.status === 503)
                            this.addNotification(null, "Username has already been taken. Please choose different username!".localize(), "error");
                        else if (err.response.status === 409)
                            this.addNotification(null, "Due to unavailable service, user cannot be created!".localize(), "error");
                    }
                    else
                        this.addNotification(null, "Benutzer kann nicht erstellt werden, da der Server nicht verfügbar ist!", "error");
                }
                else if (res) {
                    if (res.status === 200)
                        this.addNotification(null, "User created successfully!".localize(), "success");

                }
            });
        }
    }

    addNotification = (event, message, type) => {
        if (event)
            event.preventDefault();
        const notification = this.notificationSystem.current;
        notification.addNotification({
            message: message,
            level: type,
        });
    };

    handleLogin = () => {
        var credentials = {
            password: this.state.password,
            userName: this.state.userName
        }
        if (credentials.password === "" || credentials.username === "")
            this.addNotification(null, "Please fill all of the fields!".localize(), "error");
        else {
            BackendApi.checkAuthentication(credentials, (res, err) => {
                // console.log("login response", res);
                if (err) {
                    if (err.response) {
                        if (err.request.status === 401) {
                            this.addNotification(null, "The informations you gave are wrong!".localize(), "error");
                        }
                    }
                }
                else {
                    if (res.data.auth) {
                        this.addNotification(null, "Logged in successfully !".localize(), "success");
                        /*if(isHaveNexudus()){
                            this.loginNexudus()
                        }*/
                        //Check the local storage
                        setTimeout(
                            function () {
                                let succLocalStorageItem = {
                                    isAuthSuccess: true,
                                    token: res.data.token,
                                    username: this.state.userName,
                                    admin_id: res.data.id
                                }
                                // localList.push(succLocalStorageItem);
                                localStorage.setItem('userInfo', JSON.stringify(succLocalStorageItem));
                                this.setState({ isAuthSuccess: true, token: res.data.token });
                            }.bind(this), 1500
                        )

                    }
                }
            })
        }
    }
/*
    loginNexudus(){
       BackendApi.authNexudus(
            (res)=>{
                this.addNotification(null, "Logged in to Nexudus successfully!".localize(), "success");
        },
            (err)=>{
                if (err.response) {
                    if (err.request.status === 401) {
                        this.addNotification(null, "Could not log in to Nexudus!".localize(), "error");
                    }
                }
        })
    }
*/
    handleChange = (event, type) => {
        if (type === "username") {
            this.setState({ userName: event.target.value });
        }
        else {
            this.setState({ password: event.target.value });
        }
    }
    handleKeyDown = (event) => {
        if (event.keyCode === 13 /*enter*/) {
            this.handleLogin();
        }
    }

    renderUserCreateButton = () => {
        return (
            <Button
                style={{
                    backgroundColor: getColorTheme().selectedTheme,
                    fontSize: "18px",
                    maxWidth: '280px',
                    minWidth: '280px',
                    maxHeight: '45px',
                    minHeight: '45px',
                    marginTop: '2%',
                }}
                variant="contained"
                onClick={() => this.createUser()}
            >
                <p style={{ color: 'white' }} >{"Create User".localize()}</p>
            </Button  >
        );
    };

    render() {
        if (this.state.isAuthSuccess) {
            return <Redirect to={{
                pathname: '/main',
                state: { token: this.state.token, }
            }} />
        }
        return (
            <div className="LoginInput" onKeyDown={(event) => this.handleKeyDown(event)}>
                <NotificationSystem ref={this.notificationSystem} />
                <TextField
                    id="standard-username-input"
                    label={"Username".localize()}
                    type="text"
                    onChange={(event, data) => this.handleChange(event, "username")}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <PersonIcon transform="scale(1.2)" />
                            </InputAdornment>
                        ),
                    }}
                    autoComplete="current-password"
                    margin="normal"
                    style={{ width: '23%' }}
                />
                <TextField
                    id="standard-password-input"
                    label={"Password".localize()}
                    type="password"
                    onChange={(event, data) => this.handleChange(event, "password")}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <PasswordIcon transform="scale(1.1)" />
                            </InputAdornment>
                        ),
                    }}
                    autoComplete="current-password"
                    margin="normal"
                    style={{ width: '23%' }}
                />
                <Button
                    style={{
                        backgroundColor: getColorTheme().selectedTheme,
                        fontSize: "18px",
                        maxWidth: '280px',
                        minWidth: '280px',
                        maxHeight: '45px',
                        minHeight: '45px',
                        marginTop: '2%',
                    }}
                    variant="contained"
                    onClick={() => this.handleLogin()}
                >
                    <p style={{ color: 'white' }} >{"Login".localize()}</p>
                </Button  >

                {/* Create User Button - In case of emergency    */}
                {isNeedMoreAdmin() ? this.renderUserCreateButton() : null}
                <CustomTooltip title={"Change Language".localize()} style={{ float: 'right' }}>
                    <IconButton onClick={this.props.changeLanguage}>
                        <img className="MuiSvgIcon-root" src={this.props.activeLanguage} />
                    </IconButton>
                </CustomTooltip>
            </div>
        );
    }
}
export default LoginInput;
