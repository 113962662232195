import React from 'react';
import { Input } from 'semantic-ui-react';
import { CUSTOMER_TYPE } from '../Helpers/const';

export default class RoomInfoContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            roomName: "",
        };
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(undefined);
        }
    }

    renderInput = () => {
        return <div className="SixtyPercentage">
            <Input
                iconPosition='left' icon="address book"
                onChange={(event, data) => this.handleTextChange(event, data)}
                placeholder="" style={this.getInputStyle()} />
        </div>
    };

    handleTextChange = (event, data) => {
        const value = data.value.replace(/\s+/g, ' ').trim();
        this.setState({ roomName: value });
    };

    getInputStyle = () => {
        return { width: '80%', height: '50%' };
    };

    getStateInfo = () => {
        return { type: CUSTOMER_TYPE.ROOM, roomName: this.state.roomName };
    };


    render() {
        return <div id="room_info_container" style={{ display: 'contents' }}>
            <div id="room_name" className="NewProfileFirstName">
                <div className="NewProfileTextContainer">
                    <p className="NewProfileGeneralText" >{"Room Name".localize()}</p>
                </div>
                <div className="NewProfileSelectContainer">
                    {this.renderInput()}
                </div>
            </div>
        </div>
    }
}