import React from 'react';
import BackendApi from '../api/app2backend';
import { Redirect } from "react-router-dom";
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { getColorTheme, coworkingSpace, getBorderWithTheme } from '../config/flavor/mainFlavor';
import PersonInfoContainer from '../Components/PersonInfoContainer';
import RoomInfoContainer from '../Components/RoomInfoContainer';
import { CUSTOMER_TYPE } from '../Helpers/const';
import PinTriggerComponent from '../Components/PinTriggerComponent';

export default class CreateCustomerScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isUnauth: true,
            floor: -1,
            floorListDropdown: [],
        };
    }

    componentWillMount() {
        let tokenToSend;
        let localStorageInfo = localStorage.getItem('userInfo');
        localStorageInfo = JSON.parse(localStorageInfo);
        if (localStorageInfo && localStorageInfo.isAuthSuccess) {
            tokenToSend = localStorageInfo.token
            this.setState({ isUnauth: false });
        }
        else
            tokenToSend = this.props.token

        const info = { token: tokenToSend };
        this.setState({ tokenActual: tokenToSend });

        BackendApi.getFloors(info, (res, err) => {
            if (err) {
                if (err.response.status === 401) {
                    this.setState({ isUnauth: true, isLoading: false });
                    alert("You are unauthorized. Please login firstly!".localize());
                }
                else {
                    this.props.addNotification("The required information could not be loaded!".localize(), "error");
                }
            }
            else if (res) {
                console.log(res);
                let floors = res.data;
                this.setState({ floorListDropdown: floors })
            }
        });
    }

    renderCustomerInfo = () => {
        switch (this.props.customerType) {
            case CUSTOMER_TYPE.PERSON:
                return <PersonInfoContainer onRef={(ref) => this.personInfo = ref} />;
            case CUSTOMER_TYPE.ROOM:
                return <RoomInfoContainer onRef={(ref) => this.roomInfo = ref} />;
            default:
                return [];
        }
    };

    sendSuccessNotification = (customerInfo) => {
        switch (this.props.customerType) {
            case CUSTOMER_TYPE.PERSON:
                this.props.addNotification(null,
                    "successfullyPersonAddedInfo".localize().format(customerInfo.firstName, customerInfo.lastName, this.state.floorName), "success");
                break;
            case CUSTOMER_TYPE.ROOM:
                this.props.addNotification(null,
                    "successfullyRoomAddedInfo".localize().format(customerInfo.firstName, this.state.floorName), "success");
                break;
            default:
                break;
        }
    };

    prepareCustomerInfo = () => {
        let generalInfo = {
            floor: this.state.floor,
            token: this.state.tokenActual,
            mobile: false,
            pin: this.pinTrigger ? this.pinTrigger.getPinValue() : "not_setted",
        };
        switch (this.props.customerType) {
            case CUSTOMER_TYPE.PERSON:
                const infoPerson = this.personInfo ? this.personInfo.getStateInfo() : {};
                return {
                    ...generalInfo, ...{
                        firstName: infoPerson.firstName,
                        lastName: infoPerson.lastName,
                        email: infoPerson.email,
                        type: infoPerson.type
                    }
                };
            case CUSTOMER_TYPE.ROOM:
                const infoRoom = this.roomInfo ? this.roomInfo.getStateInfo() : {};
                return {
                    ...generalInfo, ...{
                        firstName: infoRoom.roomName,
                        lastName: "",
                        email: "",
                        type: infoRoom.type
                    }
                };
            default:
                return {};
        }
    };

    checkRequiredInfo = (customerInfo) => {
        switch (this.props.customerType) {
            case CUSTOMER_TYPE.PERSON:
                return customerInfo.firstName !== "" && customerInfo.lastName !== "" && customerInfo.email !== "" && customerInfo.floor !== -1;
            case CUSTOMER_TYPE.ROOM:
                return customerInfo.firstName !== "" && customerInfo.floor !== -1;
            default:
                return false;
        }
    };

    handleAddCustomer = () => {
        const customerInfo = this.prepareCustomerInfo();
        if (this.checkRequiredInfo(customerInfo)) {
            BackendApi.addProfile(customerInfo, (res, err) => {
                if (err) {
                    if (err.response) {
                        if (err.response.status === 401) {
                            alert("You are unauthorized. Please login firstly!".localize());
                            this.setState({ isUnauth: true });
                        } else {
                            this.props.addNotification(`The ${this.props.customerType} cannot be added!`.localize(), "error");
                        }
                    }
                } else {
                    if (res.status === 200) {
                        this.sendSuccessNotification(customerInfo);
                    }
                }
            });
        } else {
            this.props.addNotification(null, "Please fill all of the fields!".localize(), "error");
        }
    };

    getButtonStyle = () => {
        return {
            backgroundColor: getColorTheme().selectedTheme, fontSize: "18px",
            marginTop: '4%', alignSelf: 'center',
            maxWidth: '200px', minWidth: '200px',
            maxHeight: '45px', minHeight: '45px',
        }
    };

    handleFloorChange = (event, data) => {
        this.setState({ floor: data.props.value, floorName: data.props.children });
    }

    getFloorSelectStyle = () => {
        return { width: '80%', border: getBorderWithTheme("1px", "solid"), height: '50%' };
    };

    getFloorTxt = () => {
        return coworkingSpace === "rostock" ? "Lounge" : "Floor";
    };

    getButtonTxt = () => {
        switch (this.props.customerType) {
            case CUSTOMER_TYPE.PERSON:
                return "Add Profile".localize();
            case CUSTOMER_TYPE.ROOM:
                return "Add Room".localize();
            default:
                return "Undefined Type";
        }
    };

    renderFloorInfo = () => {
        return <div id="floor_container" className="NewProfileLastName">
            <div id="floor_text" className="NewProfileTextContainer">
                <p className="NewProfileGeneralText" >{this.getFloorTxt()}</p>
            </div>
            <div id="floor_comp" className="NewProfileSelectContainer">
                <div className="SixtyPercentage">
                    <Select
                        value={this.state.floor}
                        onChange={(event, data) => this.handleFloorChange(event, data)}
                        style={this.getFloorSelectStyle()}>
                        {
                            this.state.floorListDropdown.map((data, index) => {
                                return (
                                    <MenuItem key={index} value={data.id}>{data.name}</MenuItem>
                                )
                            })
                        }
                    </Select>
                </div>
            </div>
        </div>
    };

    render() {
        return (
            this.state.isUnauth ? <Redirect to={{
                pathname: '/',
                state: { token: this.props.token }
            }} /> :
                <div className="NewProfile">
                    <div className="NewProfileInputContainer">
                        {this.renderCustomerInfo()}
                        {this.renderFloorInfo()}
                        <PinTriggerComponent onRef={(ref) => this.pinTrigger = ref} addNotification={this.props.addNotification} />
                        <div className="NewProfileButtonContainer">
                            <Button style={this.getButtonStyle()} variant="contained" onClick={this.handleAddCustomer}>
                                <p className="NewProfileAddButtonText" >{this.getButtonTxt()}</p>
                            </Button>
                        </div>
                    </div>
                </div>
        );
    }
}