import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Button from '@material-ui/core/Button';

export default class CustomReactTable extends React.Component {
    constructor(props){
        super(props);
        this.state = {

        };
    }

    componentDidMount(){
        if (this.props.onRef) {
            this.props.onRef(this);
        }
    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(undefined);
        }
    };

    getResolvedState = () => {
        if (this.reactTable) {
            return this.reactTable.getResolvedState().sortedData;
        }

        return [];
    };

    renderSubComponent = (row) => {
        //maybe need generic for other components.
        let {subComponentConditionKey,subComponentConditionValue} = this.props
        let condition = row.original[subComponentConditionKey]
        if(condition && condition === subComponentConditionValue){
            return null
        }
        return (
            <div style={{ alignItems: 'flex-start' }} >
                <Button
                    style={{
                        backgroundColor: "#FF3D00",
                        fontSize: "18px",
                        maxWidth: '120px',
                        minWidth: '120px',
                        maxHeight: '30px',
                        minHeight: '30px',
                    }}
                    variant="contained"
                    onClick={() => this.props.subComponentClick(row)}
                    disabled={this.props.subComponentLoading}
                >
                    <p style={{ color: 'white', fontSize: 12 }} >{this.props.subComponentText}</p>
                </Button>
            </div>
        )
    };

    getLocalizedStrings = () => {
        return {
            previousText: 'Previous'.localize(),
            nextText: 'Next'.localize(),
            loadingText: 'Loading'.localize(),
            noDataText: 'No rows found'.localize(),
            pageText: 'Page'.localize(),
            ofText: 'of'.localize(),
            rowsText: 'rows'.localize(),
        };
    };

    isSubComponentRequired = () => {
        return this.props.subComponentClick && this.props.subComponentText;
    };

    render() {
        return (
            <ReactTable 
                ref={(ref) => this.reactTable = ref}
                loading={this.props.loading}
                data={this.props.data}
                // key={this.props.key}
                columns={this.props.columns}
                resolveData={data => data.map(row => row)}
                defaultPageSize={this.props.defaultPageSize}
                filterable
                {...this.getLocalizedStrings()}
                onFilteredChange={this.props.onFilteredChange}
                defaultFilterMethod={(filter, row) =>
                    String(row[filter.id]).includes(filter.value)}
                SubComponent={
                    this.isSubComponentRequired() ?
                    row => this.renderSubComponent(row) : false
                }/>
        );
    }
}