import React from 'react';
import Button from '@material-ui/core/Button';
import { Input, Modal } from 'semantic-ui-react';
import { getColorTheme } from '../config/flavor/mainFlavor';
import ReactPasswordStrength from 'react-password-strength';

export default class ChangePasswordModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPass: "",
            newPass: "",
            verifyPass: ""
        };
    }

    handleChangePassword = (value, stateKey) => {
        this.setState({ [stateKey]: value });
    };

    handleFormCleared = () => {
        this.setState({ currentPass: "", newPass: "", verifyPass: "" }, () => this.ReactPasswordStrength.clear());
    };

    handleRecoverPassword = () => {
        if (this.state.newPass !== this.state.verifyPass) {
            this.props.addNotification(null, "Password do not match!".localize(), "error");
            this.handleFormCleared()
        }
        else {
            this.props.recoverPassword(this.state.currentPass, this.state.newPass);
            this.props.closePasswordModal();
        }
    };

    isButtonDisabled = () => {
        return this.state.currentPass === "" || this.state.newPass === "" || this.state.verifyPass === "";
    };

    render() {
        const buttonStyle = {
            backgroundColor: "#FF3D00", fontSize: "18px", maxWidth: '190px', 
            minWidth: '190px', maxHeight: '45px', minHeight: '45px', marginLeft: '3%'
        };
        const buttonColor = this.isButtonDisabled() ? "grey" : getColorTheme().selectedTheme;
        const divStyle = {width: '100%', height: '50px'};
        const inputStyle = {width: '100%', height: '100%'};
        const spanStyle = { fontFamily: 'kelson2', color: 'white' };
        return (
            <Modal open={true} closeOnDimmerClick={true} centered={false} closeOnDocumentClick={true} size="small">
                <Modal.Header style={{ textAlign: 'center' }} >{"Password".localize()} </Modal.Header>
                <Modal.Content>
                    <label className="LogoutTextContainer">{"Current Password".localize()}</label>
                    <div style={divStyle}>
                        <Input style={inputStyle} type="password" value={this.state.currentPass} onChange={(e, data) => this.handleChangePassword(data.value, "currentPass")}/>
                    </div>
                    <label className="LogoutTextContainer">{"New Password".localize()}</label>
                    <div style={divStyle}>
                        <ReactPasswordStrength
                            ref={ref => this.ReactPasswordStrength = ref}
                            scoreWords={['weak'.localize(), 'weak'.localize(), 'okay'.localize(), 'good'.localize(), 'strong'.localize()]}
                            tooShortWord={"too short".localize()}
                            changeCallback={(data) => this.handleChangePassword(data.password, "newPass")}
                        />
                    </div>
                    <label className="LogoutTextContainer">{"Verify Password".localize()}</label>
                    <div style={divStyle}>
                        <Input style={inputStyle} type="password" value={this.state.verifyPass} onChange={(e, data) => this.handleChangePassword(data.value, "verifyPass")}/>
                    </div>                 
                </Modal.Content>
                <Modal.Actions>
                    <Button style={{ ...buttonStyle, backgroundColor: buttonColor }} variant="contained"
                        onClick={this.handleRecoverPassword} disabled={this.isButtonDisabled()}>
                        <p style={spanStyle} >{"Change password".localize()}</p>
                    </Button>
                    <Button style={buttonStyle} variant="contained" onClick={() => this.props.closePasswordModal()}>
                        <p style={spanStyle} >{"Cancel".localize()}</p>
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}