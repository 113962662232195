import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { getColorTheme } from '../config/flavor/mainFlavor';

export default class CustomTooltip extends React.Component {
    render() {
        const TooltipComponent = withStyles((theme) => ({
            tooltip: {
                backgroundColor: theme.palette.common.white,
                color: getColorTheme().selectedTheme,
                boxShadow: theme.shadows[1],
                fontSize: this.props.fontSize ? this.props.fontSize : 18,
            }
        }))(Tooltip);
        return <TooltipComponent {...this.props} />
    }
}