import React from 'react';
import CSVExporterButton from '../Components/CSVExporterButton';
import CustomReactTable from '../Components/CustomReactTable';
import DatePickerModalTrigger from '../Components/DatePickerModalTrigger';
import { getColorTheme } from '../config/flavor/mainFlavor';
import { momentIsBetween } from '../Helpers/TimeHelper';
import BackendApi from '../api/app2backend';
import { Redirect } from 'react-router';
import { createOrderOfPersonData, sortByDate, sortByPrice, calculateTotalCostForAnalysis } from '../Helpers/ContentHelpers';

export default class AnalysisScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isUnauth: false,
            token: "",
            orderTableData: [],
            dataToDownload: [],
            drinkList: [],
            personList: [],
            deviceList: [],
            floorList: [],
            loading: true,
            column: [],
        };
    }

    componentWillMount() {
        this.getRequiredInfos();
        this.setState({ column: this.generateColumn() });
    }

    getRequiredInfos = () => {
        let tokenToSend;
        let localStorageInfo = localStorage.getItem('userInfo');
        localStorageInfo = JSON.parse(localStorageInfo);
        if (localStorageInfo && localStorageInfo.isAuthSuccess) {
            tokenToSend = localStorageInfo.token
            //this.setState({ isUnauth: false });
        }
        else
            tokenToSend = this.props.token
        let info = { token: tokenToSend, mobile: false };
        // orderOfPersons
        BackendApi.getAllOrder(info, (res, err) => {
            if (err) {
                this.generateGenericError(err);
            }
            else if (res) {
                const tableData = createOrderOfPersonData(res.data);
                this.setState({ orderTableData: tableData, systemSummary: calculateTotalCostForAnalysis(tableData), loading: false });
            }
        });
        // all drinks
        BackendApi.getDrinks(info, (res, err) => {
            if (err) {
                this.generateGenericError(err);
            }
            else if (res) {
                this.setState({ drinkList: res.data });
            }
        });
        // all persons
        BackendApi.getProfilesOfFloor('all', (res, err) => {
            if (err) {
                this.generateGenericError(err);
            }
            else if (res) {
                this.setState({ personList: res.data });
            }
        });
        // all devices
        BackendApi.getDevices("", (res, err) => { // maybe need parameter for request
            if (err) {
                this.generateGenericError(err);
            }
            else if (res) {
                this.setState({ deviceList: res.data });
            }
        });
        // all floors
        BackendApi.getFloors(info, (res, err) => {
            if (err) {
                if (err.response.status === 401) {
                    this.setState({ isUnauth: true, isLoading: false });
                    alert("You are unauthorized. Please login firstly!".localize());
                }
                else {
                    this.props.addNotification("The required information could not be loaded!".localize(), "error");
                }
            }
            else if (res) {
                let floors = res.data;
                this.setState({ floorList: floors })
            }
        });
    };

    generateGenericError = (err) => {
        //todo need more generic in BackendAPI
        if (err.response) {
            if (err.response.status === 401) {
                this.setState({ isUnauth: true });
                alert("You are unauthorized. Please login firstly!".localize());
            }
            else {
                this.props.addNotification("Something went wrong!".localize(), "error");
            }
        }
    };

    generateColumn = () => {
        return [{
            Header: 'Date'.localize(),
            accessor: 'formattedDate',
            sortMethod: (a, b) => {
                return sortByDate(a, b);
            },
            filterMethod: (filter, row) => {
                if (filter.value === "All".localize()) {
                    return true;
                }
                return momentIsBetween(row._original.date, filter.value);
            },
            Filter: ({ filter, onChange }) =>
                <DatePickerModalTrigger onApply={(data) => onChange(data)} applyColor={getColorTheme().selectedTheme} />
        },
        {
            Header: 'Profile'.localize(),
            accessor: 'person_info',
            filterMethod: (filter, row) => {
                if (filter.value === "all") {
                    return true;
                }
                else if (filter.value === "deleted") {
                    const index = this.state.personList.findIndex((person) => person.name === row[filter.id]);
                    return index === -1;
                }
                return row[filter.id] === filter.value;
            },
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value="all">{"All".localize()}</option>
                    {this.state.personList.map((person, key) => {
                        let value = person.name;
                        return <option key={key + 1} value={value}>{value}</option>
                    })}
                    <option value="deleted">{"Deleted Profiles".localize()}</option>
                </select>
        },
        {
            Header: 'Device'.localize(),
            accessor: 'device_name',
            filterMethod: (filter, row) => {
                if (filter.value === "all") {
                    return true;
                } else if (filter.value === "unregistered") {
                    const index = this.state.deviceList.findIndex((device) => device.name === row[filter.id]);
                    return index === -1;
                }
                return row[filter.id] === filter.value;
            },
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value="all">{"All".localize()}</option>
                    {this.state.deviceList.map((device, key) => {
                        let value = device.name;
                        if (device.name)
                            return <option key={key + 1} value={value}>{value}</option>
                    })}
                    <option value="unregistered">{"Unregistered".localize()}</option>
                </select>
        },
        {
            Header: 'Floor'.localize(),
            accessor: 'floor_info',
            filterMethod: (filter, row) => {
                if (filter.value === "all") {
                    return true;
                }
                return row[filter.id] === filter.value;
            },
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value="all">{"All".localize()}</option>
                    {this.state.floorList.map((floor, key) => {
                        return <option key={key + 1} value={floor.name}>{floor.name}</option>
                    })}
                </select>
        },
        {
            Header: 'Type'.localize(),
            accessor: 'type',
            id: "type",
            filterMethod: (filter, row) => {
                if (filter.value === "all") {
                    return true;
                }
                if (filter.value === "purchase") {
                    return row[filter.id] === "Purchase".localize();
                }
                if (filter.value === "payment") {
                    return row[filter.id] === "Payment".localize();
                }
                return row[filter.id];
            },
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value="all">{"All".localize()}</option>
                    <option value="purchase">{"Purchase".localize()}</option>
                    <option value="payment">{"Payment".localize()}</option>
                </select>
        },
        {
            Header: 'Product'.localize(),
            accessor: 'product',
            id: "product",
            filterMethod: (filter, row) => {
                if (filter.value === "all") {
                    return true;
                }
                else if (filter.value === "custom") {
                    const index = this.state.drinkList.findIndex((drinks) => drinks.name === row[filter.id]);
                    return index === -1;
                }
                return row[filter.id] === filter.value;
            },
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value="all">{"All".localize()}</option>
                    {this.state.drinkList.map((drink, key) => {
                        return <option key={key + 1} value={drink.name}>{drink.name}</option>
                    })}
                    <option value="custom">{"Custom".localize()}</option>
                </select>
        },
        {
            Header: 'Product Price'.localize(),
            accessor: 'product_cost',
            sortMethod: (a, b) => {
                return sortByPrice(a, b);
            }
        },
        {
            Header: 'Quantity'.localize(),
            accessor: 'product_quantity',
        },
        {
            Header: 'Price'.localize(),
            accessor: 'price',
            sortMethod: (a, b) => {
                return sortByPrice(a, b);
            }
        },
        {
            Header: 'Active'.localize(),
            accessor: 'active',
            filterMethod: (filter, row) => {
                if (filter.value === "all") {
                    return true;
                }
                if (filter.value === "active") {
                    return row[filter.id] === "Active".localize();
                }
                if (filter.value === "inactive") {
                    return row[filter.id] === "Inactive".localize();
                }
                return row[filter.id];
            },
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value="all">{"All".localize()}</option>
                    <option value="active">{"Active".localize()}</option>
                    <option value="inactive">{"Inactive".localize()}</option>
                </select>
        },
        {
            Header: 'Invoice'.localize(),
            accessor: 'invoice',
            filterMethod: (filter, row) => {
                if (filter.value === "all") {
                    return true;
                }
                if (filter.value === "invoice") {
                    return row[filter.id] === "Invoice".localize();
                }
                if (filter.value === "non-invoice") {
                    return row[filter.id] === "Non-Invoice".localize();
                }
                return row[filter.id];
            },
            Filter: ({ filter, onChange }) =>
                <select
                    onChange={event => onChange(event.target.value)}
                    style={{ width: "100%" }}
                    value={filter ? filter.value : "all"}
                >
                    <option value="all">{"All".localize()}</option>
                    <option value="invoice">{"Invoice".localize()}</option>
                    <option value="non-invoice">{"Non-Invoice".localize()}</option>
                </select>
        }];
    };

    getSortedData = () => {
        if (this.reactTable) {
            return this.reactTable.getResolvedState();
        }
        return [];
    }

    filteredChange = (filtered, column) => {
        const systemSummary = calculateTotalCostForAnalysis(this.getSortedData());
        this.setState({ systemSummary });
        /*const sumText = document.getElementById("summaryText");
        sumText.textContent = systemSummary;*/
    };

    renderScreen = () => {
        return (
            <div id="analysis_screen_div" className="AnalysisContainer">
                <div className="ProfileUpdateDelete">
                    <div className="ProfileTextContainer" >
                        <p className="ThematicGeneralText">{"Transaction History".localize()}</p>
                    </div>
                    <div className="AnalysisExportContainer">
                        <div className="SwitchContainer">
                            <CSVExporterButton
                                dataToDownload={this.state.dataToDownload}
                                columns={this.state.column} getSortedData={this.getSortedData} />
                        </div>
                    </div>
                    <div className="AnalysisSummaryContainer">
                        <p className="ThematicGeneralText">{`${"Total".localize()}: `}</p>
                        <p className="AnalysisSummaryText" id="summaryText">{this.state.systemSummary}</p>
                    </div>
                </div>
                <CustomReactTable
                    onRef={(ref) => this.reactTable = ref}
                    data={this.state.orderTableData}
                    loading={this.state.loading}
                    onFilteredChange={this.filteredChange}
                    columns={this.state.column} />
            </div>
        );
    }

    render() {
        return (
            this.state.isUnauth ? <Redirect to={{
                pathname: '/',
                state: { token: this.state.token }
            }} /> : this.renderScreen()
        );
    }
};