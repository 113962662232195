import React from 'react';
import { Button } from 'semantic-ui-react'
import CustomPopup from "../Components/CustomPopup"
export default class GroupButton extends  React.Component {
    constructor(props) {
        super(props);
    }

    onClickFirst = () => {
        this.startFirst()
    }

    startFirst = () => {
        this.props.firstButtonClick(()=>{
            this.props.addNotification(null, "User successfully found in Nexudus!".localize(), "success");
        },()=>{
            this.props.addNotification(null, "User could not found in Nexudus !".localize(), "error");
        })
    }


    onClickSecond = () => {
        this.props.secondButtonClick(()=>{
            this.props.addNotification(null, "Synchronize successfully with Nexudus!".localize(), "success");
            //show notification
        },()=>{
            this.props.addNotification(null, "Could not synchronize with Nexudus!".localize(), "error");
        })
    }


    render(){
        return(
                <Button.Group
                    style={this.props.style}
                >
                    <CustomPopup
                        content={this.props.firstPopup}
                        trigger={
                            <Button
                                loading={this.props.isLoading}
                                disabled={this.props.isFirstDisable}
                                onClick={this.onClickFirst}
                                style={{width:"100%"}}
                            >
                                {this.props.firstButton}
                            </Button>
                        }
                    />
                    <CustomPopup
                        content={this.props.secondPopup}
                        trigger={
                            <Button
                                positive
                                disabled={this.props.isSecondDisable}
                                onClick={this.onClickSecond}
                                style={{width:"100%"}}
                            >
                                {this.props.secondButton}
                            </Button>
                        }
                    />
                </Button.Group>
        )
    }
}
