import React from 'react'
import { Button, Header, Image, Modal } from 'semantic-ui-react'

function CustomModal(props) {
    return (
        <Modal
            open={props.open}
        >
            <Modal.Header>{props.header}</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Header>{props.descriptionHeader}</Header>
                    {props.description}
                    {props.render}
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <div style={{display:"flex",flexWrap:"wrap",gap:"16px",justifyContent:"right"}}>
                    {props.firstButton}
                    {props.secondButton}
                </div>
            </Modal.Actions>
        </Modal>
    )
}

export default CustomModal
