import React from 'react';
import { Image } from 'semantic-ui-react';
import DrinkImageModal from './DrinkImageModal';

export default class CustomImageSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            recentImage: this.props.data.inactive_image,
            backgroundColor: this.props.theme.mainBackgroundColor,
            isOpenModal: false,
        };
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(undefined);
        }
    }

    onMouseEnter = () => {
        this.setState({ recentImage: this.props.data.image, backgroundColor: this.props.theme.selectedTheme });
    };

    onMouseLeave = () => {
        this.setState({ recentImage: this.props.data.inactive_image, backgroundColor: this.props.theme.mainBackgroundColor });
    };

    handleClick = () => {
        this.setState({ isOpenModal: true });
    };

    onApply = (imageObj) => {
        this.props.onApply(imageObj);
        this.onClose();
    };

    onClose = () => {
        this.setState({ isOpenModal: false });
    };

    render() {
        return (
            <div style={this.props.style} key={this.props.data.image}>
                {
                    this.state.isOpenModal ? <DrinkImageModal open={this.state.isOpenModal} data={this.props.data} 
                        addNotification={this.props.addNotification} theme={this.props.theme}
                        onApply={this.onApply} onClose={this.onClose}/> : null
                }
                <Image src={this.state.recentImage} style={{ backgroundColor: this.state.backgroundColor }} circular bordered
                    centered onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} onClick={this.handleClick} />
            </div>
        );
    }
}