import React from 'react';
import { Input } from 'semantic-ui-react';
import { CUSTOMER_TYPE } from '../Helpers/const';

export default class PersonInfoContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
        };
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(undefined);
        }
    }

    handleTextChange = (event, data, stateType) => {
        const value = data.value.replace(/\s+/g, ' ').trim();
        this.setState({ [stateType]: value });
    };

    getInputStyle = () => {
        return { width: '80%', height: '50%' };
    };

    renderInput = (stateType) => {
        let icon;
        switch (stateType) {
            case "firstName":
                icon = "address book";
                break;
            case "lastName":
                icon = "address card";
                break;
            case "email":
                icon = "mail";
                break;
            default:
                icon = "address book";
                break;
        }

        return <div className="SixtyPercentage">
            <Input
                iconPosition='left' icon={icon}
                onChange={(event, data) => this.handleTextChange(event, data, stateType)}
                placeholder="" style={this.getInputStyle()} />
        </div>
    };

    getStateInfo = () => {
        return { type: CUSTOMER_TYPE.PERSON, firstName: this.state.firstName, lastName: this.state.lastName, email: this.state.email };
    };

    render() {
        return <div id="person_info_container" style={{ display: 'contents' }}>
            <div id="person_name" className="NewProfileFirstName">
                <div className="NewProfileTextContainer">
                    <p className="NewProfileGeneralText" >{"First Name / Company Name".localize()}</p>
                </div>
                <div className="NewProfileSelectContainer">
                    {this.renderInput("firstName")}
                </div>
            </div>
            <div id="person_surname" className="NewProfileLastName">
                <div className="NewProfileTextContainer">
                    <p className="NewProfileGeneralText" >{"Last Name / Legal Form".localize()}</p>
                </div>
                <div className="NewProfileSelectContainer">
                    {this.renderInput("lastName")}
                </div>
            </div>
            <div id="person_email" className="NewProfileLastName">
                <div className="NewProfileTextContainer">
                    <p className="NewProfileGeneralText" >{"E-Mail".localize()}</p>
                </div>
                <div className="NewProfileSelectContainer">
                    {this.renderInput("email")}
                </div>
            </div>
        </div>
    }
}