import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import BackendApi from '../api/app2backend';
import '../Main.css';
import {
    generateCurrentAndPrevMonthName, generateCurrentAndPrevMonthValue,
    generateCurrentProp, generateLastPaymentProp,
    generatePrevProp
} from '../Helpers/ListHelpers';
import { isRoomMenuAllowed,isHaveNexudus } from '../config/flavor/mainFlavor';
import SelectedProfileScreen from './SelectedProfileScreen';
import GeneralProfileScreen from './GeneralProfileScreen';

export default class ProfilesScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            floorList: [],
            personList: [],
            showProfileUpdate: false,
            profileUpdateInfo: [],
            floorListDropdown: [],
            propList: "none",
            tokenActual: "",
            searchText: "",
            floorIndex: 'all',
            personsLatesQueues: []
        }
    }
    componentWillUnmount() {
        if (this.props.onRef)
            this.props.onRef(undefined);
    }

    componentDidMount() {
        if (this.props.onRef)
            this.props.onRef(this);
    }

    componentWillMount() {
        this.getAllInfo(() => {
            let tokenToSend;
            let localStorageInfo = localStorage.getItem('userInfo');
            localStorageInfo = JSON.parse(localStorageInfo);
            if (localStorageInfo && localStorageInfo.isAuthSuccess) {
                tokenToSend = localStorageInfo.token
                this.setState({ isUnauth: false });
            }
            else
                tokenToSend = this.props.token
            let info = { token: tokenToSend };
            this.setState({ tokenActual: tokenToSend });

            this.fetchFloors(info);
        });
    }

    getAllInfo = (cb) => {
        var info = generateCurrentAndPrevMonthValue();
        BackendApi.getPersonWithAll(info, (res) => {
            this.setState({ propList: res.data }, () => {
                if (cb) {
                    cb();
                }
            });
        })
    };

    fetchFloors = (info) => {
        BackendApi.getFloors(info, (res, err) => {
            if (err) {
                if (err.response) {
                    if (err.response.status === 401) {
                        this.setState({ isUnauth: true });
                        alert("You are unauthorized. Please login firstly!".localize());
                    }
                    else {
                        this.props.addNotification("The person of cannot be added!".localize(), "error");
                    }
                }
            }
            else if (res) {
                let floors = [];
                let tempArr = res.data;
                this.setState({ floorListDropdown: tempArr })
                let all = {
                    name: "All".localize(),
                    id: "all"
                }
                floors.push(all);
                tempArr.map((floor) => floors.push(floor));
                let invoice = {
                    name: "Invoice".localize(),
                    id: "invoice"
                }
                let rooms = {
                    name: "Rooms".localize(),
                    id: "room"
                }
                floors.push(invoice);
                if (isRoomMenuAllowed())
                    floors.push(rooms);
                floors.map((data, index) => {
                    data['isActive'] = 0;
                    data['key'] = data.id;
                })

                this.setState({ floorList: floors }, () => {
                    this.generalProfileScreen.handleHeaderClick("all");
                });
            }
        });
    };

    hightLightSelectedFloor = (key) => {
        let tempList = this.state.floorList;
        tempList.map((floor) => {
            if (floor.id.toString() === key.toString()) {
                floor.isActive = 1;
            } else {
                floor.isActive = 0;
            }
        });
        this.setState({ floorList: tempList, });
    };

    getPersonsOfFloor = (index) => {
        if(isHaveNexudus()){
            this.getLatestQue()
        }
        this.getAllInfo(() => {
            BackendApi.getProfilesOfFloor(index, (res) => {
                let currentList, prevList, lastPaymentList;
                // console.log("response from floor click", res);
                if (this.state.propList !== "none") {
                    currentList = this.state.propList.currentPersonList;
                    prevList = this.state.propList.prevPersonList;
                    lastPaymentList = this.state.propList.lastPaymentList;
                }

                var persons = res.data;
                if (persons.length === 0)
                    this.setState({ personList: persons });
                else {
                    generateCurrentProp(currentList, persons);
                    generatePrevProp(prevList, persons);
                    generateLastPaymentProp(lastPaymentList, persons);

                    this.setState({ personList: persons, })
                }
            });
        });
    };

    getLatestQue = () => {
        BackendApi.getCustomersLatestQue((response)=> {
            this.setState({personsLatesQueues:response.data.result})
        },()=>{
            this.props.addNotification(null, "Could not get latest transfer status!".localize(), "error");
        })
    }

    updateList = (persons) => {
        this.generatePropList(persons);
        this.setState({ personList: persons })
    };

    generatePropList(persons) {
        let currentList = this.state.propList.currentPersonList;
        let prevList = this.state.propList.prevPersonList;
        let lastPaymentList = this.state.propList.lastPaymentList;
        generateCurrentProp(currentList, persons);
        generatePrevProp(prevList, persons);
        generateLastPaymentProp(lastPaymentList, persons);
    };

    backToAllProfiles = () => {
        this.getPersonsOfFloor(this.state.floorIndex);
        this.setState({ showProfileUpdate: false, searchText: "" });
    };

    calculateFontSize = (text) => {
        return text.length > 17 ? 20 : 25;
    };

    handlePersonClick = (index) => {
        let personInfo = this.state.personList[index];
        this.setState({ showProfileUpdate: true, profileUpdateInfo: personInfo });
    };

    render() {
        const dateNames = generateCurrentAndPrevMonthName();
        if (!this.state.showProfileUpdate) {
            return (
                this.state.isUnauth ? <Redirect to={{
                    pathname: '/',
                    state: { token: this.state.token }
                }} /> :
                    <GeneralProfileScreen
                        onRef={ref => this.generalProfileScreen = ref} dateNames={dateNames}
                        hightLightSelectedFloor={this.hightLightSelectedFloor} getPersonsOfFloor={this.getPersonsOfFloor}
                        floorList={this.state.floorList} updateList={this.updateList}
                        searchText={this.state.searchText}
                        updateSearchText={(string) => this.setState({ searchText: string })}
                        updateFloorIndex={(index) => this.setState({ floorIndex: index })}
                        addNotification={this.props.addNotification}
                        getLatestQue={this.getLatestQue}
                        personsLatesQueues={this.state.personsLatesQueues}
                        personList={this.state.personList} handlePersonClick={this.handlePersonClick} />
            )
        }
        else
            return <SelectedProfileScreen
                logout={this.props.logout}
                profileUpdateInfo={this.state.profileUpdateInfo} tokenActual={this.state.tokenActual}
                dateNames={dateNames} floorListDropdown={this.state.floorListDropdown}
                addNotification={this.props.addNotification} backToAllProfiles={this.backToAllProfiles} personList={this.state.personList}/>
    }
}