import React from 'react';
import { Input, Icon } from 'semantic-ui-react';

export default class CustomDrinkInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditMode: false,
            currentDrinkName: this.props.drinkName
        };
    }

    openEditMode = () => {
        this.setState({ isEditMode: true });
    };

    closeEditMode = () => {
        this.setState({ isEditMode: false, currentDrinkName: this.props.drinkName });
    };

    handleChangeName = (event, data) => {
        this.setState({ currentDrinkName: data.value });
    };

    changeDrinkName = () => {
        this.props.onApplyDrinkName(this.state.currentDrinkName);
        this.closeEditMode();
    };

    render() {
        return (
            <div id="CustomDrinkInput">
                {
                    !this.state.isEditMode ?
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <p className="DrinkNameText">
                                {this.props.drinkName}
                            </p>
                            <Icon
                                onClick={this.openEditMode}
                                style={{ marginLeft: 10 }}
                                size='large'
                                name="edit" />
                        </div>
                        :
                        <div>
                            <Input
                                iconPosition={"left"}
                                icon="cocktail"
                                value={this.state.currentDrinkName}
                                onChange={this.handleChangeName}
                                placeholder={"Drink Name".localize()}
                                style={{ width: '70%', height: '50%' }} />
                            <div style={{ marginTop: 10, float: 'right', marginLeft: 10, display: 'flex', flexDirection: 'row' }} >
                                <Icon
                                    onClick={this.changeDrinkName}
                                    size='large'
                                    name="check circle" />
                                <Icon
                                    onClick={this.closeEditMode}
                                    size='large'
                                    name="close" />
                            </div>
                        </div>
                }

            </div>
        );
    }
}