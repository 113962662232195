import React from 'react'
import { Input } from 'semantic-ui-react'
export default class SearchInput extends  React.Component {
    constructor(props) {
        super(props);
    }

    render(){
        return(
                <Input
                    icon='search'
                    label={this.props.label}
                    value={this.props.defaultValue}
                    onChange={this.props.onChange}
                    placeholder={"Search".localize()+"..."}
                />

        )
    }
}
