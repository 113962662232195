import logoActiveImg from '../images/logoActive.png';
import fleetLogoImg from '../images/fleet_logo.png';
import tulipLogoImg from '../images/tulip.png';
import rostockLogoImg from '../images/Rostock-Logo-Dark.png';
import iotiqLogoImg from '../images/iotiq_logo.png';

import leftArrow from '../images/left-arrow.png';
import leftArrowFleet from '../images/left-arrow-fleet7.png';

export const LOGO_IMAGES = {
    basislager: {
        logoActive: rostockLogoImg // logoActiveImg
    },
    fleet: {
        logoActive: fleetLogoImg
    },
    room: {
        logoActive: tulipLogoImg
    },
    rostock: {
        logoActive: rostockLogoImg
    },
    iotiq: {
        logoActive: iotiqLogoImg
    }
};

export const BACK_BUTTON_IMAGES = {
    basislager: {
        logoActive: leftArrow
    },
    fleet: {
        logoActive: leftArrowFleet
    },
    room: {
        logoActive: leftArrowFleet
    },
    rostock: {
        logoActive: leftArrow
    },
    iotiq: {
        logoActive: leftArrow
    }
};

export const DEFAULT_IMAGE = "images/default/default.png";
export const DEFAULT_IMAGE_NAME = "default.png";