class CoworkingspaceClient {
    constructor(data) {
        this.coworkingspace = data.coworkingspace;
        this.platform = data.platform;
        this.connection = data.connection;
        this.haveInfo = {
            haveRoomMenu: data.haveRoomMenu,
            haveMoreAdmin: data.haveMoreAdmin,
            haveNexudus: data.isHaveNexudus
        };
        this.asteriskImage = data.asteriskImage;
        this.style = {
            colorTheme: data.colorTheme,
            header: data.header,
            profileHeaderProperties: data.profileHeaderProperties,
            logoProperties: data.logoProperties,
            profileLogoProperties: data.profileLogoProperties
        };
        this.headersInfo = {
            headerList: data.headerList,
            headerButtonMarginLeft: data.headerButtonMarginLeft
        };
        this.texts = {
            howToPayText: data.howToPayText,
            purchaseButtonText: data.purchaseButtonText,
            cancelPinText: data.cancelPinText,
            cancelButtonText: data.cancelButtonText,
        };
    }

    getCoworkingspace() {
        return this.coworkingspace;
    }

    getPlatform() {
        return this.platform;
    }

    getConnectionInfo() {
        return this.connection;
    }

    getHaveInfo(component) {
        return this.haveInfo[`${component}`];
    }

    getAsteriskImage() {
        return this.asteriskImage;
    }

    getStyle(component) {
        return this.style[`${component}`];
    }

    getHeaderInfo(component) {
        return this.headersInfo[`${component}`];
    }

    getText(component) {
        return this.texts[`${component}`];
    }

}

CoworkingspaceClient.STYLE = {
    COLOR_THEME     : "colorTheme",
    HEADER          : "header",
    PROFILE_HEADER  : "profileHeaderProperties",
    LOGO            : "logoProperties",
    PROFILE_LOGO    : "profileLogoProperties"
};

CoworkingspaceClient.HEADER = {
    HEADER_LIST     : "headerList",
    MARGIN_LEFT     : "headerButtonMarginLeft"
};

CoworkingspaceClient.TEXT = {
    HOW_TO_PAY      : "howToPayText",
    PURCHASE        : "purchaseButtonText",
    CANCEL_PIN      : "cancelPinText",
    CANCEL_BUTTON   : "cancelButtonText"
};

CoworkingspaceClient.HAVE_INFO = {
    ROOM_MENU       : "haveRoomMenu",
    MORE_ADMIN      : "haveMoreAdmin",
    NEXUDUS         : "haveNexudus"
};

export default CoworkingspaceClient;